import React, {useMemo} from "react";
import {Autocomplete, TextField, Typography} from "@mui/material";
import {timezones} from "@atttomyx/react-utils";
import "./timeZoneField.css";

const TimeZoneField = (props) => {
    const { value, onChange : onSync } = props;
    const timeZones = useMemo(() => timezones.listTimeZonesForCountry("US"), []);
    const timeZone = useMemo(() => timezones.toTimeZoneObj(value), [value]);

    const onChange = (event, value) => {
        const name = value ? value.name : null;

        onSync(name);
    }

    return <div className="time-zone-field">
        <Autocomplete options={timeZones}
                      getOptionLabel={tz => tz.name}
                      isOptionEqualToValue={(option, value) => value && option.name === value.name}
                      value={timeZone}
                      renderInput={params => <TextField {...params} label="Time Zone" required={true}/>}
                      onChange={onChange}
        />
        {timeZone ? <Typography variant="caption" className="details">
            (GMT {timeZone.offset}) {timeZone.abbreviation}
        </Typography> : null}
    </div>
}

export default TimeZoneField;
