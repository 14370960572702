import React, {Component} from "react";
import {CircularProgress, Dialog, DialogActions, DialogContent, IconButton} from "@mui/material";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import TimeOffInfo from "../../../components/timeOffInfo/timeOffInfo";
import * as timeOffService from "../../../services/timeOffs";
import {strings, users} from "@atttomyx/shared-utils";
import {confirm} from "@atttomyx/react-utils";
import "./pastTimeOffDialog.css";

class PastTimeOffDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deleting: false,
        };
    }

    deleteTimeOff() {
        const user = this.props.user;
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;

        this.setState({
            deleting: true,
        });

        if (user.roles.admin) {
            timeOffService.deleteTimeOff(timeOff.id, this.timeOffDeleted.bind(this));

        } else {
            timeOffService.cancelTimeOff(timeOff.id, this.timeOffDeleted.bind(this));
        }
    }

    timeOffDeleted(timeOffId) {
        this.setState({
            deleting: false,
        });

        this.props.onDelete(timeOffId);
    }

    render() {
        const me = this.props.user;
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const user = customFields.user;
        const ownRequest = timeOff.requestedBy === me.id;
        const admin = me.roles.admin;
        const deleting = this.state.deleting;
        const title = ownRequest ? "this time off" : strings.possessive(users.getFullName(user)) + " time off";

        return <Dialog
            open={true}
            className="past-time-off-dialog"
        >
            <ClosableDialogTitle onClose={this.props.onClose}
                                 subtitle={users.getFullName(user)}>
                Past Time Off
            </ClosableDialogTitle>
            <DialogContent className="past-time-off-dialog">
                {deleting ?
                    <CircularProgress size="40px" color="secondary"/> :
                    <TimeOffInfo event={event}/>}
            </DialogContent>
            {(ownRequest || admin) && !deleting ?
                <DialogActions>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => confirm.confirmDelete(title, this.deleteTimeOff.bind(this))}>
                        <DeleteIcon/>
                    </IconButton>
                </DialogActions> : null}
        </Dialog>
    }
}

export default PastTimeOffDialog;
