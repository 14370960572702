import React from "react";
import {TextField} from "@mui/material";
import {BoolFilterField} from "@atttomyx/react-components";
import AttributeField from "../../fields/attributeField/attributeField";
import {forms, objects} from "@atttomyx/shared-utils";
import {filters} from "@atttomyx/shared-constants";

const AttendanceFilters = (props) => {
    const {data, onChange: onSync, formProps} = props;
    const {attributes} = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        if (filters.FALSE === modified.shift) {
            modified.started = undefined;
        }

        onSync(modified);
    }

    return <div className="attendance-filters">
        <div className="field">
            <TextField label="First name"
                       type="text"
                       value={forms.sanitizeValue(data.firstName)}
                       onChange={(event) => onChange("firstName", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Last name"
                       type="text"
                       value={forms.sanitizeValue(data.lastName)}
                       onChange={(event) => onChange("lastName", event.target.value)}
            />
        </div>
        <div className="field">
            <AttributeField
                attributes={attributes}
                value={data.attributeId}
                onChange={(value) => onChange("attributeId", value)}
            />
        </div>
        <div className="field">
            <BoolFilterField
                label="Shift"
                value={forms.sanitizeValue(data.shift)}
                onChange={(value) => onChange("shift", value)}
                trueLabel="Assigned"
                falseLabel="Not assigned"
            />
        </div>
        {data.shift === filters.TRUE ? <div className="field">
            <BoolFilterField
                label="Started"
                value={forms.sanitizeValue(data.started)}
                onChange={(value) => onChange("started", value)}
                trueLabel="Shift already started"
                falseLabel="Shift starts later today"
            />
        </div> : null}
    </div>
}

export default AttendanceFilters;
