import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {CircularProgress, Dialog, DialogActions, DialogContent, Divider, IconButton, TextField} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    ThumbDown as DenyIcon,
    ThumbUp as ApproveIcon
} from "@mui/icons-material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import TimeOffInfo from "../../../components/timeOffInfo/timeOffInfo";
import * as timeOffService from "../../../services/timeOffs";
import {forms, strings, users} from "@atttomyx/shared-utils";
import {confirm, router} from "@atttomyx/react-utils";
import {PAGE_TIME_OFF_REQUEST} from "../../../constants";
import "./adminTimeOffDialog.css";

class AdminTimeOffDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: null,
            saving: false,
            deleting: false,
        };
    }

    setNotes(event) {
        const notes = event.target.value;

        this.setState({
            notes: notes,
        });
    }

    approveTimeOff() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const notes = this.state.notes;

        this.setState({
            notes: null,
            saving: true,
        });

        timeOffService.approveTimeOff(timeOff.id, notes, this.timeOffApproved.bind(this));
    }

    timeOffApproved(timeOff) {
        this.setState({
            saving: false,
        });

        this.props.onApproved(timeOff);
    }

    denyTimeOff() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const notes = this.state.notes;

        this.setState({
            notes: null,
            saving: true,
        });

        timeOffService.denyTimeOff(timeOff.id, notes, this.timeOffDenied.bind(this));
    }

    timeOffDenied(timeOff) {
        this.setState({
            saving: false,
        });

        this.props.onDenied(timeOff);
    }

    deleteTimeOff() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;

        this.setState({
            deleting: true,
        });

        timeOffService.deleteTimeOff(timeOff.id, this.timeOffDeleted.bind(this));
    }

    timeOffDeleted(timeOffId) {
        this.setState({
            deleting: false,
        });

        this.props.onDelete(timeOffId);
    }

    render() {
        const history = this.props.history;
        const me = this.props.user;
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const user = customFields.user;
        const notes = this.state.notes;
        const saving = this.state.saving;
        const deleting = this.state.deleting;
        const title = me.id === timeOff.requestedBy ? "this time off" : strings.possessive(users.getFullName(user)) + " time off";
        const approvedOrDenied = strings.isNotBlank(timeOff.approvedBy) || strings.isNotBlank(timeOff.deniedBy);

        return <Dialog
            open={true}
            className="admin-time-off-dialog"
        >
            <ClosableDialogTitle onClose={this.props.onCancel}>
                Time Off {!approvedOrDenied ? "Request" : null}
            </ClosableDialogTitle>
            <DialogContent className="admin-time-off-dialog">
                {saving ?
                    <CircularProgress size="40px"/> : deleting ?
                    <CircularProgress size="40px" color="secondary"/> :
                    <TimeOffInfo event={event}/>}
            </DialogContent>
            {!deleting && !saving ?
                <DialogActions className="top-actions">
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => confirm.confirmDelete(title, this.deleteTimeOff.bind(this))}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => router.redirectTo(history, `${PAGE_TIME_OFF_REQUEST}/${timeOff.id}`)}>
                        <EditIcon/>
                    </IconButton>
                </DialogActions> : null}
            {!saving && !deleting && !approvedOrDenied ?
                <>
                    <DialogContent className="admin-time-off-dialog">
                        <Divider variant="fullWidth"/>
                        <TextField label="Notes"
                                   type="text"
                                   multiline={true}
                                   value={forms.sanitizeValue(notes)}
                                   onChange={this.setNotes.bind(this)}
                        />
                    </DialogContent>
                    <DialogActions className="bottom-actions">
                        <IconButton color="secondary" title="Deny"
                                    onClick={this.denyTimeOff.bind(this)}>
                            <DenyIcon/>
                        </IconButton>
                        <IconButton color="primary" title="Approve"
                                    onClick={this.approveTimeOff.bind(this)}>
                            <ApproveIcon/>
                        </IconButton>
                    </DialogActions>
                </> : null}
        </Dialog>
    }
}

export default withRouter(AdminTimeOffDialog);
