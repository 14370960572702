import React, {useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {UserAvatar} from "@atttomyx/react-components";
import {arrays, objects, users as userUtils} from "@atttomyx/shared-utils";
import {formatPayRate, formatSeniority} from "../../utils/users";
import {misc} from "@atttomyx/shared-constants";
import "./userPicker.css";

const typographyClassName = (disabled, bogus) => {
    let ret = disabled ? "MuiFormControlLabel-label Mui-disabled" : null;

    if (bogus && ret) {
        ret += " bogus";

    } else if (bogus) {
        ret = "bogus";
    }

    return ret;
};

const hoursClassName = (hoursBeforeOvertime, userHours, shiftHours, checked) => {
    let css = "no-overtime";

    if (userHours > hoursBeforeOvertime) {
        css = "overtime";

    } else if (!checked && userHours + shiftHours > hoursBeforeOvertime) {
        css = "would-overtime";
    }

    return css;
};

const UserPicker = (props) => {
    const { availableUsers, users, deletedUsers = [], userIds, settings = {}, userIdToHours, shiftUserIds = [],
        shiftHours = 0, specialIds = [], onChange : onSync, isDisabled,
        checkedIcon, uncheckedIcon, specialCheckedIcon, specialUncheckedIcon,
        ready, readyMessage = "No available users"} = props;

    const [ allUsers, setAllUsers ] = useState([]);
    const [ available, setAvailable ] = useState([]);

    const filteredUsers = allUsers.filter(user => arrays.contains(userIds, user.id) || arrays.contains(available, user.id));

    useEffect(() => {
        const all = [];

        arrays.addAll(all, users);
        arrays.addAll(all, deletedUsers);

        const available = arrays.getIds(availableUsers ? availableUsers : users);
        const recognized = arrays.getIds(all);

        userIds.filter(userId => !arrays.contains(recognized, userId)).forEach(userId => {
            arrays.addTo(all, {
                id: userId,
                firstName: misc.UNKNOWN,
                lastName: "",
            });
            console.log("Unknown: " + userId);
        });

        setAllUsers(all);
        setAvailable(available);
    }, [availableUsers, users, deletedUsers, userIds]);

    const onChange = (userId, checked) => {
        const modified = arrays.copy(userIds);

        if (checked) {
            arrays.addTo(modified, userId);

        } else {
            arrays.removeFrom(modified, userId);
        }

        onSync(modified);
    };

    const renderUser = (user, checked, disabled, bogus) => {
        const hoursBeforeOvertime = objects.defaultIfNullOrUndefined(settings.hoursBeforeOvertime, 40);
        const hoursIncludeThisShift = arrays.contains(shiftUserIds, user.id);
        const userHours = userIdToHours ? userIdToHours[user.id] || 0 : 0;
        const typographyClass = typographyClassName(disabled, bogus);
        let userHoursToShow = userHours;

        if (hoursIncludeThisShift && checked) {
            userHoursToShow = userHours;

        } else if (hoursIncludeThisShift && !checked) {
            userHoursToShow = userHours - shiftHours;

        } else if (checked) {
            userHoursToShow = userHours + shiftHours;
        }

        return <Table size="small" padding="none" className={"user " + (disabled ? "disabled" : "")}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <UserAvatar user={user} mode="no-title" size="small"/>
                    </TableCell>
                    <TableCell className="name">
                        <Typography variant="body2" className={typographyClass}>
                            {userUtils.getFullName(user)}
                        </Typography>
                    </TableCell>
                    <TableCell className="hours">
                        {userIdToHours ?
                            <Typography
                                variant="caption"
                                className={hoursClassName(hoursBeforeOvertime, userHoursToShow, shiftHours, checked)}
                            >
                                ({userHoursToShow}h)
                            </Typography> : null}
                    </TableCell>
                    <TableCell className="seniority">
                        <Typography variant="caption" className={typographyClass}>
                            {formatSeniority(user)}
                        </Typography>
                    </TableCell>
                    <TableCell className="rate">
                        <Typography variant="caption" className={typographyClass}>
                            {formatPayRate(user)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    };

    return <div className="user-picker">
        {filteredUsers.length === 0 ?
            <Typography component="div" className="no-one">
                {ready ? "No available users" : readyMessage}
            </Typography> : filteredUsers.map((user) => {
                const checked = arrays.contains(userIds, user.id);
                const disabled = isDisabled ? isDisabled(checked) : false;
                const special = arrays.contains(specialIds, user.id);
                const bogus = !arrays.contains(available, user.id);

                return <FormControlLabel key={user.id}
                                         labelPlacement="end"
                                         label={renderUser(user, checked, disabled, bogus)}
                                         control={<Checkbox color="primary"
                                                            checked={checked}
                                                            disabled={disabled}
                                                            onChange={event => onChange(user.id, event.target.checked)}
                                                            icon={special ? specialUncheckedIcon : uncheckedIcon}
                                                            checkedIcon={special ? specialCheckedIcon : checkedIcon}
                                         />}
                />
            })}
    </div>
}

export default UserPicker;
