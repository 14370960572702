import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {isDesktop} from "react-device-detect";
import {useReactToPrint} from "react-to-print";
import csvDownload from "json-to-csv-export";
import {Button, Divider, Table, TableBody, TableCell, TableRow, Typography,} from "@mui/material";
import {Print as PrintIcon} from "@mui/icons-material";
import {FiltersAccordion, Info, UserAvatar} from "@atttomyx/react-components";
import AttendanceReportFilters from "../../filters/attendanceReportFilters/attendanceReportFilters";
import ReportAttendanceDialog from "../../dialogs/reportAttendanceDialog/reportAttendanceDialog";
import ReportAttendanceDetails from "../../components/reportAttendanceDetails/reportAttendanceDetails";
import Printable from "../../components/printable/printable";
import {arrays, objects, sorting, users as userUtils} from "@atttomyx/shared-utils";
import {
    ATTENDANCE_CAME_IN,
    ATTENDANCE_NO_SHOW,
    CSV_DELIMITER,
    CSV_FILENAME_CAME_IN,
    CSV_FILENAME_NO_SHOW,
    CSV_HEADERS_CAME_IN,
    CSV_HEADERS_NO_SHOW,
    TYPE_NO_SHOW
} from "../../constants";
import "./attendanceReportPage.css";

const AttendanceReportPage = (props) => {
    const {users, deletedUsers, attributes, attendances, filters} = props;
    const [usersById, setUsersById] = useState(null);
    const [deletedUserIds, setDeletedUserIds] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const data = filters.data || {};

    const ref = useRef(null);
    const printReport = useReactToPrint({
        content: () => ref.current,
        documentTitle: data.type === TYPE_NO_SHOW ? ATTENDANCE_NO_SHOW : ATTENDANCE_CAME_IN,
    });

    useEffect(() => {
        if (showResults) {
            const userIdToDates = {};
            const attributeId = data.attributeId;
            const userIds = data.userIds;

            attendances.forEach(attendance => {
                const field = data.type === TYPE_NO_SHOW ? "noShows" : "cameIns";
                const date = attendance.id;

                if (attendance[field]) {
                    attendance[field].filter(candidate => {
                        let include;

                        if (userIds.length === 0) {
                            include = !attributeId || candidate.attributeId === attributeId;

                        } else {
                            include = arrays.contains(userIds, candidate.userId);
                        }

                        return include;
                    }).forEach(attendee => {
                        const array = userIdToDates[attendee.userId] || [];

                        arrays.addTo(array, date);
                        userIdToDates[attendee.userId] = array;
                    });
                }
            });

            const mostAtTop = (a, b) => {
                return sorting.sortByFieldDesc(a, b, "num");
            };
            const alphabetical = (a, b) => {
                return userUtils.sortByName(a.user, b.user);
            };
            const composite = sorting.getCompositeSorter([mostAtTop, alphabetical]);

            const sorted = Object.entries(userIdToDates)
                .filter(([userId, dates]) => objects.notNullOrUndefined(usersById[userId]) && dates.length > 0)
                .map(([userId, dates]) => {
                    return {
                        userId: userId,
                        user: usersById[userId],
                        dates: dates,
                        num: dates.length,
                    }
                }).sort(composite);

            setResults(sorted);

        } else {
            setResults(null);
        }
    }, [filters.data, showResults, attendances]);

    useEffect(() => {
        const idToUser = arrays.getIdToEntity(users);
        const deleted = [];

        deletedUsers.map(user => {
            const userId = user.id;

            idToUser[userId] = user;
            arrays.addTo(deleted, userId);
        });

        setUsersById(idToUser);
        setDeletedUserIds(deleted);
    }, [users, deletedUsers]);

    const downloadCsv = () => {
        const lines = [];

        results.forEach(result => {
            const user = result.user;
            const name = userUtils.getFullName(user);

            result.dates.forEach(date => {
                const momentDate = moment(date);

                arrays.addTo(lines, {
                    name: name,
                    dayOfWeek: momentDate.format("dddd"),
                    date: date,
                });
            });
        });

        csvDownload({
            data: lines,
            delimiter: CSV_DELIMITER,
            headers: data.type === TYPE_NO_SHOW ? CSV_HEADERS_NO_SHOW : CSV_HEADERS_CAME_IN,
            filename: data.type === TYPE_NO_SHOW ? CSV_FILENAME_NO_SHOW : CSV_FILENAME_CAME_IN,
        });
    };

    return <div className="attendance-report-page">
        <Typography variant="h5" paragraph={true}>
            Attendance Report
        </Typography>
        <FiltersAccordion
            filters={filters}
            form={AttendanceReportFilters}
            formProps={{
                users: users,
                attributes: attributes,
            }}
            open={!showResults}
            mayDeleteCriteria={false}
        />
        <div className="actions">
            {isDesktop ?
                <>
                    <Button color="secondary"
                            disabled={!showResults}
                            onClick={printReport}>
                        <PrintIcon/> Print
                    </Button>
                    <Button color="secondary"
                            disabled={!showResults}
                            onClick={downloadCsv}>
                        Download CSV
                    </Button>
                </> : null}
            <Button color="primary"
                    onClick={() => setShowResults(!showResults)}>
                {showResults ? "Edit Filters" : "Run Report"}
            </Button>
        </div>
        {results ? <>
            <Divider/>
            {results.length > 0 ? <>
                    <Printable ref={ref}>
                        <Typography variant="h5">
                            {data.type === TYPE_NO_SHOW ? "No Show" : "Came in"}
                        </Typography>
                        <Table size="small" cellPadding={0} cellSpacing={0} className="results striped">
                            <TableBody>
                                {results.map((result) =>
                                    <TableRow key={result.userId} className="result"
                                              onClick={() => setResult(result)}>
                                        <TableCell className={arrays.contains(deletedUserIds, result.userId) ? "bogus" : ""}>
                                            <UserAvatar user={result.user} mode="right" size="small"/>
                                        </TableCell>
                                        <TableCell>
                                            {result.num}
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                        <div className="print-only">
                            {results.map(result => <div key={result.userId}>
                                <Divider/>
                                <UserAvatar user={result.user} mode="right" size="small"/>
                                <ReportAttendanceDetails
                                    dates={result.dates}
                                />
                            </div>)}
                        </div>
                    </Printable>
                    <Info>
                        Click on a user to see the days they {data.type === TYPE_NO_SHOW ? "missed" : "came in"}
                    </Info>
                </> :
                <Typography>
                    No results
                </Typography>}
        </> : null}
        {result ? <ReportAttendanceDialog
            user={result.user}
            dates={result.dates}
            type={data.type}
            onCancel={() => setResult(null)}
        /> : null}
    </div>
};

export default AttendanceReportPage;
