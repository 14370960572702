import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {Visibility as VisibleIcon, VisibilityOff as HiddenIcon} from "@mui/icons-material";
import "./visibilityIcon.css";

class VisibilityIcon extends Component {

    render() {
        const visible = this.props.visible;

        return <Table size="small" padding="none" className="visibility-icon">
            <TableBody>
                <TableRow>
                    <TableCell className="visibility" title={visible ? "Posted" : "Not posted"}>
                        {visible ? <VisibleIcon/> : <HiddenIcon/>}
                    </TableCell>
                    <TableCell>
                        <Typography variant="h6">
                            {this.props.children}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }
}

export default VisibilityIcon;
