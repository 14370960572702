import React, {useState} from 'react';
import {IconButton, Typography} from '@mui/material';
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import AttributeCard from "../../cards/attributeCard/attributeCard";
import AttributeDialog from "../../dialogs/attributeDialog/attributeDialog";
import {Cards, ConfirmDeleteDialog, FloatingAddButton} from "@atttomyx/react-components";
import {usePaging} from "@atttomyx/shared-hooks";
import * as attributeService from "../../services/attributes";
import {objects} from "@atttomyx/shared-utils";

const AttributesPage = (props) => {
    const { dimensions, snackbar, attributes, onSave, onDelete } = props;
    const [ toEdit, setToEdit ] = useState(null);
    const [ toDelete, setToDelete ] = useState(null);
    const paging = usePaging();

    return <div className="attributes-page">
        <Typography variant="h5">
            Qualifications
        </Typography>
        <Cards
            items={attributes}
            paging={paging}
            dimensions={dimensions}
            renderer={(attribute) =>
                <AttributeCard
                    key={attribute.id}
                    attribute={attribute}
                >
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => setToDelete(attribute)}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => setToEdit(objects.deepCopy(attribute))}>
                        <EditIcon/>
                    </IconButton>
                </AttributeCard>}
        />
        <FloatingAddButton title="Add qualification" position="higher"
                           onClick={() => setToEdit({})}
        />
        {toEdit ? <AttributeDialog
            attribute={toEdit}
            onCancel={() => setToEdit(null)}
            onSaved={(attribute) => {
                setToEdit(null);
                onSave(attribute);
            }}
        /> : null}
        {toDelete ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={toDelete.title}
            onCancel={() => setToDelete(null)}
            onDelete={(attributeId) => {
                setToDelete(null);
                onDelete(attributeId);
            }}
            deleter={(success, failure) => attributeService.deleteAttribute(toDelete.id, success, failure)}
        /> : null}
    </div>
}

export default AttributesPage;
