import React, {useState} from 'react';
import {IconButton, Typography} from '@mui/material';
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import TrackCard from "../../cards/trackCard/trackCard";
import TrackDialog from "../../dialogs/trackDialog/trackDialog";
import {Cards, ConfirmDeleteDialog, FloatingAddButton} from "@atttomyx/react-components";
import {usePaging} from "@atttomyx/shared-hooks";
import * as trackService from "../../services/tracks";
import {datetime, objects} from "@atttomyx/shared-utils";

const TracksPage = (props) => {
    const { dimensions, snackbar, tracks, onSave, onDelete } = props;
    const [ toEdit, setToEdit ] = useState(null);
    const [ toDelete, setToDelete ] = useState(null);
    const paging = usePaging();

    return <div className="tracks-page">
        <Typography variant="h5">
            Recurrences
        </Typography>
        <Cards
            items={tracks}
            paging={paging}
            dimensions={dimensions}
            renderer={(track) =>
                <TrackCard key={track.id}
                           track={track}
                >
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => setToDelete(track)}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => setToEdit(objects.deepCopy(track))}>
                        <EditIcon/>
                    </IconButton>
                </TrackCard>}
        />
        <FloatingAddButton title="Add recurrence" position="higher"
                           onClick={() => setToEdit({
                               start: datetime.today(),
                           })}
        />
        {toEdit ? <TrackDialog
            track={toEdit}
            onCancel={() => setToEdit(null)}
            onSaved={(track) => {
                setToEdit(null);
                onSave(track);
            }}
        /> : null}
        {toDelete ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={toDelete.title}
            onCancel={() => setToDelete(null)}
            onDelete={(trackId) => {
                setToDelete(null);
                onDelete(trackId);
            }}
            deleter={(success, failure) => trackService.deleteTrack(toDelete.id, success, failure)}
        /> : null}
    </div>
}

export default TracksPage;
