import React from 'react';
import ReactDOM from "react-dom";
import {Wrapper} from "@atttomyx/react-components";
import {DndWrapper} from "@atttomyx/react-dnd";
import App from "./app";
import {light} from "./themes/light";
import {dark} from "./themes/dark";
import {branding, storage} from "@atttomyx/react-utils";
import {themes} from "@atttomyx/shared-constants";
import {NAMESPACE} from "./constants";

storage.setNamespace(NAMESPACE);

ReactDOM.render(
    <Wrapper theme={themes.LIGHT}
             branding={branding.BRANDING_LIGHT}
             light={light}
             dark={dark}
    >
        {(onThemeChange, snackbar, dimensions) =>
            <DndWrapper>
                <App
                    onThemeChange={onThemeChange}
                    snackbar={snackbar}
                    dimensions={dimensions}
                />
            </DndWrapper>}
    </Wrapper>,
    document.getElementById('root')
);
