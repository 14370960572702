import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {Mutex} from 'async-mutex';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {CircularProgress, Fab} from "@mui/material";
import {Add as AddIcon, FilterList as FilterIcon} from "@mui/icons-material";
import {useQuery} from "@atttomyx/react-hooks";
import {FiltersDialog, FloatingButtons} from "@atttomyx/react-components";
import TimeOffFilters from "../../filters/timeOffFilters/timeOffFilters";
import AdminTimeOffDialog from "../../dialogs/timeOffDialog/admin/adminTimeOffDialog";
import MineTimeOffDialog from "../../dialogs/timeOffDialog/mine/mineTimeOffDialog";
import PastTimeOffDialog from "../../dialogs/timeOffDialog/past/pastTimeOffDialog";
import TimeOffEvent from "../../components/timeOffEvent/timeOffEvent";
import {getContentHeight, timeOffsToEvents} from "../../utils/fullCalendar";
import {getGoBackTo} from "../../utils/users";
import {arrays, datetime} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {PAGE_TIME_OFF_REQUEST, VIEW_DAY_MONTH, VIEW_DAY_WEEK} from "../../constants";
import "./timeOffPage.css";

const eventsMutex = new Mutex();
const datesMutex = new Mutex();

const TimeOffPage = (props) => {
    const {history, dimensions, account, user : me, users, attributes, timeOffCache, filters,
        date, setDate, view, setView} = props;
    const [range, setRange] = useState({});
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);
    const [popTimeOffId, setPopTimeOffId] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [visibleAttributes, setVisibleAttributes] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState([]);
    const query = useQuery();

    useEffect(() => {
        if (me.roles.admin || !account.settings.hideOtherUsers) {
            setVisibleAttributes(attributes);

        } else {
            const visibleAttributes = [];

            attributes
                .filter(attribute => arrays.contains(attribute.id, me.settings.attributeIds))
                .forEach(attribute => arrays.addTo(visibleAttributes, attribute));

            setVisibleAttributes(visibleAttributes);
        }
    }, [me, account, attributes]);

    useEffect(() => {
        if (me.roles.admin || !account.settings.hideOtherUsers) {
            setVisibleUsers(users);

        } else {
            setVisibleUsers(users.filter(user => arrays.containsAny(me.settings.attributeIds, user.settings.attributeIds)));
        }
    }, [me, account, users]);

    useEffect(() => {
        const dateParam = query.get("date");
        const timeOffId = query.get("timeOffId");

        if (dateParam) {
            setDate(dateParam);
        }

        if (timeOffId) {
            setPopTimeOffId(timeOffId);
        }
    }, []);

    useEffect(() => {
        eventsMutex.acquire()
            .then(function (release) {
                try {
                    const attributeId = filters.data.attributeId;
                    const userIds = filters.data.userIds || [];

                    const filtered = visibleUsers.filter(user => {
                        const attribute = !attributeId || arrays.contains(user.settings.attributeIds, attributeId);
                        const selected = userIds.length === 0 || arrays.containsAny(userIds, user.id);

                        return attribute && selected;
                    });

                    const events = timeOffsToEvents(me, timeOffCache.entities, account.branding, filtered, view);

                    if (popTimeOffId) {
                        events.forEach((candidate) => {
                            if (!event) {
                                const customFields = candidate.extendedProps;
                                const timeOff = customFields.timeOff;

                                if (timeOff.id === popTimeOffId) {
                                    setEvent(candidate);
                                    setPopTimeOffId(null);
                                }
                            }
                        });
                    }

                    setEvents(events);
                } finally {
                    release();
                }
            }.bind(this));
    }, [timeOffCache.entities, range, account.branding, me, visibleUsers, filters.data, view]);

    const dateRangeChanged = (info) => {
        const view = info.view;
        const start = datetime.getDate(view.activeStart.toISOString());
        const stop = datetime.getDate(view.activeEnd.toISOString());
        const date = datetime.getDate(view.currentStart.toISOString());

        if (range.start !== start || range.stop !== stop) {
            datesMutex.acquire()
                .then(function (release) {
                    try {
                        if (range.start !== start || range.stop !== stop) {
                            setDate(date);
                            setView(view.type);

                            setRange({
                                start: start,
                                stop: stop,
                            });

                            timeOffCache.load(start, stop);
                        }
                    } finally {
                        release();
                    }
                }.bind(this));
        }
    };

    const timeOffSaved = (timeOff) => {
        setEvent(null);
        timeOffCache.onEntitySaved(timeOff);
    };

    const timeOffDeleted = (timeOffId) => {
        setEvent(null);
        timeOffCache.onEntityDeleted(timeOffId);
    };

    const renderTimeOffDialog = () => {
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const past = datetime.isPast(timeOff.stop);

        return past ?
            <PastTimeOffDialog
                user={me}
                event={event}
                onClose={() => setEvent(null)}
                onDelete={timeOffDeleted}
            /> : me.roles.admin ?
            <AdminTimeOffDialog
                user={me}
                event={event}
                onCancel={() => setEvent(null)}
                onApproved={timeOffSaved}
                onDenied={timeOffSaved}
                onDelete={timeOffDeleted}
            /> : me.id === timeOff.requestedBy ?
            <MineTimeOffDialog
                user={me}
                event={event}
                onCancel={() => setEvent(null)}
                onApproved={timeOffSaved}
                onDenied={timeOffSaved}
                onDelete={timeOffDeleted}
            /> : null
    };

    const wide = dimensions.wide;
    const spinner = timeOffCache.loading;

    return <div className="time-off-page">
        <div className="calendar">
            <FullCalendar initialView={view || me.settings.timeOffView}
                          initialDate={date}
                          plugins={[dayGridPlugin]}
                          contentHeight={getContentHeight()}
                          height="100%"
                          displayEventTime={false}
                          displayEventEnd={false}
                          eventDisplay="block"
                          eventOrder={["start", "lastName", "firstName"]}
                          events={events}
                          eventClick={info => setEvent(info.event)}
                          eventContent={info => <TimeOffEvent user={me}
                                                              event={info.event}/>}
                          datesSet={dateRangeChanged}
                          validRange={{
                              start: getGoBackTo(me),
                          }}
                          headerToolbar={{
                              left: wide ? 'title' : '',
                              center: '',
                              right: 'today prev,next ' + VIEW_DAY_WEEK + ',' + VIEW_DAY_MONTH
                          }}
                          footerToolbar={wide ? null : {
                              left: 'title',
                          }}
            />
        </div>
        <FloatingButtons position="higher">
            <Fab color="primary" size="medium" title="Add time off"
                 onClick={() => router.redirectTo(history, PAGE_TIME_OFF_REQUEST)}
            >
                <AddIcon/>
            </Fab>
            <Fab color="primary" size="medium" title="Filter calendar"
                 onClick={() => setShowFilters(true)}
            >
                <FilterIcon/>
            </Fab>
        </FloatingButtons>
        {spinner && wide ? <CircularProgress size="40px"/> : null}
        {event ? renderTimeOffDialog() : null}
        {showFilters ? <FiltersDialog
            filters={filters}
            form={TimeOffFilters}
            formProps={{
                attributes: visibleAttributes,
                users: visibleUsers,
            }}
            onClose={() => setShowFilters(false)}
        /> : null}
    </div>
}

export default withRouter(TimeOffPage);
