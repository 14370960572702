import React from "react";
import AttributeUsersFilters from "../attributeUsersFilters/attributeUsersFilters";

const ScheduleFilters = (props) => {
    const {data, onChange, formProps} = props;

    return <div className="schedule-filters">
        <AttributeUsersFilters
            usersLabel="Assignees"
            data={data}
            onChange={onChange}
            formProps={formProps}
        />
    </div>
}

export default ScheduleFilters;
