import React from "react";
import {UserField} from "@atttomyx/react-components";
import {objects, strings} from "@atttomyx/shared-utils";

const AcceptTradeForm = (props) => {
    const { users, data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.userId);

        onSync(modified);
    };

    return <div className="accept-trade-fields">
        <div className="field">
            <UserField
                label="Give shift to"
                emptyLabel="No one"
                users={users}
                value={data.userId}
                onChange={(userId) => onChange("userId", userId)}
                multiple={false}
            />
        </div>
    </div>
};

export default AcceptTradeForm;
