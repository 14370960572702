import React, {Component} from 'react';
import {Prompt, withRouter} from "react-router-dom";
import {Button, CircularProgress, Typography} from '@mui/material';
import TimeOffForm from "../../forms/timeOffForm/timeOffForm";
import * as timeOffService from "../../services/timeOffs";
import {datetime} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {getDefaultDate} from "../../utils/timeOffs";
import {verbiage} from "@atttomyx/shared-constants";
import {PAGE_TIME_OFF} from "../../constants";

class AddTimeOffPage extends Component {

    constructor(props) {
        super(props);

        const user = this.props.user;
        const date = getDefaultDate(this.props.date);

        this.state = {
            timeOff: {
                requestedBy: user.id,
                start: datetime.beginningOfDay(date),
                stop: datetime.endOfDay(date),
                approved: true,
                valid: true,
            },
            saving: false,
            modified: false,
        };
    }

    setTimeOff(timeOff) {
        this.setState({
            timeOff: timeOff,
            modified: true,
        });
    }

    submitForm() {
        const user = this.props.user;
        const data = this.state.timeOff;

        this.setState({
            saving: true,
            modified: false,
        });

        const timeOff = {
            start: data.start,
            stop: data.stop,
            reason: data.reason,
        };

        if (user.roles.admin) {
            timeOff.requestedBy = data.requestedBy;
            timeOff.approved = data.approved;
            timeOff.notes = data.notes;

            timeOffService.createTimeOff(timeOff, this.timeOffSaved.bind(this));

        } else {
            timeOffService.createRequest(timeOff, this.timeOffSaved.bind(this));
        }
    }

    timeOffSaved(timeOff) {
        const history = this.props.history;
        const timeOffCache = this.props.timeOffCache;

        timeOffCache.onEntitySaved(timeOff);

        this.setState({
            saving: false,
        });

        router.redirectTo(history, PAGE_TIME_OFF);
    }

    render() {
        const user = this.props.user;
        const users = this.props.users;
        const timeOff = this.state.timeOff;
        const saving = this.state.saving;
        const modified = this.state.modified;

        return saving ?
            <div className="add-time-off-page">
                <CircularProgress size="80px"/>
            </div> :
            <div className="add-time-off-page">
                <Typography variant="h5" paragraph={true}>
                    Add Time Off
                </Typography>
                <TimeOffForm
                    user={user}
                    users={users}
                    timeOff={timeOff}
                    onChange={this.setTimeOff.bind(this)}
                />
                <div className="action">
                    <Button color="primary" size="large"
                            disabled={!timeOff.valid}
                            onClick={this.submitForm.bind(this)}>
                        Save
                    </Button>
                </div>
                <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
            </div>
    }
}

export default withRouter(AddTimeOffPage);
