import React from "react";
import {Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {SelectField} from "@atttomyx/react-components";
import TimeZoneField from "../../fields/timeZoneField/timeZoneField";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";
import {VIEW_DAY_MONTH, VIEW_DAY_WEEK, VIEW_TIME_DAY} from "../../constants";

const OPTIONS_SCHEDULE = {
    [VIEW_TIME_DAY]: "Day",
    [VIEW_DAY_WEEK]: "Week",
    [VIEW_DAY_MONTH]: "Month",
};

const OPTIONS_TIME_OFF = {
    [VIEW_DAY_WEEK]: "Week",
    [VIEW_DAY_MONTH]: "Month",
};

const UserSettingsForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.theme);

        onSync(modified);
    };

    return <div className="user-settings-fields">
        <div className="field">
            <FormLabel id="theme">Theme</FormLabel>
            <RadioGroup row={true} aria-labelledby="theme"
                        onChange={(event) => onChange("theme", event.target.value)}>
                <FormControlLabel
                    value={themes.LIGHT}
                    label="Light"
                    control={<Radio checked={data.theme === themes.LIGHT} color="primary"/>}
                />
                <FormControlLabel
                    value={themes.DARK}
                    label="Dark"
                    control={<Radio checked={data.theme === themes.DARK} color="primary"/>}
                />
            </RadioGroup>
        </div>
        <div className="field">
            <SelectField
                label="Schedule default view"
                required={true}
                value={data.scheduleView}
                onChange={(value) => onChange("scheduleView", value)}
                options={OPTIONS_SCHEDULE}
            />
        </div>
        <div className="field">
            <SelectField
                label="Time Off default view"
                required={true}
                value={data.timeOffView}
                onChange={(value) => onChange("timeOffView", value)}
                options={OPTIONS_TIME_OFF}
            />
        </div>
        <div className="field">
            <TimeZoneField value={data.timeZone}
                           onChange={(timeZone) => onChange("timeZone", timeZone)}/>
        </div>
        <div className="option">
            <FormControlLabel label="Allow other users to offer me trades"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.acceptTrades)}
                                                 onChange={(event) => onChange("acceptTrades", event.target.checked)}
                              />}
            />
        </div>
        <div className="option">
            <FormControlLabel label="Allow other users to call me"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.allowCalls)}
                                                 onChange={(event) => onChange("allowCalls", event.target.checked)}
                              />}
            />
        </div>
        <div className="option">
            <FormControlLabel label="Allow other users to text me"
                              control={<Checkbox color="primary"
                                                 checked={forms.sanitizeOption(data.allowSms)}
                                                 onChange={(event) => onChange("allowSms", event.target.checked)}
                              />}
            />
        </div>
    </div>
}

export default UserSettingsForm;
