import React, {Component} from "react";
import {Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {UserField} from "@atttomyx/react-components";
import {datetime, forms, objects, strings} from "@atttomyx/shared-utils";
import {APPROVED, DENIED} from "../../constants";

class TimeOffForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allDay: true,
        };
    }

    onChange(field, value) {
        const user = this.props.user;
        const timeOff = this.props.timeOff;
        const modified = objects.deepCopy(timeOff);

        modified[field] = value;
        modified.valid = (strings.isNotBlank(modified.requestedBy) || !user.roles.admin)
            && modified.start && modified.stop && modified.stop >= modified.start
            && !datetime.isPastDate(modified.start) && !datetime.isPastDate(modified.stop);

        this.props.onChange(modified);
    }

    setRequestedBy(userId) {
        this.onChange("requestedBy", userId);
    }

    setStartDate(event) {
        const start = event.target.value;

        this.onChange("start", datetime.beginningOfDay(start));
    }

    setStopDate(event) {
        const stop = event.target.value;

        this.onChange("stop", datetime.endOfDay(stop));
    }

    setStartDateTime(event) {
        const start = event.target.value;

        this.onChange("start", start);
    }

    setStopDateTime(event) {
        const stop = event.target.value;

        this.onChange("stop", stop);
    }

    setReason(event) {
        const reason = event.target.value;

        this.onChange("reason", reason);
    }

    setApproved(event) {
        const approved = event.target.value === APPROVED;

        this.onChange("approved", approved);
    }

    setNotes(event) {
        const notes = event.target.value;

        this.onChange("notes", notes);
    }

    setAllDay(event) {
        const allDay = event.target.checked;

        this.setState({
            allDay: allDay,
        });
    }

    render() {
        const user = this.props.user;
        const users = this.props.users;
        const timeOff = this.props.timeOff;
        const allDay = this.state.allDay;

        return <div className="time-off-fields">
            {user.roles.admin ? <div className="field">
                <UserField label="Requested by" required={true}
                           users={users}
                           value={timeOff.requestedBy}
                           onChange={this.setRequestedBy.bind(this)}
                           disabled={this.props.disableRequestedBy}
                />
            </div> : null}
            {allDay ?
                <>
                    <div className="field">
                        <TextField label="Start" required={true}
                                   type="date"
                                   value={forms.sanitizeValue(datetime.getDate(timeOff.start))}
                                   error={datetime.isPastDate(timeOff.start)}
                                   onChange={this.setStartDate.bind(this)}
                        />
                    </div>
                    <div className="field">
                        <TextField label="Stop" required={true}
                                   type="date"
                                   error={timeOff.start > timeOff.stop || datetime.isPastDate(timeOff.stop)}
                                   value={forms.sanitizeValue(datetime.getDate(timeOff.stop))}
                                   onChange={this.setStopDate.bind(this)}
                        />
                    </div>
                </> :
                <>
                    <div className="field">
                        <TextField label="Start" required={true}
                                   type="datetime-local"
                                   value={forms.sanitizeValue(timeOff.start)}
                                   error={datetime.isPastDate(timeOff.start)}
                                   onChange={this.setStartDateTime.bind(this)}
                                   inputProps={{
                                       step: 300,   // 5 min
                                   }}
                        />
                    </div>
                    <div className="field">
                        <TextField label="Stop" required={true}
                                   type="datetime-local"
                                   value={forms.sanitizeValue(timeOff.stop)}
                                   error={timeOff.start > timeOff.stop || datetime.isPastDate(timeOff.stop)}
                                   onChange={this.setStopDateTime.bind(this)}
                                   inputProps={{
                                       step: 300,   // 5 min
                                   }}
                        />
                    </div>
                </>}
            <div className="option">
                <FormControlLabel labelPlacement="end"
                                  label="All day"
                                  control={<Checkbox color="primary"
                                                     checked={allDay}
                                                     onChange={this.setAllDay.bind(this)}
                                  />}
                />
            </div>
            <div className="field">
                <TextField label="Reason"
                           type="text"
                           multiline={true}
                           value={forms.sanitizeValue(timeOff.reason)}
                           onChange={this.setReason.bind(this)}
                />
            </div>
            {user.roles.admin ? <div className="option">
                <FormControl component="fieldset">
                    <RadioGroup row={true} onChange={this.setApproved.bind(this)}>
                        <FormControlLabel
                            value={APPROVED}
                            label="Approved"
                            control={<Radio checked={forms.sanitizeOption(timeOff.approved)} color="primary"/>}
                        />
                        <FormControlLabel
                            value={DENIED}
                            label="Denied"
                            control={<Radio checked={forms.sanitizeOption(!timeOff.approved)} color="secondary"/>}
                        />
                    </RadioGroup>
                </FormControl>
            </div> : null}
            {user.roles.admin ? <div className="field">
                <TextField label="Notes"
                           type="text"
                           multiline={true}
                           value={forms.sanitizeValue(timeOff.notes)}
                           onChange={this.setNotes.bind(this)}
                />
            </div> : null}
        </div>
    }
}

export default TimeOffForm;
