import {datetime} from "@atttomyx/shared-utils";
import {filters} from "@atttomyx/shared-constants";
import {TYPE_NO_SHOW} from "../constants";

// filters users
export const getUserFilters = (attributeRenderer) => {
    return {
        firstName: {
            type: filters.TYPE_STRING,
        },
        lastName: {
            type: filters.TYPE_STRING,
        },
        attributeId: {
            type: filters.TYPE_ARRAY,
            label: "qualification",
            getter: (item) => item.settings.attributeIds,
            formatter: (value) => attributeRenderer.render(value),
        },
        role: {
            type: filters.TYPE_BOOLEAN,
            getter: (item) => item.roles.admin,
            formatter: (value) => value === filters.TRUE ? "Admin" : "User",
        },
    }
};

// filters shifts
export const getScheduleFilters = (userRenderer, attributeRenderer) => {
    return {
        attributeId: {
            label: "qualification",
            getter: (item) => item.settings.attributeIds,
            formatter: (value) => attributeRenderer.render(value),
        },
        userIds: {
            label: "assignees",
            formatter: (value) => userRenderer.render(value),
            hidden: (data) => data.userIds.length === 0,
        },
    }
};

export const SCHEDULE_FILTER_DATA = {
    attributeId: null,
    userIds: [],
};

// filters users
export const getTimeOffFilters = (userRenderer, attributeRenderer) => {
    return {
        attributeId: {
            label: "qualification",
            getter: (item) => item.settings.attributeIds,
            formatter: (value) => attributeRenderer.render(value),
        },
        userIds: {
            label: "users",
            formatter: (value) => userRenderer.render(value),
            hidden: (data) => data.userIds.length === 0,
        },
    }
};

export const TIME_OFF_FILTER_DATA = {
    attributeId: null,
    userIds: [],
};

// filters users
export const getAttendanceFilters = (attributeRenderer) => {
    return {
        firstName: {
            type: filters.TYPE_STRING,
        },
        lastName: {
            type: filters.TYPE_STRING,
        },
        attributeId: {
            type: filters.TYPE_ARRAY,
            label: "qualification",
            getter: (item) => item.settings.attributeIds,
            formatter: (value) => attributeRenderer.render(value),
        },
        shift: {
            type: filters.TYPE_BOOLEAN,
            getter: (item) => item.decorations ? item.decorations.shift : undefined,
            formatter: (value) => value === filters.TRUE ? "assigned" : "not assigned",
        },
        started: {
            type: filters.TYPE_BOOLEAN,
            getter: (item) => item.decorations ? item.decorations.started : undefined,
            formatter: (value) => value === filters.TRUE ? "yes" : "not yet",
        },
    }
};

export const ATTENDANCE_FILTER_DATA = {
    firstName: null,
    lastName: null,
    attributeId: null,
    shift: filters.TRUE,
    started: filters.TRUE,
};

// filters attendances
export const getAttendanceReportFilters = (userRenderer, attributeRenderer) => {
    return {
        attributeId: {
            label: "who",
            formatter: (value) => attributeRenderer.render(value),
            hidden: (data) => data.userIds.length > 0,
        },
        userIds: {
            label: "who",
            formatter: (value) => userRenderer.render(value),
            hidden: (data) => data.userIds.length === 0,
        },
        fromDate: {
            label: "from",
        },
        untilDate: {
            label: "until",
        },
        type: {},
    }
};

export const getAttendanceReportFiltersData = () => {
    const now = datetime.today();

    return {
        attributeId: null,
        userIds: [],
        fromDate: datetime.beginningOfMonthsAgo(now, 3),
        untilDate: now,
        type: TYPE_NO_SHOW,
    };
};

// filters volunteers
export const getVolunteerReportFilters = (userRenderer, attributeRenderer) => {
    return {
        attributeId: {
            label: "who",
            formatter: (value) => attributeRenderer.render(value),
            hidden: (data) => data.userIds.length > 0,
        },
        userIds: {
            label: "who",
            formatter: (value) => userRenderer.render(value),
            hidden: (data) => data.userIds.length === 0,
        },
        fromDate: {
            label: "from",
        },
        untilDate: {
            label: "until",
        },
    }
};

export const getVolunteerReportFiltersData = () => {
    const now = datetime.today();

    return {
        attributeId: null,
        userIds: [],
        fromDate: datetime.beginningOfMonthsAgo(now, 3),
        untilDate: now,
    };
};

// filters trades
export const getTradeReportFilters = (userRenderer, attributeRenderer) => {
    return {
        attributeId: {
            label: "who",
            formatter: (value) => attributeRenderer.render(value),
            hidden: (data) => data.userIds.length > 0,
        },
        userIds: {
            label: "who",
            formatter: (value) => userRenderer.render(value),
            hidden: (data) => data.userIds.length === 0,
        },
        fromDate: {
            label: "from",
        },
        untilDate: {
            label: "until",
        },
    }
};

export const getTradeReportFiltersData = () => {
    const now = datetime.today();

    return {
        attributeId: null,
        userIds: [],
        fromDate: datetime.beginningOfMonthsAgo(now, 3),
        untilDate: now,
    };
};
