import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {AppBar, Badge, BottomNavigation, BottomNavigationAction} from "@mui/material";
import {
    Assignment as AttendanceIcon,
    BeachAccess as TimeOffIcon,
    Home as HomeIcon,
    MoreHoriz as MoreIcon,
    Notifications as NotificationsIcon,
    Schedule as ScheduleIcon,
} from "@mui/icons-material";
import MoreMenu from "../moreMenu/moreMenu";
import {router} from "@atttomyx/react-utils";
import {arrays, strings} from "@atttomyx/shared-utils";
import {accounts as accountConstants} from "@atttomyx/shared-constants";
import {
    PAGE_ATTENDANCE,
    PAGE_DISABLED,
    PAGE_INTRO,
    PAGE_NOTIFICATIONS,
    PAGE_SCHEDULE,
    PAGE_SHIFT,
    PAGE_TIME_OFF,
    PAGE_TIME_OFF_REQUEST,
    PAGE_USER,
    PAGES_MAIN,
    PAGES_MORE,
} from "../../constants";
import "./footer.css";

const PAGE_MORE = "more";
const PAGE_OTHER = "other";

const Footer = (props) => {
    const {
        snackbar, history, accounts, account, user, numNotifications, numTodos,
        onLogout, onSwitchAccounts, authService
    } = props;
    const [showMore, setShowMore] = useState(false);

    const eulaRequired = !user.eula;
    const billingRequired = account.status === accountConstants.STATUS_DISABLED;
    const disabled = eulaRequired || billingRequired;

    const path = billingRequired ? PAGE_DISABLED : strings.sanitizeStr(router.getCurrentPage(history));
    let page = PAGE_OTHER;

    if (arrays.contains(PAGES_MAIN, path)
        || ((path === PAGE_TIME_OFF && !user.roles.admin))) {

        page = path;

    } else if (path === PAGE_USER || path.startsWith(PAGE_USER + "/")) {
        page = PAGE_MORE;

    } else if (path === PAGE_SHIFT || path.startsWith(PAGE_SHIFT + "/")) {
        page = PAGE_SCHEDULE;

    } else if (path.startsWith(PAGE_TIME_OFF_REQUEST + "/")) {
        page = user.roles.admin ? PAGE_MORE : PAGE_TIME_OFF;

    } else if (arrays.contains(PAGES_MORE, path)) {
        page = PAGE_MORE;
    }

    return <div className="footer">
        <AppBar className="bottom-nav" position="fixed" color="default">
            <BottomNavigation
                value={page}
                showLabels={true}
            >
                {numTodos > 0 ?
                    <BottomNavigationAction
                        value={PAGE_INTRO}
                        label="Intro"
                        icon={
                            <Badge
                                color="error"
                                badgeContent={numTodos}
                                invisible={disabled || numTodos === 0}>
                                <HomeIcon/>
                            </Badge>}
                        disabled={disabled}
                        onClick={() => router.redirectTo(history, PAGE_INTRO)}
                    /> : null}
                <BottomNavigationAction
                    value={PAGE_SCHEDULE}
                    label="Schedule"
                    icon={<ScheduleIcon/>}
                    disabled={disabled}
                    onClick={() => router.redirectTo(history, PAGE_SCHEDULE)}
                />
                {user.roles.admin ?
                    <BottomNavigationAction
                        value={PAGE_ATTENDANCE}
                        label="Attendance"
                        icon={<AttendanceIcon/>}
                        disabled={disabled}
                        onClick={() => router.redirectTo(history, PAGE_ATTENDANCE)}
                    /> :
                    <BottomNavigationAction
                        value={PAGE_TIME_OFF}
                        label="Time Off"
                        icon={<TimeOffIcon/>}
                        disabled={disabled}
                        onClick={() => router.redirectTo(history, PAGE_TIME_OFF)}
                    />}
                <BottomNavigationAction
                    value={PAGE_NOTIFICATIONS}
                    label="Notifications"
                    icon={
                        <Badge
                            color="error"
                            badgeContent={numNotifications}
                            invisible={disabled || numNotifications === 0}>
                            <NotificationsIcon/>
                        </Badge>}
                    disabled={disabled}
                    onClick={() => router.redirectTo(history, PAGE_NOTIFICATIONS)}
                />
                <BottomNavigationAction
                    value={PAGE_MORE}
                    label="More"
                    icon={<MoreIcon/>}
                    component="div"
                    onClick={() => setShowMore(true)}
                />
            </BottomNavigation>
        </AppBar>
        {showMore ?
            <MoreMenu
                snackbar={snackbar}
                accounts={accounts}
                account={account}
                user={user}
                onCancel={() => setShowMore(false)}
                onLogout={onLogout}
                onSwitchAccounts={onSwitchAccounts}
                authService={authService}
            /> : null}
    </div>
}

export default withRouter(Footer);
