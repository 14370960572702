import React, {useMemo} from "react";
import {SelectField} from "@atttomyx/react-components";
import {renderTimeSlot} from "../../utils/timeSlots";
import {ADD_NEW} from "../../constants";

const TimeSlotField = (props) => {
    const {timeSlots, value, onChange, showAddNew, required, disabled, error} = props;

    const options = useMemo(() => {
        const map = {};

        timeSlots.forEach(timeSlot => map[timeSlot.id] = renderTimeSlot(timeSlot));

        if (showAddNew) {
            map[ADD_NEW] = ADD_NEW;
        }

        return map;
    }, [timeSlots, showAddNew]);

    return <div className="time-slot-field">
        <SelectField
            label="Time slot"
            required={required}
            disabled={disabled}
            value={value}
            error={error}
            onChange={onChange}
            options={options}
        />
    </div>
}

export default TimeSlotField;
