import axios from 'axios';
import {sanitizeAccount, sanitizeMyAccount, standardizeBranding} from "../utils/accounts";
import {sanitizeUser} from "../utils/users";
import {CLOUDINARY_ID} from "../constants";

export const joinAccount = (user, code, success, failure) => {
    // need to skip the request interceptors (don't want to redirect to login on 401)
    const instance = axios.create();
    const url = "/api/v1/account/join";

    instance.post(url, {
        firstName: user.firstName,
        lastName: user.lastName,
        alias: user.alias,
        email: user.email,
        phone: user.phone,
        password: user.password,
        code: code,
    })
    .then(response => {
        const json = response.data;

        sanitizeAccount(json.account);
        sanitizeUser(json.user);

        success(json);
    })
    .catch(failure);
};

export const joinAdditionalAccount = (code, success, failure) => {
    axios.put("/api/v1/account/joinAdditional", {
        code: code,
    })
    .then(response => {
        const json = response.data;

        sanitizeAccount(json.account);
        sanitizeUser(json.user);

        success(json);
    })
    .catch(failure);
};

export const loadMyAccount = (success, failure) => {
    axios.get("/api/v1/account/")
    .then(response => {
        const account = response.data;

        sanitizeMyAccount(account);
        success(account);
    })
    .catch(failure);
};

export const saveMyAccount = (account, success, failure) => {
    axios.put("/api/v1/account/", {
        name: account.name,
        website: account.website,
        branding: standardizeBranding(account.branding),
        allowJoin: account.allowJoin,
        settings: account.settings,
    })
    .then(response => {
        const account = response.data;

        sanitizeMyAccount(account);
        success(account);
    })
    .catch(failure);
};

export const postSchedule = (until, success, failure) => {
    axios.put("/api/v1/account/postSchedule", {
        until: until,
    })
    .then(response => {
        const account = response.data;

        sanitizeMyAccount(account);
        success(account);
    })
    .catch(failure);
};

export const uploadImage = (accountId, logo, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "account_image");
    data.append("file", logo);
    data.append("tags", accountId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        success(response.data.secure_url);
    })
    .catch(failure);
};
