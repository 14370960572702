import React from "react";
import {TextField} from "@mui/material";
import {BoolFilterField} from "@atttomyx/react-components";
import AttributeField from "../../fields/attributeField/attributeField";
import {forms, objects} from "@atttomyx/shared-utils";

const UserFilters = (props) => {
    const { data, onChange : onSync, formProps } = props;
    const { attributeIdToTitle } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="user-filters">
        <div className="field">
            <TextField label="First name"
                       type="text"
                       value={forms.sanitizeValue(data.firstName)}
                       onChange={(event) => onChange("firstName", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Last name"
                       type="text"
                       value={forms.sanitizeValue(data.lastName)}
                       onChange={(event) => onChange("lastName", event.target.value)}
            />
        </div>
        <div className="field">
            <AttributeField
                attributeIdToTitle={attributeIdToTitle}
                value={data.attributeId}
                onChange={(value) => onChange("attributeId", value)}
            />
        </div>
        <div className="field">
            <BoolFilterField label="Role"
                             value={forms.sanitizeValue(data.role)}
                             onChange={(value) => onChange("role", value)}
                             trueLabel="Admin"
                             falseLabel="User"
            />
        </div>
    </div>
}

export default UserFilters;
