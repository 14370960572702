import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {isDesktop} from "react-device-detect";
import {useReactToPrint} from "react-to-print";
import csvDownload from "json-to-csv-export";
import {Button, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {Print as PrintIcon} from "@mui/icons-material";
import {FiltersAccordion, Info, UserAvatar} from "@atttomyx/react-components";
import VolunteerReportFilters from "../../filters/volunteerReportFilters/volunteerReportFilters";
import ReportShiftsDetails from "../../components/reportShiftsDetails/reportShiftsDetails";
import ReportShiftsDialog from "../../dialogs/reportShiftsDialog/reportShiftsDialog";
import Printable from "../../components/printable/printable";
import {arrays, objects, sorting, users as userUtils} from "@atttomyx/shared-utils";
import {toCompositeId} from "../../utils/shifts";
import {
    ATTENDANCE_CAME_IN,
    ATTENDANCE_NO_SHOW,
    CSV_DELIMITER,
    CSV_FILENAME_VOLUNTEER,
    CSV_HEADERS_VOLUNTEER,
    TYPE_NO_SHOW
} from "../../constants";
import "./volunteerReportPage.css";

const VolunteerReportPage = (props) => {
    const {users, deletedUsers, attributes, volunteers, shiftCache, filters} = props;
    const [usersById, setUsersById] = useState(null);
    const [deletedUserIds, setDeletedUserIds] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const data = filters.data || {};

    const ref = useRef(null);
    const printReport = useReactToPrint({
        content: () => ref.current,
        documentTitle: data.type === TYPE_NO_SHOW ? ATTENDANCE_NO_SHOW : ATTENDANCE_CAME_IN,
    });

    useEffect(() => {
        if (showResults) {
            const compositeToShift = {};
            const userIdToRequestedShifts = {};
            const userIdToAssignedShifts = {};
            const attributeId = data.attributeId;
            const userIds = data.userIds;

            shiftCache.entities.forEach(shift => {
                const composite = toCompositeId(shift.key);

                compositeToShift[composite] = shift;
            });

            volunteers.filter(volunteer => userIds.length === 0 || arrays.contains(userIds, volunteer.userId))
            .forEach(volunteer => {
                const composite = toCompositeId(volunteer.shiftKey);
                const shift = compositeToShift[composite];

                if (shift) {
                    if (!attributeId || shift.attributeId === attributeId) {
                        if (volunteer.assigned) {
                            const array = userIdToAssignedShifts[volunteer.userId] || [];

                            arrays.addTo(array, shift);
                            userIdToAssignedShifts[volunteer.userId] = array;

                        } else {
                            const array = userIdToRequestedShifts[volunteer.userId] || [];

                            arrays.addTo(array, shift);
                            userIdToRequestedShifts[volunteer.userId] = array;
                        }
                    }
                }
            });

            const mostAtTop = (a, b) => {
                return sorting.sortByFieldDesc(a, b, "total");
            };
            const alphabetical = (a, b) => {
                return userUtils.sortByName(a.user, b.user);
            };
            const composite = sorting.getCompositeSorter([mostAtTop, alphabetical]);
            const userIdToResult = {};

            Object.entries(userIdToRequestedShifts)
                .filter(([userId, shifts]) => objects.notNullOrUndefined(usersById[userId]) && shifts.length > 0)
                .forEach(([userId, shifts]) => {
                    userIdToResult[userId] = {
                        userId: userId,
                        user: usersById[userId],
                        shifts: shifts,
                        requested: shifts.length,
                        assigned: 0,
                        total: shifts.length,
                    };
                });

            Object.entries(userIdToAssignedShifts)
                .filter(([userId, shifts]) => objects.notNullOrUndefined(usersById[userId]) && shifts.length > 0)
                .forEach(([userId, shifts]) => {
                    const result = userIdToResult[userId] || {
                        userId: userId,
                        user: usersById[userId],
                        shifts: [],
                        requested: 0,
                        assigned: 0,
                        total: 0,
                    };

                    result.shifts = arrays.addAll(result.shifts, shifts);
                    result.assigned = shifts.length;
                    result.total = result.total + shifts.length;

                    userIdToResult[userId] = result;
                });

            const sorted = Object.values(userIdToResult).sort(composite);

            setResults(sorted);

        } else {
            setResults(null);
        }
    }, [showResults, volunteers, shiftCache.entities]);

    useEffect(() => {
        if (filters.data.fromDate && filters.data.untilDate) {
            shiftCache.load(filters.data.fromDate, filters.data.untilDate);
        }
    }, [filters.data]);

    useEffect(() => {
        const idToUser = arrays.getIdToEntity(users);
        const deleted = [];

        deletedUsers.map(user => {
            const userId = user.id;

            idToUser[userId] = user;
            arrays.addTo(deleted, userId);
        });

        setUsersById(idToUser);
        setDeletedUserIds(deleted);
    }, [users, deletedUsers]);

    const downloadCsv = () => {
        const lines = [];

        results.forEach(result => {
            const user = result.user;
            const name = userUtils.getFullName(user);

            result.shifts.forEach(shift => {
                const momentStart = moment(shift.start);
                const momentStop = moment(shift.stop);

                arrays.addTo(lines, {
                    name: name,
                    gotIt: arrays.contains(shift.userIds, user.id),
                    startDayOfWeek: momentStart.format("dddd"),
                    startIso: momentStart.toISOString(),
                    stopDayOfWeek: momentStop.format("dddd"),
                    stopIso: momentStop.toISOString(),
                });
            });
        });

        csvDownload({
            data: lines,
            delimiter: CSV_DELIMITER,
            headers: CSV_HEADERS_VOLUNTEER,
            filename: CSV_FILENAME_VOLUNTEER,
        });
    };

    return <div className="volunteer-report-page">
        <Typography variant="h5" paragraph={true}>
            Volunteer Report
        </Typography>
        <FiltersAccordion
            filters={filters}
            form={VolunteerReportFilters}
            formProps={{
                users: users,
                attributes: attributes,
            }}
            open={!showResults}
            mayDeleteCriteria={false}
        />
        <div className="actions">
            {isDesktop ?
                <>
                    <Button color="secondary"
                            disabled={!showResults}
                            onClick={printReport}>
                        <PrintIcon/> Print
                    </Button>
                    <Button color="secondary"
                            disabled={!showResults}
                            onClick={downloadCsv}>
                        Download CSV
                    </Button>
                </> : null}
            <Button color="primary"
                    onClick={() => setShowResults(!showResults)}>
                {showResults ? "Edit Filters" : "Run Report"}
            </Button>
        </div>
        {results ? <>
            <Divider/>
            {results.length > 0 ? <>
                    <Printable ref={ref}>
                        <Typography variant="h5" className="print-only">
                            Volunteers
                        </Typography>
                        <Table size="small" cellPadding={0} cellSpacing={0} className="results striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>
                                        Requests
                                    </TableCell>
                                    <TableCell>
                                        Got&nbsp;it
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.map((result) =>
                                    <TableRow key={result.userId} className="result"
                                              onClick={() => setResult(result)}>
                                        <TableCell className={arrays.contains(deletedUserIds, result.userId) ? "bogus" : ""}>
                                            <UserAvatar user={result.user} mode="right" size="small"/>
                                        </TableCell>
                                        <TableCell>
                                            {result.total}
                                        </TableCell>
                                        <TableCell>
                                            {result.assigned}
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                        <div className="print-only">
                            {results.map(result => <div key={result.userId}>
                                <Divider/>
                                <UserAvatar user={result.user} mode="right" size="small"/>
                                <ReportShiftsDetails
                                    user={result.user}
                                    shifts={result.shifts}
                                    wide={true}
                                />
                            </div>)}
                        </div>
                    </Printable>
                    <Info>
                        Click on a user to see the shifts they volunteered for
                    </Info>
                </> :
                <Typography>
                    No results
                </Typography>}
        </> : null}
        {result ? <ReportShiftsDialog
            user={result.user}
            shifts={result.shifts}
            type={data.type}
            onCancel={() => setResult(null)}
        /> : null}
    </div>
};

export default VolunteerReportPage;
