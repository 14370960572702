import React, {useState} from 'react';
import {IconButton, Typography} from '@mui/material';
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import TimeSlotCard from "../../cards/timeSlotCard/timeSlotCard";
import TimeSlotDialog from "../../dialogs/timeSlotDialog/timeSlotDialog";
import {Cards, ConfirmDeleteDialog, FloatingAddButton} from "@atttomyx/react-components";
import {usePaging} from "@atttomyx/shared-hooks";
import * as timeSlotService from "../../services/timeSlots";
import {objects} from "@atttomyx/shared-utils";

const TimeSlotsPage = (props) => {
    const { dimensions, snackbar, timeSlots, onSave, onDelete } = props;
    const [ toEdit, setToEdit ] = useState(null);
    const [ toDelete, setToDelete ] = useState(null);
    const paging = usePaging();

    return <div className="time-slots-page">
        <Typography variant="h5">
            Time Slots
        </Typography>
        <Cards
            items={timeSlots}
            paging={paging}
            dimensions={dimensions}
            renderer={(timeSlot) =>
                <TimeSlotCard key={timeSlot.id}
                              timeSlot={timeSlot}
                >
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => setToDelete(timeSlot)}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                onClick={() => setToEdit(objects.deepCopy(timeSlot))}>
                        <EditIcon/>
                    </IconButton>
                </TimeSlotCard>}
        />
        <FloatingAddButton title="Add time slot" position="higher"
                           onClick={() => setToEdit({
                               start: "09:00",
                               stop: "17:00",
                           })}
        />
        {toEdit ? <TimeSlotDialog
            timeSlot={toEdit}
            onCancel={() => setToEdit(null)}
            onSaved={(timeSlot) => {
                setToEdit(null);
                onSave(timeSlot);
            }}
        /> : null}
        {toDelete ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={toDelete.title}
            onCancel={() => setToDelete(null)}
            onDelete={(timeSlotId) => {
                setToDelete(null);
                onDelete(timeSlotId);
            }}
            deleter={(success, failure) => timeSlotService.deleteTimeSlot(toDelete.id, success, failure)}
        /> : null}
    </div>
}

export default TimeSlotsPage;
