import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    Typography
} from "@mui/material";
import {ArrowBack as BackIcon, Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {ClosableDialogTitle, TabBar, TabPanel, UserAvatar} from "@atttomyx/react-components";
import ShiftInfo from "../../../components/shiftInfo/shiftInfo";
import TimeOffForm from "../../../forms/timeOffForm/timeOffForm";
import RequestTradeForm from "../../../forms/requestTradeForm/requestTradeForm";
import AcceptTradeForm from "../../../forms/acceptTradeForm/acceptTradeForm";
import * as tradeService from "../../../services/trades";
import * as timeOffService from "../../../services/timeOffs";
import {calculateHours, findAvailableUsers, timeOffOverlaps} from "../../../utils/shifts";
import {arrays, datetime, forms, objects} from "@atttomyx/shared-utils";
import {confirm} from "@atttomyx/react-utils";
import "./mineShiftDialog.css";

const toTradeData = (trade) => {
    const userIds = trade ? trade.requestedIds : [];

    return {
        userIds: userIds,
        reason: trade ? trade.reason : null,
        valid: userIds.length > 0,
    }
};

const toAcceptData = () => {
    return {
        userId: null,
        valid: false,
    }
};

const toTimeOffData = (user, shift) => {
    const start = datetime.getDate(shift.start);
    const stop = datetime.getDate(shift.stop);

    return {
        requestedBy: user.id,
        start: datetime.beginningOfDay(start),
        stop: datetime.endOfDay(stop),
        valid: true,
    }
};

const MineShiftDialog = (props) => {
    const {snackbar, settings, user, users, event, shiftCache, timeOffCache,
        onSaveTrade, onDeleteTrade, onApproveTrade, onCancel} = props;

    const customFields = event.extendedProps;
    const shift = customFields.shift;
    const trade = customFields.trade;

    const [availableUsers, setAvailableUsers] = useState([]);
    const [requesteds, setRequesteds] = useState([]);
    const [volunteers, setVolunteers] = useState([]);
    const [tab, setTab] = useState(0);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tradeData, setTradeData] = useState(toTradeData(trade));
    const [acceptData, setAcceptData] = useState(toAcceptData());
    const [timeOffData, setTimeOffData] = useState(toTimeOffData(user, shift));
    const [requested, setRequested] = useState(false);
    const [showRequest, setShowRequest] = useState(false);

    useEffect(() => {
        const allowOvertime = settings.allowTradeOvertime;
        const hoursBeforeOvertime = settings.hoursBeforeOvertime;
        const maxSequentialHours = settings.maxSequentialHours;

        timeOffOverlaps(shift, timeOffCache, user.id, setRequested);

        if (!allowOvertime) {
            calculateHours(shiftCache, timeOffCache, shift.start, maxSequentialHours, userIdToHours => {
                findAvailableUsers(shift, users, shiftCache, timeOffCache, false, allowOvertime, hoursBeforeOvertime,
                    maxSequentialHours, userIdToHours, setAvailableUsers);
            });

        } else {
            findAvailableUsers(shift, users, shiftCache, timeOffCache, false, allowOvertime, hoursBeforeOvertime,
                maxSequentialHours, {}, setAvailableUsers);
        }
    }, []);

    useEffect(() => {
        if (trade && availableUsers) {
            const requestedIds = trade.requestedIds || [];
            const volunteerIds = trade.volunteerIds || [];

            setRequesteds(availableUsers.filter(candidate => arrays.contains(requestedIds, candidate.id)));
            setVolunteers(availableUsers.filter(candidate => arrays.contains(volunteerIds, candidate.id)));
        }
    }, [availableUsers]);

    const resetForm = () => {
        setTradeData(toTradeData(trade));
        setAcceptData(toAcceptData());
        setTimeOffData(toTimeOffData(user, shift));
    };

    const cancelTrade = () => {
        setDeleting(true);

        const success = (tradeId) => {
            setDeleting(false);
            onDeleteTrade(tradeId);
            snackbar.setSuccess("Fill-in request cancelled");
        };

        tradeService.deleteTrade(trade.id, success, snackbar.setError);
    };

    const submitTrade = () => {
        setSaving(true);

        const data = {
            shiftKey: shift.key,
            userIds: tradeData.userIds,
            reason: tradeData.reason,
        };

        const success = (trade) => {
            setSaving(false);
            onSaveTrade(trade);
            snackbar.setSuccess("Fill-in requested");
        };

        if (trade) {
            tradeService.editTrade(trade.id, data, success, snackbar.setError);

        } else {
            tradeService.requestTrade(data, success, snackbar.setError);
        }
    };

    const submitAccept = () => {
        setSaving(true);

        const success = (trade) => {
            setSaving(false);
            onApproveTrade(trade);
            snackbar.setSuccess("Shift filled-in");
        };

        tradeService.approveTrade(trade.id, acceptData.userId, success, snackbar.setError);
    };

    const submitTimeOff = () => {
        setSaving(true);

        const data = {
            start: timeOffData.start,
            stop: timeOffData.stop,
            reason: timeOffData.reason,
        };

        const success = (timeOff) => {
            setSaving(false);
            setRequested(true);
            resetForm();
            timeOffCache.onEntitySaved(timeOff);
            snackbar.setSuccess("Time off requested");
        };

        timeOffService.createRequest(data, success, snackbar.setError);
    };

    const modifiedTrade = forms.differ(tradeData, toTradeData(trade));
    const modifiedAccept = forms.differ(acceptData, toAcceptData());
    const modifiedTimeOff = forms.differ(timeOffData, toTimeOffData(user, shift));

    return <Dialog
        open={true}
        aria-labelledby="shift-dialog-content"
        className="mine-shift-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            My Shift
        </ClosableDialogTitle>
        <DialogContent className="shift-dialog-content">
            {saving || deleting ?
                <CircularProgress size="40px"/> :
                <div>
                    <ShiftInfo event={event}/>
                    <Divider/>
                    <div className="info">
                        <TabBar
                            tabs={["Request fill-in", "Request time off"]}
                            value={tab}
                            onChange={setTab}
                        />
                        <TabPanel value={tab} index={0}>
                            {trade && !showRequest ?
                                <>
                                    {volunteers.length > 0 ?
                                        <AcceptTradeForm
                                            users={volunteers}
                                            data={acceptData}
                                            onChange={setAcceptData}
                                        /> :
                                        <>
                                            <Typography>
                                                Waiting for volunteers...
                                            </Typography>
                                            <Typography variant="caption">
                                                Sent request to:
                                            </Typography>
                                            {requesteds.length > 0 ?
                                                <div className="field">
                                                    {requesteds.map(user => <UserAvatar key={user.id} user={user} mode="right" size="small"/>)}
                                                </div> : null}
                                        </>}
                                    <div className="buttons">
                                        <IconButton color="primary" title="Edit fill-in"
                                                    onClick={() => setShowRequest(true)}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton color="secondary" title="Cancel fill-in"
                                                    onClick={() => confirm.confirm("Are you sure you want to cancel this fill-in request?", cancelTrade)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                </> :
                                <>
                                    <RequestTradeForm
                                        users={availableUsers}
                                        data={tradeData}
                                        onChange={setTradeData}
                                    />
                                    {showRequest ?
                                        <div className="buttons">
                                            <IconButton color="primary" title="Back"
                                                        onClick={() => setShowRequest(false)}>
                                                <BackIcon/>
                                            </IconButton>
                                            <IconButton color="secondary" title="Cancel fill-in"
                                                        onClick={() => confirm.confirm("Are you sure you want to cancel this fill-in request?", cancelTrade)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </div> : null}
                                </>}
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            {requested ?
                                <Typography>
                                    Waiting for admin to approve...
                                </Typography> :
                                <TimeOffForm
                                    user={user}
                                    users={users}
                                    timeOff={timeOffData}
                                    onChange={setTimeOffData}
                                />}
                        </TabPanel>
                    </div>
                </div>}
        </DialogContent>
        {!saving && !deleting ? <DialogActions>
            <Button color="secondary" size="small" variant="text"
                    disabled={!modifiedTrade && !modifiedAccept && !modifiedTimeOff}
                    onClick={resetForm}>
                Undo
            </Button>
            {tab === 1 ?
                <Button color="primary" size="small"
                        disabled={requested || !timeOffData.valid}
                        onClick={submitTimeOff}>
                    Save
                </Button> : objects.notNullOrUndefined(trade) && !showRequest ?
                <Button color="primary" size="small"
                        disabled={!acceptData.valid}
                        onClick={submitAccept}>
                    Save
                </Button> :
                <Button color="primary" size="small"
                        disabled={!tradeData.valid || !modifiedTrade}
                        onClick={submitTrade}>
                    Save
                </Button>}
        </DialogActions> : null}
    </Dialog>
}

export default MineShiftDialog;
