import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Typography} from "@mui/material";
import {EmojiPeople as VolunteerIcon} from "@mui/icons-material";
import {ClosableDialogTitle, UserAvatar} from "@atttomyx/react-components";
import ShiftInfo from "../../../components/shiftInfo/shiftInfo";
import * as tradeService from "../../../services/trades";
import {calculateHoursForUser} from "../../../utils/shifts";
import {datetime, users} from "@atttomyx/shared-utils";
import "./tradeShiftDialog.css";

const TradeShiftDialog = (props) => {
    const { snackbar, settings, user: me, event, shiftCache, timeOffCache, onSave, onDelete, onCancel } = props;
    const [ saving, setSaving ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ userHours, setUserHours ] = useState(0);

    const customFields = event.extendedProps;
    const user = customFields.user;
    const shift = customFields.shift;
    const trade = customFields.trade || {};
    const volunteer = customFields.wanted;
    const allowOvertime = settings.allowTradeOvertime;
    const hoursBeforeOvertime = settings.hoursBeforeOvertime;
    const maxSequentialHours = settings.maxSequentialHours;
    const wouldGoIntoOvertime = !allowOvertime && (userHours + datetime.hoursApart(shift.stop, shift.start) > hoursBeforeOvertime);

    useEffect(() => {

        if (!allowOvertime) {
            calculateHoursForUser(shiftCache, timeOffCache, me.id, shift.start, maxSequentialHours, setUserHours);
        }
    }, []);

    const submitForm = () => {
        setSaving(true);

        const success = (trade) => {
            setSaving(false);
            onSave(trade);
            snackbar.setSuccess("You volunteered");
        };

        tradeService.volunteerTrade(trade.id, success, snackbar.setError);
    };

    const deleteVolunteer = () => {
        setDeleting(true);

        const success = (trade) => {
            setDeleting(false);
            onDelete(trade);
            snackbar.setSuccess("Volunteer cancelled");
        };

        tradeService.withdrawTrade(trade.id, success, snackbar.setError);
    };

    return <Dialog
        open={true}
        aria-labelledby="shift-dialog-content"
        className="trade-shift-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Fill-in Shift
        </ClosableDialogTitle>
        <DialogContent className="shift-dialog-content">
            {saving || deleting ?
                <CircularProgress size="40px"/> :
                <div>
                    <ShiftInfo event={event}/>
                    {volunteer ?
                        <>
                            <Divider/>
                            <Typography paragraph={true}>
                                You volunteered
                            </Typography>
                            <Typography>
                                You will be notified if {users.getFullName(user)} chooses you to fill-in for them
                            </Typography>
                        </> :
                        <>
                            <UserAvatar user={user} mode="right"/>
                            {trade.reason ? <Typography component="div" className="info">
                                {trade.reason}
                            </Typography> : null}
                        </>}
                </div>}
        </DialogContent>
        {!saving && !deleting ? <DialogActions>
            {volunteer ?
                <Button color="secondary" size="small" variant="text"
                        onClick={deleteVolunteer}>
                    Withdraw
                </Button> :
                <Button color="primary" size="small"
                        disabled={wouldGoIntoOvertime}
                        onClick={submitForm}>
                    <VolunteerIcon/> Volunteer
                </Button>}
        </DialogActions> : null}
    </Dialog>
};

export default TradeShiftDialog;
