import moment from "moment";
import {datetime, formatters, objects, users} from "@atttomyx/shared-utils";
import {timezones} from "@atttomyx/react-utils";
import {themes} from "@atttomyx/shared-constants";
import {VIEW_DAY_MONTH, VIEW_DAY_WEEK} from "../constants";

export const sanitizeUser = (user) => {
    user.roles = users.normalizeRoles(user.roles);
    user.settings = sanitizeSettings(user.settings);
};

export const sanitizeProfile = (profile) => {
    profile.settings = sanitizeSettings(profile.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};

    sanitized.theme = objects.defaultIfNullOrUndefined(sanitized.theme, themes.LIGHT);
    sanitized.scheduleView = objects.defaultIfNullOrUndefined(sanitized.scheduleView, VIEW_DAY_WEEK);
    sanitized.timeOffView = objects.defaultIfNullOrUndefined(sanitized.timeOffView, VIEW_DAY_MONTH);
    sanitized.timeZone = objects.defaultIfNullOrUndefined(sanitized.timeZone, timezones.guessTimeZone());
    sanitized.acceptTrades = objects.defaultIfNullOrUndefined(sanitized.acceptTrades, true);
    sanitized.allowCalls = objects.defaultIfNullOrUndefined(sanitized.allowCalls, false);
    sanitized.allowSms = objects.defaultIfNullOrUndefined(sanitized.allowSms, false);
    sanitized.attributeIds = objects.defaultIfNullOrUndefined(sanitized.attributeIds, []);

    return sanitized;
};

export const getGoBackTo = (user) => {
    const date = datetime.today();

    return user.roles.admin
        ? datetime.beginningOfMonthsAgo(date, 3)
        : datetime.beginningOfLastMonth(date);
};

export const getDateRange = (user) => {
    const start = getGoBackTo(user);
    const stop = datetime.endOfNextMonth(datetime.today());

    return {
        start: start,
        stop: stop,
    }
};

export const formatSeniority = (user) => {
    let seniority = null;

    if (user.settings && user.settings.startDate) {
        const now = moment();
        const start = moment(user.settings.startDate);
        const duration = moment.duration(now.diff(start));

        seniority = duration.asYears().toFixed(1) + "y";
    }

    return seniority;
};

export const formatPayRate = (user) => {
    let payRate = null;

    if (user.settings && user.settings.hourlyRate) {
        payRate = formatters.usDollars(user.settings.hourlyRate) + "/h";
    }

    return payRate;
};
