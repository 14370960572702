import React from "react";
import AttributeUsersFilters from "../attributeUsersFilters/attributeUsersFilters";

const TimeOffFilters = (props) => {
    const {data, onChange, formProps} = props;

    return <div className="time-off-filters">
        <AttributeUsersFilters
            data={data}
            onChange={onChange}
            formProps={formProps}
        />
    </div>
}

export default TimeOffFilters;
