import React from "react";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";

const AccountSettingsForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.hoursBeforeOvertime && modified.hoursBeforeOvertime > 0
            && modified.maxSequentialHours && modified.maxSequentialHours > 0;

        onSync(modified);
    };

    return <div className="account-settings-fields">
        <div className="section">
            <Typography variant="h6">
                Overtime
            </Typography>
            <div className="field">
                <TextField label="Hours before overtime" required={true}
                           type="number"
                           value={forms.sanitizeValue(data.hoursBeforeOvertime)}
                           error={!data.hoursBeforeOvertime || data.hoursBeforeOvertime < 1}
                           onChange={(event) => onChange("hoursBeforeOvertime", event.target.value)}
                           inputProps={{
                               min: 1,
                           }}
                />
            </div>
            <div className="option">
                <FormControlLabel label="Allow volunteering into overtime"
                                  control={<Checkbox color="primary"
                                                     checked={forms.sanitizeOption(data.allowVolunteerOvertime)}
                                                     onChange={(event) => onChange("allowVolunteerOvertime", event.target.checked)}
                                  />}
                />
            </div>
            <div className="option">
                <FormControlLabel label="Allow trading into overtime"
                                  control={<Checkbox color="primary"
                                                     checked={forms.sanitizeOption(data.allowTradeOvertime)}
                                                     onChange={(event) => onChange("allowTradeOvertime", event.target.checked)}
                                  />}
                />
            </div>
        </div>
        <div className="section">
            <Typography variant="h6">
                Other
            </Typography>
            <div className="field">
                <TextField label="Max sequential hours" required={true}
                           type="number"
                           value={forms.sanitizeValue(data.maxSequentialHours)}
                           error={!data.maxSequentialHours || data.maxSequentialHours < 1}
                           onChange={(event) => onChange("maxSequentialHours", event.target.value)}
                           inputProps={{
                               min: 1,
                           }}
                />
            </div>
            <div className="option">
                <FormControlLabel label="Users may only see others with same qualifications"
                                  control={<Checkbox color="primary"
                                                     checked={forms.sanitizeOption(data.hideOtherUsers)}
                                                     onChange={(event) => onChange("hideOtherUsers", event.target.checked)}
                                  />}
                />
            </div>
        </div>
    </div>
}

export default AccountSettingsForm;
