import React, {Component} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {ClosableDialogTitle, Info} from "@atttomyx/react-components";
import * as accountService from "../../services/account";
import {datetime, forms, strings} from "@atttomyx/shared-utils";
import "./postScheduleDialog.css";

class PostScheduleDialog extends Component {

    constructor(props) {
        super(props);

        const until = this.props.showScheduleUntil;
        const existing = until ? datetime.previousDay(until) : null;

        this.state = {
            existing: existing,
            until: existing,
            saving: false,
        };
    }

    setUntil(event) {
        const until = event.target.value;

        this.setState({
            until: until,
        });
    }

    submitForm() {
        const until = datetime.nextDay(this.state.until);

        this.setState({
            saving: true,
        });

        accountService.postSchedule(until, this.postScheduleSuccess.bind(this), this.postScheduleFailure.bind(this));
    }

    postScheduleSuccess(account) {
        this.setState({
            until: null,
            saving: false,
        });

        this.props.onSaveMyAccount(account);
    }

    postScheduleFailure(err) {
        this.setState({
            saving: false,
        });

        this.props.onCancel();
    }

    render() {
        const existing = this.state.existing;
        const until = this.state.until;
        const saving = this.state.saving;

        return <Dialog
            open={true}
            aria-labelledby="post-schedule-dialog"
        >
            <ClosableDialogTitle onClose={this.props.onCancel}>
                Post Schedule
            </ClosableDialogTitle>
            <DialogContent className="post-schedule-dialog">
                {saving ?
                    <CircularProgress size="40px"/> :
                    <div className="field">
                        <TextField label="Until" required={true}
                                   type="date"
                                   value={forms.sanitizeValue(until)}
                                   error={datetime.isPastDate(until)}
                                   onChange={this.setUntil.bind(this)}
                        />
                        <Info>
                            Users will see their shifts once the schedule has been posted. Afterwards, they will be notified of any modifications made to posted shifts.
                        </Info>
                    </div>}
            </DialogContent>
            {!saving ? <DialogActions>
                <Button color="primary"
                        disabled={strings.isBlank(until) || datetime.isPastDate(until) || until === existing}
                        onClick={this.submitForm.bind(this)}>
                    Save
                </Button>
            </DialogActions> : null}
        </Dialog>
    }
}

export default PostScheduleDialog;
