import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Collapse, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {
    Assignment as AttendanceIcon,
    BarChart as ReportIcon,
    BeachAccess as TimeOffIcon,
    Brush as BrandingIcon,
    Business as AccountIcon,
    CheckBoxOutlined as OptionsIcon,
    EmojiPeople as VolunteerIcon,
    ExpandLess,
    ExpandMore,
    Face as ProfileIcon,
    Fingerprint as PasswordIcon,
    Info as AboutIcon,
    LabelOutlined as AttributeIcon,
    Logout as LogoutIcon,
    People as PeopleIcon,
    Settings as SettingsIcon,
    Storage as TimeSlotIcon,
    SwapHoriz as SwitchIcon,
    SwapHoriz as TradeIcon,
    Update as RecurrenceIcon
} from "@mui/icons-material";
import {AccountAvatar} from "@atttomyx/react-components";
import {sorting} from "@atttomyx/shared-utils";
import {confirm, mobile, router} from "@atttomyx/react-utils";
import {accounts as accountConstants} from "@atttomyx/shared-constants";
import {
    PAGE_ABOUT,
    PAGE_ATTENDANCE_REPORT,
    PAGE_ATTRIBUTES,
    PAGE_JOIN_ACCOUNT,
    PAGE_MY_ACCOUNT,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_RECURRENCES,
    PAGE_SETTINGS,
    PAGE_TIME_OFF,
    PAGE_TIME_SLOTS,
    PAGE_TRADE_REPORT,
    PAGE_USERS,
    PAGE_VOLUNTEER_REPORT
} from "../../constants";
import "./moreMenu.css";

const MoreMenu = (props) => {
    const {snackbar, history, accounts, account, user, onCancel, onLogout, onSwitchAccounts, authService} = props;
    const eulaRequired = !user.eula;
    const billingRequired = account.status === accountConstants.STATUS_DISABLED;
    const disabled = eulaRequired || billingRequired;

    const [showAccount, setShowAccount] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showReports, setShowReports] = useState(false);
    const [showAccounts, setShowAccounts] = useState(false);

    const closeAndRedirect = (page) => {
        onCancel();
        router.redirectTo(history, page);
    };

    const closeAndSwitchAccounts = (accountId) => {
        const switchAccountFailure = (err) => {
            snackbar.setError(err);
        }

        onCancel();
        authService.switchAccounts(accountId, onSwitchAccounts, switchAccountFailure);
    };

    const renderAccount = () => {
        return <>
            <ListItemButton disabled={disabled} onClick={() => setShowAccount(!showAccount)}>
                <ListItemIcon><AccountIcon/></ListItemIcon>
                <ListItemText>Account</ListItemText>
                {showAccount ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={showAccount} timeout="auto" unmountOnExit>
                <List disablePadding className="sub-menu">
                    <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_MY_ACCOUNT)}>
                        <ListItemIcon><BrandingIcon/></ListItemIcon>
                        <ListItemText>Branding</ListItemText>
                    </ListItemButton>
                    <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_SETTINGS)}>
                        <ListItemIcon><OptionsIcon/></ListItemIcon>
                        <ListItemText>Options</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    };

    const renderSettings = () => {
        return <>
            <ListItemButton disabled={disabled} onClick={() => setShowSettings(!showSettings)}>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <ListItemText>Settings</ListItemText>
                {showSettings ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={showSettings} timeout="auto" unmountOnExit>
                <List disablePadding className="sub-menu">
                    <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_ATTRIBUTES)}>
                        <ListItemIcon><AttributeIcon/></ListItemIcon>
                        <ListItemText>Qualifications</ListItemText>
                    </ListItemButton>
                    <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_TIME_SLOTS)}>
                        <ListItemIcon><TimeSlotIcon/></ListItemIcon>
                        <ListItemText>Time slots</ListItemText>
                    </ListItemButton>
                    <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_RECURRENCES)}>
                        <ListItemIcon><RecurrenceIcon/></ListItemIcon>
                        <ListItemText>Recurrences</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    };

    const renderReports = () => {
        return <>
            <ListItemButton disabled={disabled} onClick={() => setShowReports(!showReports)}>
                <ListItemIcon><ReportIcon/></ListItemIcon>
                <ListItemText>Reports</ListItemText>
                {showReports ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={showReports} timeout="auto" unmountOnExit>
                <List disablePadding className="sub-menu">
                    <ListItemButton onClick={() => closeAndRedirect(PAGE_ATTENDANCE_REPORT)}>
                        <ListItemIcon><AttendanceIcon/></ListItemIcon>
                        <ListItemText>Attendance</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => closeAndRedirect(PAGE_VOLUNTEER_REPORT)}>
                        <ListItemIcon><VolunteerIcon/></ListItemIcon>
                        <ListItemText>Volunteers</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={() => closeAndRedirect(PAGE_TRADE_REPORT)}>
                        <ListItemIcon><TradeIcon/></ListItemIcon>
                        <ListItemText>Trades</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    };

    const renderLogo = (account) => {
        const branding = account.branding || {};

        return <>
            <ListItemIcon>
                <AccountAvatar account={account} size="small" mode="no-title"/>
            </ListItemIcon>
            {!branding.logoContainsName ?
                <ListItemText>{account.name}</ListItemText> : null}
        </>
    };

    const renderAccounts = () => {
        return <>
            <ListItemButton onClick={() => setShowAccounts(!showAccounts)}>
                <ListItemIcon><SwitchIcon/></ListItemIcon>
                <ListItemText>Switch</ListItemText>
                {showAccounts ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={showAccounts} timeout="auto" unmountOnExit>
                <List disablePadding className="sub-menu">
                    {accounts.sort(sorting.sortByName).filter(candidate => candidate.id !== account.id).map(account =>
                        <ListItemButton
                            key={account.id}
                            onClick={() => closeAndSwitchAccounts(account.id)}
                        >
                            {renderLogo(account)}
                        </ListItemButton>)}
                    <ListItemButton onClick={() => closeAndRedirect(PAGE_JOIN_ACCOUNT)}>
                        <ListItemText>Join account...</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    };

    return <Drawer
        anchor="right"
        className="more-menu"
        open={true}
        onClose={onCancel}
    >
        <List>
            <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_USERS)}>
                <ListItemIcon><PeopleIcon/></ListItemIcon>
                <ListItemText>Users</ListItemText>
            </ListItemButton>
            {user.roles.admin ? <>
                <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_TIME_OFF)}>
                    <ListItemIcon><TimeOffIcon/></ListItemIcon>
                    <ListItemText>Time Off</ListItemText>
                </ListItemButton>
                {renderAccount()}
                {renderSettings()}
                {renderReports()}
            </> : <>
                <ListItemButton onClick={() => closeAndRedirect(PAGE_ATTENDANCE_REPORT)}>
                    <ListItemIcon><AttendanceIcon/></ListItemIcon>
                    <ListItemText>Attendance</ListItemText>
                </ListItemButton>
            </>}
            <Divider/>
            <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_PROFILE)}>
                <ListItemIcon><ProfileIcon/></ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </ListItemButton>
            <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_OPTIONS)}>
                <ListItemIcon><OptionsIcon/></ListItemIcon>
                <ListItemText>Options</ListItemText>
            </ListItemButton>
            <ListItemButton disabled={disabled} onClick={() => closeAndRedirect(PAGE_PASSWORD)}>
                <ListItemIcon><PasswordIcon/></ListItemIcon>
                <ListItemText>Password</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => confirm.confirm(mobile.isMobile()
                ? "Someone would need to have physical access to your phone to access this account. Are you sure you want to logout?"
                : "Are you sure you want to logout?", onLogout)}>
                <ListItemIcon><LogoutIcon/></ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </ListItemButton>
            {renderAccounts()}
            <Divider/>
            <ListItemButton disabled={eulaRequired} onClick={() => closeAndRedirect(PAGE_ABOUT)}>
                <ListItemIcon><AboutIcon/></ListItemIcon>
                <ListItemText>About</ListItemText>
            </ListItemButton>
        </List>
    </Drawer>
};

export default withRouter(MoreMenu);
