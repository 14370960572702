import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import AttributeUsersFilters from "../attributeUsersFilters/attributeUsersFilters";
import FromUntilFilters from "../fromUntilFilters/fromUntilFilters";
import {objects} from "@atttomyx/shared-utils";
import {TYPE_CAME_IN, TYPE_NO_SHOW} from "../../constants";

const AttendanceReportFilters = (props) => {
    const { data, onChange : onSync, formProps } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        onSync(modified);
    };

    return <div className="attendance-report-filters">
        <AttributeUsersFilters
            data={data}
            onChange={onSync}
            formProps={formProps}
        />
        <FromUntilFilters
            data={data}
            onChange={onSync}
        />
        <div className="field">
            <RadioGroup row={true}
                        onChange={(event) => onChange("type", event.target.value)}>
                <FormControlLabel
                    value={TYPE_CAME_IN}
                    label="Came in"
                    control={<Radio checked={data.type === TYPE_CAME_IN} color="primary"/>}
                />
                <FormControlLabel
                    value={TYPE_NO_SHOW}
                    label="No show"
                    control={<Radio checked={data.type === TYPE_NO_SHOW} color="primary"/>}
                />
            </RadioGroup>
        </div>
    </div>
}

export default AttendanceReportFilters;
