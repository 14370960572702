import React, {useMemo} from "react";
import {UserField} from "@atttomyx/react-components";
import AttributeField from "../../fields/attributeField/attributeField";
import {arrays, objects} from "@atttomyx/shared-utils";

const AttributeUsersFilters = (props) => {
    const {data, onChange: onSync, formProps, usersLabel} = props;
    const {attributes, users} = formProps;

    const usersByAttributeId = useMemo(() => {
        const map = {};

        users.forEach(user => {
            if (user.settings.attributeIds) {
                user.settings.attributeIds.forEach(attributeId => {
                    const array = map[attributeId] || [];

                    arrays.addTo(array, user);
                    map[attributeId] = array;
                });
            }
        });

        return map;
    }, [users]);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        if (field === "attributeId") {
            const existing = modified.userIds || [];
            const updated = [];

            if (usersByAttributeId[value]) {
                usersByAttributeId[value].forEach(user => {
                    const userId = user.id;

                    if (arrays.contains(existing, userId)) {
                        arrays.addTo(updated, userId);
                    }
                });
            }

            if (value === "") {
                modified.attributeId = null;
            }

            modified.userIds = updated;
        }

        onSync(modified);
    };

    return <div className="attribute-users-filters">
        <div className="field">
            <AttributeField
                attributes={attributes}
                value={data.attributeId}
                onChange={(value) => onChange("attributeId", value)}
            />
        </div>
        <div className="field">
            <UserField
                label={usersLabel}
                emptyLabel="All"
                multiple={true}
                users={data.attributeId ? usersByAttributeId[data.attributeId] || [] : users}
                value={data.userIds}
                onChange={(userIds) => onChange("userIds", userIds)}
            />
        </div>
    </div>
}

export default AttributeUsersFilters;
