import React from "react";
import {Dialog, DialogContent} from "@mui/material";
import {ClosableDialogTitle, UserAvatar} from "@atttomyx/react-components";
import ReportShiftsDetails from "../../components/reportShiftsDetails/reportShiftsDetails";
import "./reportShiftsDialog.css";

const ReportShiftsDialog = (props) => {
    const {user, shifts, onCancel} = props;

    return <Dialog
        open={true}
        aria-labelledby="report-shifts-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            <UserAvatar user={user} mode="right" size="medium"/>
        </ClosableDialogTitle>
        <DialogContent className="report-shifts-dialog">
            <ReportShiftsDetails
                user={user}
                shifts={shifts}
                wide={false}
            />
        </DialogContent>
    </Dialog>
}

export default ReportShiftsDialog;
