import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {AddCircle as AddIcon, RemoveCircle as RemoveIcon} from "@mui/icons-material";
import {ClosableDialogTitle, SelectField} from "@atttomyx/react-components";
import DayOfWeekField from "../../fields/dayOfWeekField/dayOfWeekField";
import ColorPicker from "../../fields/colorPicker/colorPicker";
import * as trackService from "../../services/tracks";
import {arrays, forms, objects, strings} from "@atttomyx/shared-utils";
import {
    validCompressed,
    validCompressedDayOfWeek,
    validCompressedDaysOfWeek,
    validDaysOfWeek,
    validOff,
    validOffAndOn,
    validOn,
    validOnAndOff
} from "../../utils/tracks";
import {date} from "@atttomyx/shared-constants";
import {
    COLORS_TRACKS,
    PATTERN_COMPRESSED,
    PATTERN_DAYS_OF_WEEK,
    PATTERN_OFF_AND_ON,
    PATTERN_ON_AND_OFF
} from "../../constants";
import "./trackDialog.css";

const OPTIONS = {
    [PATTERN_DAYS_OF_WEEK]: "Days of week",
    [PATTERN_ON_AND_OFF]: "Days on, days off",
    [PATTERN_OFF_AND_ON]: "Days off, days on",
    [PATTERN_COMPRESSED]: "Compressed",
};

const getPattern = (track) => {
    const daysOfWeek = track.daysOfWeek;
    const onAndOff = track.onAndOff;
    const offAndOn = track.offAndOn;
    const compressed = track.compressed;

    let pattern = null;

    if (daysOfWeek) {
        pattern = PATTERN_DAYS_OF_WEEK;

    } else if (onAndOff) {
        pattern = PATTERN_ON_AND_OFF;

    } else if (offAndOn) {
        pattern = PATTERN_OFF_AND_ON;

    } else if (compressed) {
        pattern = PATTERN_COMPRESSED;
    }

    return pattern;
};

const TrackDialog = (props) => {
    const { track, onCancel, onSaved } = props;
    const [ title, setTitle ] = useState(track.title);
    const [ color, setColor ] = useState(objects.defaultIfNullOrUndefined(track.color, COLORS_TRACKS[0]));
    const [ start, setStart ] = useState(track.start);
    const [ pattern, setPattern ] = useState(getPattern(track));
    const [ daysOfWeek, setDaysOfWeek ] = useState(track.daysOfWeek ? objects.deepCopy(track.daysOfWeek.phases) : []);
    const [ onAndOff, setOnAndOff ] = useState(track.onAndOff ? objects.deepCopy(track.onAndOff.phases) : []);
    const [ offAndOn, setOffAndOn ] = useState(track.offAndOn ? objects.deepCopy(track.offAndOn.phases) : []);
    const [ compressed, setCompressed ] = useState(track.compressed ? objects.deepCopy(track.compressed) : {});
    const [ saving, setSaving ] = useState(false);

    useEffect(() => {
        if (pattern === PATTERN_DAYS_OF_WEEK && daysOfWeek.length === 0) {
            setDaysOfWeek([{
                days: [date.MONDAY, date.TUESDAY, date.WEDNESDAY, date.THURSDAY, date.FRIDAY],
            }]);

        } else if (pattern === PATTERN_ON_AND_OFF && onAndOff.length === 0) {
            setOnAndOff([{
                on: 3,
                off: 3,
            }]);

        } else if (pattern === PATTERN_OFF_AND_ON && offAndOn.length === 0) {
            setOffAndOn([{
                off: 3,
                on: 3,
            }]);

        } else if (pattern === PATTERN_COMPRESSED && !compressed.days) {
            setCompressed({
                days: [date.MONDAY, date.TUESDAY, date.WEDNESDAY, date.THURSDAY, date.FRIDAY],
                hourOff: date.FRIDAY,
                dayOff: date.FRIDAY,
            });
        }
    }, [pattern]);

    const addPhase = () => {
        if (pattern === PATTERN_DAYS_OF_WEEK) {
            const modified = arrays.copy(daysOfWeek);

            arrays.addTo(modified, {
                days: [date.MONDAY, date.TUESDAY, date.WEDNESDAY, date.THURSDAY, date.FRIDAY],
            });

            setDaysOfWeek(modified);

        } else if (pattern === PATTERN_ON_AND_OFF) {
            const modified = arrays.copy(onAndOff);

            arrays.addTo(modified, {
                on: 3,
                off: 3,
            });

            setOnAndOff(modified);

        } else if (pattern === PATTERN_OFF_AND_ON) {
            const modified = arrays.copy(offAndOn);

            arrays.addTo(modified, {
                off: 3,
                on: 3,
            });

            setOffAndOn(modified);
        }
    };

    const removePhase = () => {
        if (pattern === PATTERN_DAYS_OF_WEEK) {
            const modified = arrays.copy(daysOfWeek);

            setDaysOfWeek(arrays.removeLast(modified));

        } else if (pattern === PATTERN_ON_AND_OFF) {
            const modified = arrays.copy(onAndOff);

            setOnAndOff(arrays.removeLast(modified));

        } else if (pattern === PATTERN_OFF_AND_ON) {
            const modified = arrays.copy(offAndOn);

            setOffAndOn(arrays.removeLast(modified));
        }
    };

    const setPhaseDays_DaysOfWeek = (id, days) => {
        const modified = arrays.assignIds(daysOfWeek);
        const phase = arrays.findEntity(modified, id);

        phase.days = days;

        setDaysOfWeek(arrays.removeIds(modified));
    };

    const setPhaseOn_OnAndOff = (id, on) => {
        const modified = arrays.assignIds(onAndOff);
        const phase = arrays.findEntity(modified, id);

        phase.on = on;

        setOnAndOff(arrays.removeIds(modified));
    };

    const setPhaseOff_OnAndOff = (id, off) => {
        const modified = arrays.assignIds(onAndOff);
        const phase = arrays.findEntity(modified, id);

        phase.off = off;

        setOnAndOff(arrays.removeIds(modified));
    };

    const setPhaseOn_OffAndOn = (id, on) => {
        const modified = arrays.assignIds(offAndOn);
        const phase = arrays.findEntity(modified, id);

        phase.on = on;

        setOffAndOn(arrays.removeIds(modified));
    };

    const setPhaseOff_OffAndOn = (id, off) => {
        const modified = arrays.assignIds(offAndOn);
        const phase = arrays.findEntity(modified, id);

        phase.off = off;

        setOffAndOn(arrays.removeIds(modified));
    };

    const setCompressedDays = (days) => {
        const modified = objects.deepCopy(compressed);

        modified.days = days;

        setCompressed(modified);
    };

    const setCompressedHourOff = (hourOff) => {
        const modified = objects.deepCopy(compressed);

        modified.hourOff = hourOff;

        setCompressed(modified);
    };

    const setCompressedDayOff = (dayOff) => {
        const modified = objects.deepCopy(compressed);

        modified.dayOff = dayOff;

        setCompressed(modified);
    };

    const submitForm = () => {
        const trackId = track.id;

        setSaving(true);

        const data = {
            title: title,
            color: color,
            start: start,
        };

        if (pattern === PATTERN_DAYS_OF_WEEK) {
            data.daysOfWeek = {
                phases: daysOfWeek,
            };

        } else if (pattern === PATTERN_ON_AND_OFF) {
            data.onAndOff = {
                phases: onAndOff,
            };

        } else if (pattern === PATTERN_OFF_AND_ON) {
            data.offAndOn = {
                phases: offAndOn,
            };

        } else if (pattern === PATTERN_COMPRESSED) {
            data.compressed = compressed;
        }

        const success = (track) => {
            setSaving(false);
            onSaved(track);
        };

        if (trackId) {
            trackService.saveTrack(trackId, data, success);

        } else {
            trackService.createTrack(data, success);
        }
    };

    let phases = [];

    if (pattern === PATTERN_DAYS_OF_WEEK) {
        phases = daysOfWeek;

    } else if (pattern === PATTERN_ON_AND_OFF) {
        phases = onAndOff;

    } else if (pattern === PATTERN_OFF_AND_ON) {
        phases = offAndOn;
    }

    return <Dialog
        open={true}
        aria-labelledby="track-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Recurrence
        </ClosableDialogTitle>
        <DialogContent className="track-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <div>
                    <div className="field">
                        <TextField label="Title" required={true}
                                   type="text"
                                   value={forms.sanitizeValue(title)}
                                   onChange={(event) => setTitle(event.target.value)}
                        />
                    </div>
                    <div className="field">
                        <ColorPicker
                            colors={COLORS_TRACKS}
                            value={color}
                            onChange={setColor}
                        />
                    </div>
                    <div className="field">
                        <TextField label="Beginning" required={true}
                                   type="date"
                                   value={forms.sanitizeValue(start)}
                                   onChange={(event) => setStart(event.target.value)}
                        />
                    </div>
                    <div className="field">
                        <SelectField
                            label="Pattern"
                            required={true}
                            value={pattern}
                            onChange={setPattern}
                            options={OPTIONS}
                        />
                    </div>
                    {pattern ? <Divider/> : null}
                    {pattern === PATTERN_DAYS_OF_WEEK || pattern === PATTERN_ON_AND_OFF || pattern === PATTERN_OFF_AND_ON ? <div className="actions">
                        <IconButton color="secondary" title="Remove phase"
                                    disabled={phases.length < 2}
                                    onClick={removePhase}>
                            <RemoveIcon/>
                        </IconButton>
                        <IconButton color="primary" title="Add phase"
                                    onClick={addPhase}>
                            <AddIcon/>
                        </IconButton>
                    </div> : null}
                    {pattern === PATTERN_DAYS_OF_WEEK ? <div>
                        {arrays.assignIds(daysOfWeek).map(phase => <div key={phase.id} className="field">
                            <DayOfWeekField label={phases.length > 1 ? "Days (phase " + phase.id + ")" : "Days"} multiple={true} required={true}
                                            value={phase.days}
                                            onChange={value => setPhaseDays_DaysOfWeek(phase.id, value)}
                            />
                        </div>)}
                    </div> : null}
                    {pattern === PATTERN_ON_AND_OFF ? <div>
                        {arrays.assignIds(onAndOff).map(phase => <div key={phase.id}>
                            <div className="field">
                                <TextField label={phases.length > 1 ? "On (phase " + phase.id + ")" : "On"} required={true}
                                           type="number"
                                           value={forms.sanitizeValue(phase.on)}
                                           error={!validOn(phase)}
                                           onChange={event => setPhaseOn_OnAndOff(phase.id, event.target.value)}
                                           inputProps={{
                                               min: 1,
                                           }}
                                />
                            </div>
                            <div className="field">
                                <TextField label={phases.length > 1 ? "Off (phase " + phase.id + ")" : "Off"} required={true}
                                           type="number"
                                           value={forms.sanitizeValue(phase.off)}
                                           error={!validOff(phase)}
                                           onChange={event => setPhaseOff_OnAndOff(phase.id, event.target.value)}
                                           inputProps={{
                                               min: 1,
                                           }}
                                />
                            </div>
                        </div>)}
                    </div> : null}
                    {pattern === PATTERN_OFF_AND_ON ? <div>
                        {arrays.assignIds(offAndOn).map(phase => <div key={phase.id}>
                            <div className="field">
                                <TextField label={phases.length > 1 ? "Off (phase " + phase.id + ")" : "Off"} required={true}
                                           type="number"
                                           value={forms.sanitizeValue(phase.off)}
                                           error={!validOff(phase)}
                                           onChange={event => setPhaseOff_OffAndOn(phase.id, event.target.value)}
                                           inputProps={{
                                               min: 1,
                                           }}
                                />
                            </div>
                            <div className="field">
                                <TextField label={phases.length > 1 ? "On (phase " + phase.id + ")" : "On"} required={true}
                                           type="number"
                                           value={forms.sanitizeValue(phase.on)}
                                           error={!validOn(phase)}
                                           onChange={event => setPhaseOn_OffAndOn(phase.id, event.target.value)}
                                           inputProps={{
                                               min: 1,
                                           }}
                                />
                            </div>
                        </div>)}
                    </div> : null}
                    {pattern === PATTERN_COMPRESSED ? <div>
                        <div className="field">
                            <DayOfWeekField label="Days (all phases)" multiple={true} required={true}
                                            value={compressed.days}
                                            error={!validCompressedDaysOfWeek(compressed.days)}
                                            onChange={setCompressedDays}
                            />
                        </div>
                        <div className="field">
                            <DayOfWeekField label="Hour Off (phase 1)" required={true}
                                            value={compressed.hourOff}
                                            error={!validCompressedDayOfWeek(compressed.days, compressed.hourOff)}
                                            onChange={setCompressedHourOff}
                            />
                        </div>
                        <div className="field">
                            <DayOfWeekField label="Day Off (phase 2)" required={true}
                                            value={compressed.dayOff}
                                            error={!validCompressedDayOfWeek(compressed.days, compressed.dayOff)}
                                            onChange={setCompressedDayOff}
                            />
                        </div>
                    </div> : null}
                    {pattern ? <Typography component="div" className="info">
                        And then repeat
                    </Typography> : null}
                </div>}
        </DialogContent>
        <DialogActions>
            <Button color="primary"
                    disabled={strings.isBlank(title) || !start || !pattern
                            || (pattern === PATTERN_DAYS_OF_WEEK && !validDaysOfWeek(daysOfWeek))
                            || (pattern === PATTERN_ON_AND_OFF && !arrays.allValid(onAndOff, validOnAndOff))
                            || (pattern === PATTERN_OFF_AND_ON && !arrays.allValid(offAndOn, validOffAndOn))
                            || (pattern === PATTERN_COMPRESSED && !validCompressed(compressed))}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions>
    </Dialog>
};

export default TrackDialog;
