import React, {forwardRef} from "react";

const STYLE = {
    margin: "20px",
};

const Printable = forwardRef((props, ref) => {
    const { children } = props;

    return <div ref={ref} style={STYLE}>
        {children}
    </div>
});

export default Printable;
