import React from "react";
import {Dialog, DialogContent, Typography} from "@mui/material";
import {ClosableDialogTitle, UserAvatar} from "@atttomyx/react-components";
import ReportAttendanceDetails from "../../components/reportAttendanceDetails/reportAttendanceDetails";
import {TYPE_NO_SHOW} from "../../constants";
import "./reportAttendanceDialog.css";

const ReportAttendanceDialog = (props) => {
    const {user, dates, type, onCancel} = props;

    return <Dialog
        open={true}
        aria-labelledby="report-attendance-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            <UserAvatar user={user} mode="right" size="medium"/>
        </ClosableDialogTitle>
        <DialogContent className="report-attendance-dialog">
            <Typography variant="h5" className="title">
                {type === TYPE_NO_SHOW ? "No Show" : "Came in"}
            </Typography>
            <ReportAttendanceDetails
                dates={dates}
            />
        </DialogContent>
    </Dialog>
}

export default ReportAttendanceDialog;
