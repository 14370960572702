import React, {useState} from 'react';
import {Button, CircularProgress, Divider, List, ListItem, Typography} from "@mui/material";
import * as profileService from "../../services/profile";
import {openAcceptableUsePolicy, openPrivacyPolicy, openTermsOfService} from "../../utils/windowUtils";
import {APP_NAME} from "../../constants";
import "./eulaPage.css";

const EulaPage = (props) => {
    const { snackbar, onAgree } = props;
    const [ saving, setSaving ] = useState(false);

    const agree = () => {
        setSaving(true);

        const success = (profile) => {
            setSaving(false);

            snackbar.setSuccess(`Welcome to ${APP_NAME}!`);
            onAgree(profile);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        profileService.agreeToEula(success, failure);
    }

    return <div className="eula">
        <Typography variant="h4" paragraph={true}>
            End-User License Agreement
        </Typography>
        <Typography paragraph={true}>
            This End-User License Agreement (“Agreement”) is entered into by and between MILESOFT, a Mile Software, LLC app development corporation (“Licensor”), and the end user (“Licensee”).
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Grant of License:
        </Typography>
        <Typography paragraph={true}>
            Licensor hereby grants Licensee a non-exclusive, revocable, and limited license to use the MILESOFT SCHEDULE APP software (the “Software”) in accordance with the terms and conditions of the Agreement.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            License Restrictions on Use:
        </Typography>
        <Typography>
            Licensee shall not:
        </Typography>
        <List dense={true}>
            <ListItem>
                Modify, adapt, or create derivative works of the Software.
            </ListItem>
            <ListItem>
                Reverse engineer, decompile, or disassemble the Software.
            </ListItem>
            <ListItem>
                Use the Software for any unlawful purpose or in violation of any applicable laws.
            </ListItem>
        </List>
        <Typography variant="h6" paragraph={true}>
            Intellectual Property Rights:
        </Typography>
        <Typography paragraph={true}>
            Licensor retains all intellectual property rights to the Software.
        </Typography>
        <Typography paragraph={true}>
            Licensee acknowledges that it does not acquire any ownership rights in the Software.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Payment:
        </Typography>
        <Typography paragraph={true}>
            Licensee agrees to pay any applicable fees for the use of the Software as specified by the Licensor.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Term and Termination:
        </Typography>
        <Typography paragraph={true}>
            This license is effective upon acceptance of this agreement and remains effective until terminated by you or licensor. Your rights under this EULA will automatically terminate if Licensee breaches any terms of the Agreement.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Updates and Support:
        </Typography>
        <Typography paragraph={true}>
            Licensor may provide updates to the Software, and Licensee may be required to install such updates for continued use.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Limitation of Liability:
        </Typography>
        <Typography paragraph={true}>
            To the extent permitted by law, Licensor shall not be liable for any indirect, incidental, or consequential damages arising out of the use or inability to use the Software. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAMGES WHATSOEVER, INLCUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERICAL DAMAGAGES.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Warranty Disclaimer:
        </Typography>
        <Typography paragraph={true}>
            The Software is provided “as is” without any warranties, either express or implied, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLIATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICESOR HERBRY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABLITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, AND OF NONINFRIDGEMENT OF THIRD-PARTY RIGHTS. No oral or written information or advice given by licensor, or its authorized representative shall create a warranty, should the licensed application of services prove defective, you assume the entire cost of all necessary servicing, repair or correction, some jurisdictions do not allow the exclusion of implied warranties or limitations on applicable statutory rights of a consumer, so the above exclusion and limitations may not apply to you.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            User Responsibilities:
        </Typography>
        <Typography paragraph={true}>
            Licensee is responsible for compliance with all applicable laws and regulations related to the use of the Software.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Terms of Service:
        </Typography>
        <Typography paragraph={true}>
            See the <a onClick={openTermsOfService}>User Terms of Service</a>.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Acceptable Use:
        </Typography>
        <Typography paragraph={true}>
            See the <a onClick={openAcceptableUsePolicy}>Acceptable Use Policy</a>.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Privacy and Data Collection:
        </Typography>
        <Typography paragraph={true}>
            See the <a onClick={openPrivacyPolicy}>Privacy Policy</a>.
        </Typography>
        <Typography variant="h6" paragraph={true}>
            Miscellaneous:
        </Typography>
        <Typography paragraph={true}>
            This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements or understandings, oral or written.
        </Typography>
        <div className="action">
            {saving ?
                <CircularProgress size="40px" color="primary" /> :
                <>
                    <Divider/>
                    <Typography>
                        I have read and agree to all of the above.
                    </Typography>
                    <Button color="primary" size="large" className="agree"
                            onClick={agree}>
                        Agree
                    </Button>
                </>}
        </div>
    </div>
}

export default EulaPage;
