import {notifications} from "@atttomyx/shared-constants";

export const APP_NAME = "Schedule";
export const APP_TAG_LINE = "Shift management made easy";
export const APP_ID_APPLE = "1528232000";
export const APP_ID_GOOGLE = "io.milesoft.schedule";

// export const API_BASE_URL = "http://localhost:8080";
export const API_BASE_URL = "https://schedule-app-4jhtyzlzvq-uc.a.run.app";
export const WEB_BASE_URL = "https://schedule.milesoft.app";

export const PATH_DEEP_LINK = "/deep-link";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA9Dj9SL_KXAQw4DfB-66XmJvyAUytOMdo",
    authDomain: "cf-notifier.firebaseapp.com",
    projectId: "cf-notifier",
    storageBucket: "cf-notifier.appspot.com",
    messagingSenderId: "741330969895",
    appId: "1:741330969895:web:795f316c4aca845967f502",
    measurementId: "G-7YK8FMDCTM"
};

export const NAMESPACE = "schedule";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_PROXY_TOKEN = "proxy_token";
export const KEY_TEMPORARY_PASSWORD = "temp_code";

export const COLORS_TRACKS = [
    "#00008B", "#0000CD", "#0000FF",
    "#008080", "#008000", "#228B22",
    "#663399", "#800080", "#880088",
    "#B22222", "#DC143C", "#FF0000",
    "#FF6600", "#FF8C00", "#FFA500",
];

export const COLORS_ATTRIBUTES = [
    "#ADD8E6", "#00BFFF", "#00FFFF",
    "#ADFF2F", "#00FF00", "#00FF7F",
    "#EE82EE", "#FF00FF", "#DA70D6",
    "#FFB6C1", "#FF69B4", "#F08080",
    "#FFFF00", "#FF0700", "#FAFAD2",
];

export const NA = "n/a";

export const ADD_NEW = "Add new...";

export const TYPE_CAME_IN = "cameIn";
export const TYPE_NO_SHOW = "noShow";

export const ADD_ANOTHER_COPY = "addCopy";
export const ADD_ANOTHER_BLANK = "addBlank";

export const RECURRENCE_INSTANCE = "instance";
export const RECURRENCE_FUTURE = "future";

export const PATTERN_DAYS_OF_WEEK = "DaysOfWeek";
export const PATTERN_ON_AND_OFF = "OnAndOff";
export const PATTERN_OFF_AND_ON = "OffAndOn";
export const PATTERN_COMPRESSED = "Compressed";

export const VIEW_TIME_DAY = "timeGridDay";
export const VIEW_TIME_WEEK = "timeGridWeek";
export const VIEW_DAY_WEEK = "dayGridWeek";
export const VIEW_DAY_MONTH = "dayGridMonth";

export const PENDING = "pending";
export const APPROVED = "approved";
export const DENIED = "denied";

export const INTRO_ACCOUNT = "account";
export const INTRO_PROFILE = "profile";
export const INTRO_ADD_USERS = "users";
export const INTRO_POST_SCHEDULE = "post_schedule";

export const OBSERVER_APP = "app";

export const ATTENDANCE_CAME_IN = "attendance_cameIn";
export const ATTENDANCE_NO_SHOW = "attendance_noShow";

export const CSV_FILENAME_CAME_IN = ATTENDANCE_CAME_IN + ".csv";
export const CSV_FILENAME_NO_SHOW = ATTENDANCE_NO_SHOW + ".csv";
export const CSV_FILENAME_VOLUNTEER = "volunteers.csv";
export const CSV_FILENAME_TRADE = "trades.csv";

export const CSV_DELIMITER = ",";

export const CSV_HEADERS_CAME_IN = [
    "Name",
    "Came In - Day of Week",
    "Came In - Date",
];

export const CSV_HEADERS_NO_SHOW = [
    "Name",
    "No Show - Day of Week",
    "No Show - Date",
];

export const CSV_HEADERS_VOLUNTEER = [
    "Name",
    "Got it",
    "Start - Day of Week",
    "Start - Date/Time",
    "Stop - Day of Week",
    "Stop - Date/Time",
];

export const CSV_HEADERS_TRADE = [
    "Name",
    "Filled in",
    "Start - Day of Week",
    "Start - Date/Time",
    "Stop - Day of Week",
    "Stop - Date/Time",
];

export const NOTIFICATION_TYPES = {
    account_joined: {
        admin: true,
        short: "New User",
        long: "A new user joins the account",
        delivery: notifications.DELIVERY_APP,
    },
    time_off_request: {
        admin: true,
        short: "Time Off Request",
        long: "Someone requests time off",
        delivery: notifications.DELIVERY_APP,
    },
    time_off_cancelled: {
        admin: true,
        short: "Time Off Cancelled",
        long: "Someone cancels time off",
        delivery: notifications.DELIVERY_APP,
    },
    time_off_approved: {
        admin: false,
        short: "Time Off Approved",
        long: "Your time off is approved",
        delivery: notifications.DELIVERY_APP,
    },
    time_off_denied: {
        admin: false,
        short: "Time Off Denied",
        long: "Your time off is denied",
        delivery: notifications.DELIVERY_APP,
    },
    trade_request: {
        admin: false,
        short: "Fill-in Request",
        long: "Someone sends you a fill-in request",
        delivery: notifications.DELIVERY_APP,
    },
    trade_cancelled: {
        admin: false,
        short: "Fill-in Cancelled",
        long: "Someone cancels a fill-in request",
        delivery: notifications.DELIVERY_APP,
    },
    trade_volunteer: {
        admin: false,
        short: "Fill-in Volunteer",
        long: "Someone volunteers to fill-in for you",
        delivery: notifications.DELIVERY_APP,
    },
    trade_removed: {
        admin: false,
        short: "Volunteer Cancelled",
        long: "Someone withdraws after volunteering",
        delivery: notifications.DELIVERY_APP,
    },
    trade_approved: {
        admin: false,
        short: "Fill-in Approved",
        long: "Someone picks you to fill-in for them",
        delivery: notifications.DELIVERY_APP,
    },
    shift_traded: {
        admin: true,
        short: "Shift Traded",
        long: "A shift is traded",
        delivery: notifications.DELIVERY_APP,
    },
    shift_available: {
        short: "Open Shift",
        long: "A shift is open",
        delivery: notifications.DELIVERY_APP,
    },
    shift_added: {
        short: "Assigned to Shift",
        long: "An admin assigns you to a shift",
        delivery: notifications.DELIVERY_APP,
    },
    shift_removed: {
        short: "Removed from Shift",
        long: "An admin removes you from a shift",
        delivery: notifications.DELIVERY_APP,
    },
    volunteer_added: {
        admin: true,
        short: "Shift Volunteer",
        long: "Someone volunteers for an open shift",
        delivery: notifications.DELIVERY_APP,
    },
    volunteer_removed: {
        admin: true,
        short: "Volunteer Cancelled",
        long: "Someone withdraws after volunteering",
        delivery: notifications.DELIVERY_APP,
    },
    schedule_posted: {
        short: "Schedule Posted",
        long: "An admin posts the schedule",
        delivery: notifications.DELIVERY_APP,
    },
};

export const TOPICS = {};

export const PAGE_LOGIN = "/login";
export const PAGE_RECOVERY = "/recovery";
export const PAGE_WELCOME = "/welcome";
export const PAGE_NEW_USER = "/newUser";
export const PAGE_STEALTH = "/stealth";

export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_PASSWORD = "/password";
export const PAGE_JOIN_ACCOUNT = "/joinAccount";

export const PAGE_INTRO = "/intro";
export const PAGE_ABOUT = "/about";

export const PAGE_DISABLED = "/disabled";
export const PAGE_NOTIFICATIONS = "/notifications";

export const PAGE_SCHEDULE = "/schedule";
export const PAGE_SHIFT = "/shift";

export const PAGE_TIME_OFF = "/timeOff";
export const PAGE_TIME_OFF_REQUEST = "/requestTimeOff";

export const PAGE_ATTENDANCE = "/attendance";
export const PAGE_ATTENDANCE_REPORT = "/attendanceReport";
export const PAGE_VOLUNTEER_REPORT = "/volunteerReport";
export const PAGE_TRADE_REPORT = "/tradeReport";

export const PAGE_MY_ACCOUNT = "/myAccount";
export const PAGE_SETTINGS = "/settings";

export const PAGE_USERS = "/users";
export const PAGE_USER = "/user";

export const PAGE_ATTRIBUTES = "/attributes";
export const PAGE_TIME_SLOTS = "/timeSlots";
export const PAGE_RECURRENCES = "/recurrences";

export const PAGE_HOME = PAGE_INTRO;

export const PAGES_MAIN = [
    PAGE_INTRO,
    PAGE_SCHEDULE,
    PAGE_ATTENDANCE,
    PAGE_NOTIFICATIONS,
];

export const PAGES_MORE = [
    PAGE_TIME_OFF,
    PAGE_TIME_OFF_REQUEST,
    PAGE_ATTENDANCE_REPORT,
    PAGE_VOLUNTEER_REPORT,
    PAGE_TRADE_REPORT,
    PAGE_MY_ACCOUNT,
    PAGE_SETTINGS,
    PAGE_USERS,
    PAGE_USER,
    PAGE_ATTRIBUTES,
    PAGE_TIME_SLOTS,
    PAGE_RECURRENCES,
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_JOIN_ACCOUNT,
    PAGE_ABOUT,
];

export const PRIVATE_PAGES_EXACT = [
    PAGE_DISABLED,
    PATH_DEEP_LINK + PAGE_NEW_USER,
    PATH_DEEP_LINK + PAGE_WELCOME,
    PATH_DEEP_LINK + PAGE_LOGIN,
    PATH_DEEP_LINK + PAGE_STEALTH,
].concat(PAGES_MAIN).concat(PAGES_MORE);

export const PRIVATE_PAGES_STARTS_WITH = [
    PAGE_USER,
    PAGE_SHIFT,
    PAGE_TIME_OFF_REQUEST,
];

export const PUBLIC_PAGES_EXACT = [
    PAGE_LOGIN,
    PAGE_RECOVERY,
    PAGE_NEW_USER,
    PATH_DEEP_LINK + PAGE_NEW_USER,
    PATH_DEEP_LINK + PAGE_WELCOME,
    PATH_DEEP_LINK + PAGE_LOGIN,
    PATH_DEEP_LINK + PAGE_STEALTH,
];

export const PUBLIC_PAGES_STARTS_WITH = [];
