import React, {Component} from 'react';
import {Divider, Typography} from "@mui/material";
import {ThumbDown as DenyIcon, ThumbUp as ApproveIcon} from "@mui/icons-material";
import {DateTimeRange, UserAvatar} from "@atttomyx/react-components";
import {strings} from "@atttomyx/shared-utils";
import "./timeOffInfo.css";

class TimeOffInfo extends Component {

    render() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const user = customFields.user;
        const admin = customFields.admin;
        const approvedOrDenied = strings.isNotBlank(timeOff.approvedBy) || strings.isNotBlank(timeOff.deniedBy);

        return <div className="time-off-info">
            <DateTimeRange start={timeOff.start} stop={timeOff.stop}/>
            <UserAvatar user={user} mode="right"/>
            {strings.isNotBlank(timeOff.reason) ?
                <Typography paragraph={approvedOrDenied}>
                    {timeOff.reason}
                </Typography> : null}
            {approvedOrDenied ?
                <>
                    <Divider variant="fullWidth"/>
                    {admin ? <UserAvatar user={admin} mode="right"/> : null}
                    {timeOff.approvedBy ? <ApproveIcon/> : <DenyIcon/>}
                    {strings.isNotBlank(timeOff.notes) ?
                        <Typography>
                            {timeOff.notes}
                        </Typography> : null}
                </> : null}
        </div>
    }
}

export default TimeOffInfo;
