import axios from 'axios';

export const listVolunteers = (cursor, limit, success, failure) => {
    findVolunteers("/api/v1/volunteer/list", cursor, limit, success, failure);
};

export const mineVolunteers = (cursor, limit, success, failure) => {
    findVolunteers("/api/v1/volunteer/mine", cursor, limit, success, failure);
};

const findVolunteers = (endpoint, cursor, limit, success, failure) => {
    let url = endpoint + "?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const createVolunteer = (shiftKey, success, failure) => {
    const url = "/api/v1/volunteer/";

    axios.post(url, {
        shiftId: shiftKey.shiftId,
        instanceId: shiftKey.instanceId,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const deleteVolunteer = (volunteerId, success, failure) => {
    const url = "/api/v1/volunteer/" + volunteerId + "/";

    axios.delete(url)
    .then(response => {
        success(volunteerId);
    })
    .catch(failure);
};

export const sendOpenShiftNotification = (notification, success, failure) => {
    const url = "/api/v1/volunteer/notify";
    const userIds = notification.userIds;

    axios.post(url, {
        shiftKey: notification.shiftKey,
        userIds: userIds,
        incentive: notification.incentive,
    })
    .then(response => {
        success(userIds);
    })
    .catch(failure);
};
