import React from 'react';
import {Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {CardData, UserAvatar} from "@atttomyx/react-components";
import {renderAttributes} from "../../utils/attributes";
import {formatPayRate, formatSeniority} from "../../utils/users";

const UserCard = (props) => {
    const { attributeIdToTitle, me, user, children } = props;

    const getRole = () => {
        return user.roles.admin ? "admin" : "user";
    }

    return <Card className="user-card" raised={true}>
        <CardContent>
            <div className="first-line">
                <UserAvatar user={user} mode="right" clickable={true}/>
                <Typography variant="caption" component="div" className="role">
                    ({getRole()})
                </Typography>
            </div>
            <Divider/>
            <div className="second-line">
                <Typography variant="body2">
                    {renderAttributes(user.settings.attributeIds, attributeIdToTitle)}
                </Typography>
            </div>
            <Divider/>
            {me.roles.admin ? <CardData
                filters={{}}
                data={user}
                keyToMetadata={{
                    seniority: {
                        title: "Seniority",
                        getter: (item) => item,
                        formatter: formatSeniority,
                    },
                    pay: {
                        title: "Pay rate",
                        getter: (item) => item,
                        formatter: formatPayRate,
                    },
                    notes: {
                        title: "Notes",
                        getter: (item) => item.settings.notes,
                    },
                }}
            /> : null}
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default UserCard;
