import React from "react";
import {TextField} from "@mui/material";
import {UserField} from "@atttomyx/react-components";
import {arrays, forms, objects} from "@atttomyx/shared-utils";
import {misc} from "@atttomyx/shared-constants";

const RequestTradeForm = (props) => {
    const { users, data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = modified.userIds.length > 0;

        onSync(modified);
    };

    return <div className="request-trade-fields">
        <div className="field">
            <UserField
                label="Send request to"
                users={users}
                value={data.userIds}
                onChange={(userIds) => onChange("userIds",
                    arrays.contains(userIds, misc.ALL) ? arrays.getIds(users) : userIds)}
                hideEmpty={true}
                options={{
                    [misc.NONE]: "No one",
                    [misc.ALL]: "Everyone",
                }}
                multiple={true}
            />
        </div>
        <div className="field">
            <TextField
                label="Reason"
                type="text"
                multiline={true}
                value={forms.sanitizeValue(data.reason)}
                onChange={(event) => onChange("reason", event.target.value)}
            />
        </div>
    </div>
};

export default RequestTradeForm;
