import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {CircularProgress, Dialog, DialogActions, DialogContent, IconButton} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import TimeOffInfo from "../../../components/timeOffInfo/timeOffInfo";
import * as timeOffService from "../../../services/timeOffs";
import {strings, users} from "@atttomyx/shared-utils";
import {confirm, router} from "@atttomyx/react-utils";
import {PAGE_TIME_OFF_REQUEST} from "../../../constants";
import "./mineTimeOffDialog.css";

class MineTimeOffDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deleting: false,
        };
    }

    deleteTimeOff() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;

        this.setState({
            deleting: true,
        });

        timeOffService.cancelTimeOff(timeOff.id, this.timeOffDeleted.bind(this));
    }

    timeOffDeleted(timeOffId) {
        this.setState({
            deleting: false,
        });

        this.props.onDelete(timeOffId);
    }

    render() {
        const history = this.props.history;
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const user = customFields.user;
        const deleting = this.state.deleting;

        return <Dialog
            open={true}
            className="mine-time-off-dialog"
        >
            <ClosableDialogTitle onClose={this.props.onCancel}
                                 subtitle={users.getFullName(user)}>
                Time Off
            </ClosableDialogTitle>
            <DialogContent className="mine-time-off-dialog">
                {deleting ?
                    <CircularProgress size="40px" color="secondary"/> :
                    <TimeOffInfo event={event}/>}
            </DialogContent>
            {!deleting ?
                <DialogActions>
                    <IconButton color="secondary" title="Delete" className="delete"
                                onClick={() => confirm.confirmDelete("this time off", this.deleteTimeOff.bind(this))}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton color="primary" title="Edit"
                                disabled={strings.isNotBlank(timeOff.approvedBy) || strings.isNotBlank(timeOff.deniedBy)}
                                onClick={() => router.redirectTo(history, `${PAGE_TIME_OFF_REQUEST}/${timeOff.id}`)}>
                        <EditIcon/>
                    </IconButton>
                </DialogActions> : null}
        </Dialog>
    }
}

export default withRouter(MineTimeOffDialog);
