import React, {useMemo} from "react";
import {SelectField} from "@atttomyx/react-components";
import {ADD_NEW} from "../../constants";

const TrackField = (props) => {
    const {tracks, value, onChange, showAddNew, required, disabled, error} = props;

    const options = useMemo(() => {
        const map = {};

        tracks.forEach(track => map[track.id] = track.title);

        if (showAddNew) {
            map[ADD_NEW] = ADD_NEW;
        }

        return map;
    }, [tracks, showAddNew]);

    return <div className="recurrence-field">
        <SelectField
            label="Recurrence"
            required={required}
            disabled={disabled}
            value={value}
            error={error}
            onChange={onChange}
            options={options}
        />
    </div>
}

export default TrackField;
