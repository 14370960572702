import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import {createPlugin} from "@fullcalendar/core";
import {Avatar, Button, Chip, CircularProgress, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {DndSource, DndSourceBucket, DndTargetBucket} from "@atttomyx/react-dnd";
import {useFiltered} from "@atttomyx/shared-hooks";
import {FiltersAccordion} from "@atttomyx/react-components";
import AttendanceFilters from "../../filters/attendanceFilters/attendanceFilters";
import * as attendanceService from "../../services/attendances";
import {arrays, cloudinary, datetime, objects, sorting, users as userUtils} from "@atttomyx/shared-utils";
import {getGoBackTo} from "../../utils/users";
import {filterByDate, fromCompositeId, isVisible, toCompositeId} from "../../utils/shifts";
import {getContentHeight, shiftsToEvents, sortByStartAndAttribute} from "../../utils/fullCalendar";
import {fromCompositeKey, getCompositeKey, toCompositeKey} from "../../utils/attendances";
import {misc, verbiage} from "@atttomyx/shared-constants";
import {NA} from "../../constants";
import "./attendancePage.css";

const customViewPlugin = createPlugin({
    views: {
        custom: {
            content: () => <div/>,
            duration: {
                days: 1,
            }
        }
    }
});

export const DND_UNKNOWN_S = "unknown_s";   // not marked, but on schedule
export const DND_UNKNOWN = "unknown";       // not marked, not on schedule
export const DND_CAME_IN = "came_in";       // marked as "came in"
export const DND_NO_SHOW_S = "no_show_s";   // marked as "no show", but on schedule
export const DND_NO_SHOW = "no_show";       // marked as "no show", not on schedule

const AttendancePage = (props) => {
    const { snackbar, account, user, users, deletedUsers, attributes, tracks, volunteers, attendances,
        shiftCache, timeOffCache, filters, date: initialDate, setDate: setInitialDate, onSaveAttendance } = props;
    const [ date, setDate ] = useState(null);
    const [ goBackTo, setGoBackTo ] = useState(null);
    const [ goNextTo, setGoNextTo ] = useState(null);
    const [ events, setEvents ] = useState(null);
    const [ cameInKeys, setCameInKeys ] = useState([]);
    const [ noShowKeys, setNoShowKeys ] = useState([]);
    const [ keyToAttributeId, setKeyToAttributeId ] = useState({});
    const [ numByUserId, setNumByUserId ] = useState({});
    const [ originalCameInKeys, setOriginalCameInKeys ] = useState([]);
    const [ originalNoShowKeys, setOriginalNoShowKeys ] = useState([]);
    const [ decoratedUsers, setDecoratedUsers ] = useState([]);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ saving, setSaving ] = useState(false);

    const filteredUsers = useFiltered(decoratedUsers, filters, sorting.sortByLastNameAndFirstName);

    const calendar = React.createRef();
    const modified = !arrays.equalsIgnoreOrder(originalCameInKeys, cameInKeys)
        || !arrays.equalsIgnoreOrder(originalNoShowKeys, noShowKeys);

    useEffect(() => {
        const date = datetime.today();

        setGoBackTo(getGoBackTo(user));
        setGoNextTo(datetime.nextDay(date));
    }, []);

    useEffect(() => {
        if (date) {
            const start = datetime.previousDay(date);
            const stop = datetime.nextDay(date);

            if (events) {
                setEvents(null);
            }

            setInitialDate(date);
            shiftCache.load(start, stop);
            timeOffCache.load(start, stop);
        }
    }, [date]);

    useEffect(() => {
        const filteredShifts = filterByDate(shiftCache.entities, date);
        const events = shiftsToEvents(account, user, filteredShifts, timeOffCache.entities, users, deletedUsers, attributes, tracks, volunteers, [], {}, NA);
        const sorted = events.sort(sortByStartAndAttribute);

        setEvents(sorted);
    }, [shiftCache.entities, timeOffCache.entities, users, attributes, tracks, volunteers]);

    useEffect(() => {
        if (events) {
            const attributeIdsByKey = {};
            const usersById = {};
            const numByUserId = {};
            const onUserIds = [];
            const decoratedUsers = [];
            const showScheduleUntil = account.settings.showScheduleUntil;
            const decorated1 = objects.deepCopy(users);
            const decorated2 = objects.deepCopy(deletedUsers);

            const decorateUser = (user, deleted) => {
                const decorations = user.decorations || {};

                decorations.deleted = deleted;
                user.decorations = decorations;
            };

            decorated1.forEach(user => decorateUser(user, false));
            decorated2.forEach(user => decorateUser(user, true));

            decorated1.forEach(user => usersById[user.id] = user);
            decorated2.forEach(user => usersById[user.id] = user);

            events.forEach(event => {
                const customFields = event.extendedProps;
                const shift = customFields.shift;

                if (isVisible(shift, showScheduleUntil)) {
                    const shiftKey = toCompositeId(shift.key);
                    const attributeId = shift.attributeId;
                    const started = datetime.isPast(shift.start)
                    const time = datetime.getShortTime(shift.start);

                    shift.userIds.forEach(userId => {
                        const user = usersById[userId];

                        if (user) {
                            const compositeKey = toCompositeKey(userId, shiftKey);
                            const unique = objects.deepCopy(user);
                            const decorations = unique.decorations;

                            decorations.shift = true;
                            decorations.time = time;
                            decorations.started = started;
                            decorations.key = compositeKey;

                            attributeIdsByKey[compositeKey] = attributeId;
                            arrays.addTo(decoratedUsers, unique);
                        }

                        if (!arrays.contains(onUserIds, userId)) {
                            arrays.addTo(onUserIds, userId);
                        }
                    });
                }
            });

            Object.entries(usersById)
            .filter(entry => !arrays.contains(onUserIds, entry[0]))
            .forEach(([userId, user]) => {
                const decorations = user.decorations;

                decorations.shift = false;
                decorations.started = false;
                decorations.key = toCompositeKey(userId, misc.UNKNOWN);

                arrays.addTo(decoratedUsers, user);
            });

            decoratedUsers.forEach(user => {
                const userId = user.id;
                let numShifts = objects.defaultIfNullOrUndefined(numByUserId[userId], 0);

                numByUserId[userId] = ++numShifts;
            });

            const attendance = attendances.find(candidate => candidate.id === date) || {
                cameIns: [],
                noShows: [],
            };
            const currentCameInKeys = attendance.cameIns.map(getCompositeKey);
            const currentNoShowKeys = attendance.noShows.map(getCompositeKey);

            setDecoratedUsers(decoratedUsers);
            setKeyToAttributeId(attributeIdsByKey);
            setNumByUserId(numByUserId);
            setOriginalCameInKeys(currentCameInKeys);
            setOriginalNoShowKeys(currentNoShowKeys);
            setCameInKeys([].concat(currentCameInKeys));
            setNoShowKeys([].concat(currentNoShowKeys));
        }
    }, [events, attendances]);

    const handleCameIn = (user) => {
        const modified = arrays.addAll([], cameInKeys);
        const userKey = user.decorations.key;

        arrays.addTo(modified, userKey);
        setCameInKeys(modified);
        setNoShowKeys(noShowKeys.filter(candidate => candidate !== userKey));
    };

    const handleNoShow = (user) => {
        const modified = arrays.addAll([], noShowKeys);
        const userKey = user.decorations.key;

        arrays.addTo(modified, userKey);
        setCameInKeys(cameInKeys.filter(candidate => candidate !== userKey));
        setNoShowKeys(modified);
    };

    const handleDelete = (user) => {
        const userKey = user.decorations.key;

        setCameInKeys(cameInKeys.filter(candidate => candidate !== userKey));
        setNoShowKeys(noShowKeys.filter(candidate => candidate !== userKey));
    };

    const undo = () => {
        setCameInKeys([].concat(originalCameInKeys));
        setNoShowKeys([].concat(originalNoShowKeys));
    };

    const save = () => {
        const toAttendanceUser = (key) => {
            const {userId, shiftKey} = fromCompositeKey(key);

            return {
                userId: userId,
                attributeId: keyToAttributeId[key] || misc.UNKNOWN,
                shiftKey: fromCompositeId(shiftKey),
            }
        };

        const success = (attendance) => {
            onSaveAttendance(attendance);
            setOriginalCameInKeys(cameInKeys);
            setOriginalNoShowKeys(noShowKeys);
            setSaving(false);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const attendance = {
            id: date,
            cameIns: cameInKeys.map(toAttendanceUser),
            noShows: noShowKeys.map(toAttendanceUser),
        };

        setSaving(true);
        attendanceService.saveAttendance(attendance, success, failure);
    };

    const handleDateChange = (info) => {
        const next = datetime.getDate(info.view.currentStart.toISOString());

        if (!date) {
            setDate(next);

        } else if (next !== date) {
            if (modified) {
                if (window.confirm(verbiage.UNSAVED_CHANGES)) {
                    setDate(next);
                } else {
                    calendar.current.getApi().gotoDate(date);
                }
            } else {
                setDate(next);
            }
        }
    };

    const renderFullCalendar = () => {
        return <FullCalendar
            ref={calendar}
            initialView={"custom"}
            initialDate={initialDate}
            plugins={[customViewPlugin]}
            contentHeight={getContentHeight()}
            height="100%"
            events={events}
            datesSet={handleDateChange}
            validRange={{
                start: goBackTo,
                end: goNextTo,
            }}
            headerToolbar={{
                start: 'title',
                end: 'today prev,next',
            }}
        />
    };

    const renderTitle = (title) => {
        return <Typography className="title">
            {title}
        </Typography>
    };

    const renderUserChips = (userKeys, deletable, assigner) => {
        return decoratedUsers
            .filter(user => arrays.contains(userKeys, user.decorations.key))
            .map(user => <DndSource
                key={user.decorations.key}
                type={assigner(user)}
                payload={user}
            >
                <Chip
                    avatar={numByUserId[user.id] > 1
                        ? <Typography variant="caption" className="time">{user.decorations.time}</Typography>
                        : <Avatar src={cloudinary.shrink(user.imageUrl)}/>}
                    label={userUtils.getFullName(user)}
                    onDelete={deletable ? () => handleDelete(user) : undefined}
                    className={user.decorations.deleted ? "bogus" : undefined}
                />
            </DndSource>)
    };

    const renderLists = () => {
        const assignerCameIn = () => DND_CAME_IN;
        const assignerNoShow = (user) => user.decorations.shift ? DND_NO_SHOW_S : DND_NO_SHOW;

        return <Table className="lists" padding="none">
            <TableBody>
                <TableRow>
                    <TableCell className="came-in">
                        {renderTitle("Came in")}
                        <DndTargetBucket
                            accept={[DND_NO_SHOW_S, DND_UNKNOWN_S]}
                            onDrop={handleCameIn}
                            emptyMsg={cameInKeys.length === 0 ? "Drag users here" : null}
                        >
                            {cameInKeys.length > 0 ? renderUserChips(cameInKeys, true, assignerCameIn) : null}
                        </DndTargetBucket>
                    </TableCell>
                    <TableCell className="spacer"/>
                    <TableCell className="no-show">
                        {renderTitle("No show")}
                        <DndTargetBucket
                            accept={[DND_CAME_IN, DND_UNKNOWN_S, DND_UNKNOWN]}
                            onDrop={handleNoShow}
                            emptyMsg={noShowKeys.length === 0 ? "Drag users here" : null}
                        >
                            {noShowKeys.length > 0 ? renderUserChips(noShowKeys, true, assignerNoShow) : null}
                        </DndTargetBucket>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    };

    const renderUsers = () => {
        const availableUserKeys = filteredUsers.map(user => user.decorations.key);
        const filteredUserKeys = availableUserKeys.filter(userKey => !arrays.contains(cameInKeys, userKey) && !arrays.contains(noShowKeys, userKey));
        const empty = filteredUserKeys.length === 0;
        const assigner = (user) => user.decorations.shift ? DND_UNKNOWN_S : DND_UNKNOWN;

        return <div className="users">
            {renderTitle("Users")}
            <DndSourceBucket emptyMsg={empty ? "No users" : null}>
                {!empty ? renderUserChips(filteredUserKeys, false, assigner) : null}
            </DndSourceBucket>
        </div>
    };

    return <div className="attendance-page">
        {goBackTo && goNextTo
            ? <>
                {renderFullCalendar()}
                {renderLists()}
                {renderUsers()}
                <FiltersAccordion
                    filters={filters}
                    form={AttendanceFilters}
                    formProps={{
                        attributes: attributes,
                    }}
                    open={showFilters}
                    onOpen={() => setShowFilters(true)}
                    onClose={() => setShowFilters(false)}
                />
                <div className="footer">
                    {saving ?
                        <CircularProgress size="40px"/> :
                        <>
                            <Button color="secondary" variant="text"
                                    disabled={!modified}
                                    onClick={undo}>
                                Undo
                            </Button>
                            <Button color="primary" size="large"
                                    disabled={!modified}
                                    onClick={save}>
                                Save
                            </Button>
                        </>}
                </div>
                <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
              </>
            : <CircularProgress size="80px"/>}
    </div>
};

export default AttendancePage;
