import axios from 'axios';

export const listAttributes = (cursor, limit, success, failure) => {
    let url = "/api/v1/attribute/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const createAttribute = (attribute, success, failure) => {
    const url = "/api/v1/attribute/";

    axios.post(url, {
        title: attribute.title,
        color: attribute.color,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const saveAttribute = (attributeId, attribute, success, failure) => {
    const url = "/api/v1/attribute/" + attributeId + "/";

    axios.put(url, {
        title: attribute.title,
        color: attribute.color,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const deleteAttribute = (attributeId, success, failure) => {
    const url = "/api/v1/attribute/" + attributeId + "/";

    axios.delete(url)
    .then(response => {
        success(attributeId);
    })
    .catch(failure);
};
