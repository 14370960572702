import * as notificationService from "./notifications";
import {objects, strings} from "@atttomyx/shared-utils";
import {mobile, router} from "@atttomyx/react-utils";
import {OBSERVER_APP} from "../constants";
import {parseRelativeUrl} from "../utils/deepLinks";

const logFailure = (err) => console.log(err);

export const initDeepLinkObserver = (history) => {
    mobile.addDeepLinkObserver(OBSERVER_APP, (url) => {
        const parsed = parseRelativeUrl(url);

        if (parsed) {
            router.redirectTo(history, parsed);
        }
    });
};

export const initMessagingTokenObserver = (bootstrap) => {
    mobile.addMessagingTokenObserver(OBSERVER_APP, (messagingToken) => {
        if (strings.isNotBlank(messagingToken)) {
            syncMessagingToken(bootstrap, messagingToken);
        }
    });
};

const syncMessagingToken = (bootstrap, messagingToken) => {
    if (strings.differ(bootstrap.user.notifications.messagingToken, messagingToken)) {
        const modified = objects.deepCopy(bootstrap.user.notifications);

        modified.messagingToken = messagingToken;

        notificationService.savePreferences(modified, bootstrap.syncNotifications, logFailure);
    }
};
