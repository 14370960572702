import React from "react";
import {CirclePicker} from "react-color";
import {Typography} from "@mui/material";
import "./colorPicker.css";

export const ColorPicker = (props) => {
    const { colors, value, onChange} = props;

    return <div className="color-picker">
        <Typography variant="caption" component="div" className="title">
            Color *
        </Typography>
        <CirclePicker
            colors={colors}
            color={value}
            onChangeComplete={(event) => onChange(event.hex)}
        />
    </div>
};

export default ColorPicker;
