import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from "@mui/material";
import {
    AddCircle as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    EmojiPeople as VolunteerCheckedIcon,
    EmojiPeopleOutlined as VolunteerIcon,
    Notifications as NotifiableCheckedIcon,
    NotificationsOutlined as NotifiableIcon,
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";
import {ClosableDialogTitle, TabBar, TabPanel} from "@atttomyx/react-components";
import VisibilityIcon from "../../../components/visibilityIcon/visibilityIcon";
import ShiftInfo from "../../../components/shiftInfo/shiftInfo";
import UserPicker from "../../../fields/userPicker/userPicker";
import * as shiftService from "../../../services/shifts";
import * as volunteerService from "../../../services/volunteers";
import {arrays, datetime, forms, strings} from "@atttomyx/shared-utils";
import {confirm, router} from "@atttomyx/react-utils";
import {
    calculateHours,
    findAvailableUsers,
    isPartOfRecurrence,
    isVisible,
    keyEquals,
    toCompositeId
} from "../../../utils/shifts";
import {PAGE_SHIFT, RECURRENCE_FUTURE, RECURRENCE_INSTANCE} from "../../../constants";
import "./adminShiftDialog.css";

const getOpenings = (event) => {
    const customFields = event.extendedProps;
    const shift = customFields.shift;

    return shift.openings;
};

const getUserIds = (event) => {
    const customFields = event.extendedProps;
    const shift = customFields.shift;

    return arrays.copy(shift.userIds);
};

const AdminShiftDialog = (props) => {
    const { snackbar, history, event, volunteers, settings, users, deletedUsers, user, shiftCache, timeOffCache,
        onCancel, onSave, onDelete } = props;

    const [ openings, setOpenings ] = useState(getOpenings(event));
    const [ userIds, setUserIds ] = useState(getUserIds(event));
    const [ notifyIds, setNotifyIds ] = useState([]);
    const [ incentive, setIncentive ] = useState(null);
    const [ userIdToHours, setUserIdToHours ] = useState({});
    const [ loadingUserHours, setLoadingUserHours ] = useState(true);
    const [ availableUsers, setAvailableUsers ] = useState([]);
    const [ loadingAvailableUsers, setLoadingAvailableUsers ] = useState(true);
    const [ volunteerIds, setVolunteerIds ] = useState([]);
    const [ availableVolunteers, setAvailableVolunteers ] = useState([]);
    const [ filteredAvailableVolunteers, setFilteredAvailableVolunteers ] = useState([]);
    const [ loadingAvailableVolunteers, setLoadingAvailableVolunteers ] = useState(true);
    const [ deletingShift, setDeletingShift ] = useState(false);
    const [ savingShift, setSavingShift ] = useState(false);
    const [ notifyingUsers, setNotifyingUsers ] = useState(false);
    const [tab, setTab] = useState(0);

    const customFields = event.extendedProps;
    const shift = customFields.shift;
    const shiftKey = shift.key;
    const open = openings > userIds.length;
    const recurring = isPartOfRecurrence(shift);
    const modifiedShift = strings.differ(shift.openings, openings) || !arrays.equalsIgnoreOrder(userIds, shift.userIds);
    const modified = modifiedShift || strings.isNotBlank(incentive) || notifyIds.length > 0;
    const hours = datetime.hoursApart(shift.stop, shift.start);
    const visible = isVisible(shift, settings.showScheduleUntil);
    const spinner = deletingShift || savingShift || notifyingUsers;

    useEffect(() => {
        const maxSequentialHours = settings.maxSequentialHours;
        const hoursBeforeOvertime = settings.hoursBeforeOvertime;
        const allowOvertime = settings.allowVolunteerOvertime;

        calculateHours(shiftCache, timeOffCache, shift.start, maxSequentialHours, (userIdToHours) => {
            setUserIdToHours(userIdToHours);
            setLoadingUserHours(false);

            findAvailableUsers(shift, users, shiftCache, timeOffCache, true, true, hoursBeforeOvertime, maxSequentialHours, userIdToHours, (availableUsers) => {
                setAvailableUsers(availableUsers);
                setLoadingAvailableUsers(false);
            });

            if (visible) {
                findAvailableUsers(shift, users, shiftCache, timeOffCache, false, allowOvertime, hoursBeforeOvertime, maxSequentialHours, userIdToHours, (availableUsers) => {
                    const availableUserIds = arrays.getIds(availableUsers);
                    const volunteerIds = volunteers
                        .filter((volunteer) => keyEquals(volunteer.shiftKey, shift.key))
                        .map((volunteer) => volunteer.userId)
                        .filter((volunteerId) => arrays.contains(shift.userIds, volunteerId) || arrays.contains(availableUserIds, volunteerId));

                    setVolunteerIds(volunteerIds);
                    setAvailableVolunteers(availableUsers.filter((availableUser) => availableUser.id !== user.id && !arrays.contains(volunteerIds, availableUser.id)));
                    setLoadingAvailableVolunteers(false);
                });
            } else {
                setLoadingAvailableVolunteers(false);
            }
        });
    }, []);

    useEffect(() => {
        const filtered = availableVolunteers.filter((user) => !arrays.contains(userIds, user.id));
        const filteredIds = arrays.getIds(filtered);

        setFilteredAvailableVolunteers(filtered);
        setNotifyIds(notifyIds.filter(notifyId => arrays.contains(filteredIds, notifyId)));
    }, [availableVolunteers, userIds]);

    const resetForm = () => {
        setOpenings(getOpenings(event));
        setUserIds(getUserIds(event));
        setIncentive(null);
        setNotifyIds([]);
    };

    const notifyUsers = () => {
        const notification = {
            shiftKey: shiftKey,
            userIds: notifyIds,
            incentive: incentive,
        };

        setNotifyingUsers(true);

        const success = (userIds) => {
            setNotifyingUsers(false);
            snackbar.setSuccess("Sent " + strings.count("notification", userIds.length));
            onCancel();
        };

        const failure = (err) => {
            setNotifyingUsers(false);
            snackbar.setError(err);
            onCancel();
        };

        volunteerService.sendOpenShiftNotification(notification, success, failure);
    };

    const saveShift = (mode) => {
        setSavingShift(true);

        const success = (saved, mode) => {
            const reloadShifts = isPartOfRecurrence(shift) && mode === RECURRENCE_FUTURE;

            setSavingShift(false);
            onSave(saved, reloadShifts);
        };

        const failure = (err) => {
            setSavingShift(false);
            snackbar.setError(err);
            onCancel();
        };

        shiftService.assignShift(shiftKey, openings, userIds, mode,
            (shift) => success(shift, mode), failure);
    };

    const submitForm = (mode) => {
        if (modifiedShift) {
            saveShift(mode);
        }

        if (notifyIds.length > 0) {
            notifyUsers();
        }
    };

    const closeMenuAndSaveShift = (popupState, mode) => {
        popupState.close();
        submitForm(mode);
    };

    const closeMenuAndEditShift = (popupState, mode) => {
        const compositeId = toCompositeId(shiftKey);

        popupState.close();

        switch (mode) {
            case RECURRENCE_INSTANCE:
                router.redirectTo(history, `${PAGE_SHIFT}/${compositeId}/${RECURRENCE_INSTANCE}`);
                break;
            case RECURRENCE_FUTURE:
                router.redirectTo(history, `${PAGE_SHIFT}/${compositeId}/${RECURRENCE_FUTURE}`);
                break;
            default:
                throw new Error(mode);
        }
    };

    const closeMenuAndDeleteShift = (popupState, mode) => {
        popupState.close();
        deleteShift(mode);
    };

    const deleteShift = (mode) => {
        setDeletingShift(true);

        const success = (shiftKey) => {
            const reloadShifts = isPartOfRecurrence(shift) && mode === RECURRENCE_FUTURE;

            setDeletingShift(false);
            onDelete(shiftKey, reloadShifts);
        };

        const failure = (err) => {
            setDeletingShift(false);
            snackbar.setError(err);
            onCancel();
        };

        shiftService.deleteShift(shift.key, mode, success, failure);
    };

    const renderDelete = () => {
        return recurring ?
            <PopupState variant="popover" popupId="delete">
                {(popupState) => (
                    <>
                        <IconButton color="secondary" title="Delete shift" className="delete" {...bindTrigger(popupState)}>
                            <DeleteIcon/>
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => closeMenuAndDeleteShift(popupState, RECURRENCE_INSTANCE)}>This event only</MenuItem>
                            <MenuItem onClick={() => closeMenuAndDeleteShift(popupState, RECURRENCE_FUTURE)}>This and future events</MenuItem>
                        </Menu>
                    </>
                )}
            </PopupState> :
            <IconButton color="secondary" title="Delete shift" className="delete"
                        onClick={() => confirm.confirmDelete("this shift", deleteShift)}>
                <DeleteIcon/>
            </IconButton>
    };

    const renderEdit = () => {
        return recurring ?
            <PopupState variant="popover" popupId="edit">
                {(popupState) => (
                    <>
                        <IconButton color="primary" title="Edit shift" {...bindTrigger(popupState)}>
                            <EditIcon/>
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => closeMenuAndEditShift(popupState, RECURRENCE_INSTANCE)}>This event only</MenuItem>
                            <MenuItem onClick={() => closeMenuAndEditShift(popupState, RECURRENCE_FUTURE)}>This and future events</MenuItem>
                        </Menu>
                    </>
                )}
            </PopupState> :
            <IconButton color="primary" title="Edit shift"
                        onClick={() => router.redirectTo(history, `${PAGE_SHIFT}/${toCompositeId(shift.key)}`)}>
                <EditIcon/>
            </IconButton>
    };

    const renderSave = () => {
        const disabled = !modifiedShift && notifyIds.length === 0;

        return recurring ?
            <PopupState variant="popover" popupId="edit">
                {(popupState) => (
                    <>
                        <Button color="primary" disabled={disabled} {...bindTrigger(popupState)}>
                            Save
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => closeMenuAndSaveShift(popupState, RECURRENCE_INSTANCE)}>This event only</MenuItem>
                            <MenuItem onClick={() => closeMenuAndSaveShift(popupState, RECURRENCE_FUTURE)}>This and future events</MenuItem>
                        </Menu>
                    </>
                )}
            </PopupState> :
            <Button color="primary"
                    disabled={disabled}
                    onClick={() => submitForm(null)}>
                Save
            </Button>
    };

    const renderOpenings = () => {
        return <Table size="small" padding="none" className="openings">
            <TableBody>
                <TableRow>
                    <TableCell align="left" className="label">
                        Openings
                    </TableCell>
                    <TableCell align="left" className="value">
                        {openings}
                    </TableCell>
                    <TableCell align="right" className="actions">
                        <IconButton color="secondary" title="Remove opening" edge="start"
                                    disabled={openings < 2 || openings <= userIds.length}
                                    onClick={() => setOpenings(openings - 1)}
                        >
                            <RemoveIcon/>
                        </IconButton>
                        <IconButton color="primary" title="Add opening" edge="end"
                                    onClick={() => setOpenings(openings + 1)}
                        >
                            <AddIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="left" className="label">
                        Filled
                    </TableCell>
                    <TableCell align="left" className="value" colSpan={2}>
                        {userIds.length}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    };

    const renderMakeAssignments = () => {
        return <>
            <div className="info">
                {loadingAvailableUsers || loadingUserHours ?
                    <CircularProgress size="14px"/> :
                    <UserPicker
                        availableUsers={availableUsers}
                        users={users}
                        deletedUsers={deletedUsers}
                        userIds={userIds}
                        specialIds={volunteerIds}
                        userIdToHours={userIdToHours}
                        isDisabled={(checked) => openings <= userIds.length && !checked}
                        settings={settings}
                        shiftUserIds={shift.userIds}
                        shiftHours={hours}
                        onChange={setUserIds}
                        specialCheckedIcon={<VolunteerCheckedIcon/>}
                        specialUncheckedIcon={<VolunteerIcon/>}
                    />}
            </div>
        </>
    };

    const renderAskForVolunteers = () => {
        return <>
            <div className="info">
                {loadingAvailableVolunteers || loadingUserHours ?
                    <CircularProgress size="14px"/> :
                    <UserPicker
                        availableUsers={filteredAvailableVolunteers}
                        users={users}
                        deletedUsers={deletedUsers}
                        userIds={notifyIds}
                        userIdToHours={userIdToHours}
                        settings={settings}
                        shiftUserIds={shift.userIds}
                        shiftHours={hours}
                        onChange={setNotifyIds}
                        checkedIcon={<NotifiableCheckedIcon/>}
                        uncheckedIcon={<NotifiableIcon/>}
                    />}
            </div>
            <div className="field all">
                <Button color="primary" variant="text" size="small"
                        disabled={notifyIds.length === filteredAvailableVolunteers.length}
                        onClick={() => setNotifyIds(arrays.getIds(filteredAvailableVolunteers))}>
                    Select all
                </Button>
            </div>
            <div className="field">
                <TextField label="Incentive"
                           type="text"
                           multiline={true}
                           value={forms.sanitizeValue(incentive)}
                           onChange={(event) => setIncentive(event.target.value)}
                />
            </div>
        </>
    };

    return <Dialog
        open={true}
        aria-labelledby="shift-dialog-content"
        className="admin-shift-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            <VisibilityIcon visible={visible}>{open ? "Open Shift" : "Shift"}</VisibilityIcon>
        </ClosableDialogTitle>
        <DialogContent className="shift-dialog-content">
            {savingShift || notifyingUsers ?
                <CircularProgress size="40px"/> : deletingShift ?
                <CircularProgress size="40px" color="secondary"/> :
                <ShiftInfo event={event}/>}
        </DialogContent>
        {!spinner ?
            <>
                <DialogActions className="top-actions">
                    {renderDelete()}
                    {renderEdit()}
                </DialogActions>
                <DialogContent className="shift-dialog-content">
                    <Divider/>
                    {visible && open ?
                        <>
                            {renderOpenings()}
                            <TabBar
                                tabs={["Make assignments", "Ask for volunteers"]}
                                value={tab}
                                onChange={setTab}
                            />
                            <TabPanel value={tab} index={0}>
                                {renderMakeAssignments()}
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                {renderAskForVolunteers()}
                            </TabPanel>
                        </> :
                        <>
                            {renderOpenings()}
                            {renderMakeAssignments()}
                        </>}
                </DialogContent>
                <DialogActions className="middle-actions">
                    <Button color="secondary" variant="text"
                            disabled={!modified}
                            onClick={resetForm}>
                        Undo
                    </Button>
                    {renderSave()}
                </DialogActions>
            </> : null}
    </Dialog>
}

export default withRouter(AdminShiftDialog);
