export const base = (branding) => {
    return {
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    bottom: "68px !important",
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    justifyContent: "center",
                },
            },
        },
        MUIRichTextEditor: {
            styleOverrides: {
                toolbar: {
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                },
                editorContainer: {
                    margin: "0",
                    padding: "0",
                },
                editor: {
                    minHeight: "200px",
                    margin: "0",
                    padding: "20px",
                },
                placeHolder: {
                    margin: "20px",
                    padding: "0",
                    left: "-20px",
                },
            },
        },
    }
};
