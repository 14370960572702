import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {EmojiPeople as VolunteerIcon} from "@mui/icons-material";
import {UserAvatar} from "@atttomyx/react-components";
import {arrays, users as userUtils} from "@atttomyx/shared-utils";
import "./userList.css";

const hoursClassName = (hoursBeforeOvertime, hours) => {
    return hours > hoursBeforeOvertime ? "overtime" : "no-overtime";
};

class UserList extends Component {

    renderUser(user) {
        const hoursBeforeOvertime = this.props.hoursBeforeOvertime;
        const userIdToHours = this.props.userIdToHours;
        const volunteerIds = this.props.volunteerIds;
        const hours = userIdToHours ? userIdToHours[user.id] || 0 : 0;

        return <Table key={user.id} size="small" padding="none">
            <TableBody>
                <TableRow>
                    <TableCell>
                        <UserAvatar user={user} mode="no-title" size="small"/>
                    </TableCell>
                    <TableCell className="name">
                        <Typography variant="body2">
                            {userUtils.getFullName(user)}
                        </Typography>
                    </TableCell>
                    <TableCell className="hours">
                        <Typography variant="caption" className={hoursClassName(hoursBeforeOvertime, hours)}>
                            ({hours}h)
                        </Typography>
                    </TableCell>
                    {arrays.contains(volunteerIds, user.id) ?
                        <TableCell className="volunteer">
                            <VolunteerIcon/>
                        </TableCell> : null}
                </TableRow>
            </TableBody>
        </Table>
    }

    render() {
        const users = this.props.users;

        return <div className="user-list">
            {users.length === 0 ?
                <Typography component="div" className="no-one">
                    No users
                </Typography> : users.map(user => this.renderUser(user))}
        </div>
    }
}

export default UserList;
