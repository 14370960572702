import React, {Component} from "react";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {Tooltip} from "@atttomyx/react-components";
import AttributeDialog from "../../dialogs/attributeDialog/attributeDialog";
import TrackDialog from "../../dialogs/trackDialog/trackDialog";
import TimeSlotDialog from "../../dialogs/timeSlotDialog/timeSlotDialog";
import TimeSlotField from "../../fields/timeSlotField/timeSlotField";
import AttributeField from "../../fields/attributeField/attributeField";
import TrackField from "../../fields/trackField/trackField";
import {arrays, datetime, forms, objects, strings} from "@atttomyx/shared-utils";
import {renderPattern, validCompressed, validDaysOfWeek, validOffAndOn, validOnAndOff} from "../../utils/tracks";
import {
    ADD_NEW,
    PATTERN_COMPRESSED,
    PATTERN_DAYS_OF_WEEK,
    PATTERN_OFF_AND_ON,
    PATTERN_ON_AND_OFF
} from "../../constants";
import "./shiftForm.css";

class ShiftForm extends Component {

    constructor(props) {
        super(props);

        const requireRecurrence = this.props.requireRecurrence;
        const data = this.props.data;

        this.state = {
            attribute: null,
            timeSlot: null,
            track: null,
            showTrack: requireRecurrence || data.trackId,
        };
    }

    fieldChanged(field, value) {
        this.fieldsChanged([{
            field: field,
            value: value,
        }]);
    }

    fieldsChanged(pairs) {
        const data = this.props.data;
        const modified = objects.deepCopy(data);

        pairs.forEach((pair) => {
            modified[pair.field] = pair.value;
        });

        const startDate = modified.startDate;
        const startTime = modified.startTime;
        const stopDate = modified.stopDate;
        const stopTime = modified.stopTime;

        if (startTime && stopTime) {
            const allowRecurrence = this.props.allowRecurrence;

            modified.start = startDate + "T" + startTime;

            if (allowRecurrence && modified.pattern) {
                modified.stop = stopDate
                    ? (startTime > stopTime ? datetime.nextDay(stopDate) : stopDate) + "T" + stopTime
                    : datetime.oneHundredYearsFrom(startDate) + "T" + stopTime;

            } else {
                modified.stop = (startTime > stopTime ? datetime.nextDay(startDate) : startDate) + "T" + stopTime;
            }
        }

        const validTrackId = (!this.props.requireRecurrence && !this.state.showTrack) || strings.isNotBlank(modified.trackId);

        const validTrack = !modified.pattern
            || (modified.pattern === PATTERN_DAYS_OF_WEEK && validDaysOfWeek(modified.daysOfWeek))
            || (modified.pattern === PATTERN_ON_AND_OFF && arrays.allValid(modified.onAndOff, validOnAndOff))
            || (modified.pattern === PATTERN_OFF_AND_ON && arrays.allValid(modified.offAndOn, validOffAndOn))
            || (modified.pattern === PATTERN_COMPRESSED && validCompressed(modified.compressed));

        const allowPastDate = this.props.allowPastDate;
        const validDates = allowPastDate || (!datetime.isPastDate(modified.startDate) && (!stopDate || !datetime.isPastDate(modified.stopDate)));

        modified.valid = modified.attributeId && modified.openings
            && validDates && modified.startDate
            && modified.start && modified.stop && modified.stop >= modified.start
            && validTrackId && validTrack;

        this.props.onChange(modified);
    }

    attributeIdChanged(attributeId) {
        if (attributeId === ADD_NEW) {
            this.setState({
                attribute: {},
            });

        } else {
            this.setAttributeId(attributeId);
        }
    }

    setAttributeId(attributeId) {
        this.fieldChanged("attributeId", attributeId);
    }

    setOpenings(event) {
        const openings = event.target.value;

        this.fieldChanged("openings", openings);
    }

    setDescription(event) {
        const description = event.target.value;

        this.fieldChanged("description", description);
    }

    timeSlotIdChanged(timeSlotId) {
        if (timeSlotId === ADD_NEW) {
            this.setState({
                timeSlot: {
                    start: "09:00",
                    stop: "17:00",
                },
            });

        } else {
            this.setTimeSlotId(timeSlotId);
        }
    }

    setTimeSlotId(timeSlotId) {
        const timeSlots = this.props.timeSlots;
        const timeSlot = arrays.findEntity(timeSlots, timeSlotId);

        if (timeSlot) {
            const startTime = timeSlot.start;
            const stopTime = timeSlot.stop;

            this.fieldsChanged([{
                field: "timeSlotId",
                value: timeSlotId,
            }, {
                field: "startTime",
                value: startTime,
            }, {
                field: "stopTime",
                value: stopTime,
            }]);
        } else {
            this.fieldChanged("timeSlotId", timeSlotId);
        }
    }

    setStartDate(event) {
        const startDate = event.target.value;

        this.fieldChanged("startDate", startDate);
    }

    setStopDate(event) {
        const stopDate = event.target.value;

        this.fieldChanged("stopDate", stopDate);
    }

    trackIdChanged(trackId) {
        if (trackId === ADD_NEW) {
            const data = this.props.data;

            this.setState({
                track: {
                    start: data.startDate,
                },
            });

        } else {
            this.setTrackId(trackId);
        }
    }

    setTrackId(trackId) {
        const tracks = this.props.tracks;
        const track = arrays.findEntity(tracks, trackId);

        if (track) {
            const daysOfWeek = track.daysOfWeek;
            const onAndOff = track.onAndOff;
            const offAndOn = track.offAndOn;
            const compressed = track.compressed;

            if (daysOfWeek) {
                this.fieldsChanged([{
                    field: "trackId",
                    value: trackId,
                }, {
                    field: "pattern",
                    value: PATTERN_DAYS_OF_WEEK,
                }, {
                    field: "beginning",
                    value: track.start,
                }, {
                    field: "daysOfWeek",
                    value: objects.deepCopy(daysOfWeek.phases),
                }]);

            } else if (onAndOff) {
                this.fieldsChanged([{
                    field: "trackId",
                    value: trackId,
                }, {
                    field: "pattern",
                    value: PATTERN_ON_AND_OFF,
                }, {
                    field: "beginning",
                    value: track.start,
                }, {
                    field: "onAndOff",
                    value: objects.deepCopy(onAndOff.phases),
                }]);

            } else if (offAndOn) {
                this.fieldsChanged([{
                    field: "trackId",
                    value: trackId,
                }, {
                    field: "pattern",
                    value: PATTERN_OFF_AND_ON,
                }, {
                    field: "beginning",
                    value: track.start,
                }, {
                    field: "offAndOn",
                    value: objects.deepCopy(offAndOn.phases),
                }]);

            } else if (compressed) {
                this.fieldsChanged([{
                    field: "trackId",
                    value: trackId,
                }, {
                    field: "pattern",
                    value: PATTERN_COMPRESSED,
                }, {
                    field: "beginning",
                    value: track.start,
                }, {
                    field: "compressed",
                    value: objects.deepCopy(compressed),
                }]);

            } else {
                this.fieldChanged("trackId", trackId);
            }

        } else {
            this.fieldsChanged([{
                field: "trackId",
                value: trackId,
            }, {
                field: "pattern",
                value: null,
            }]);
        }
    }

    setShowTrack(event) {
        const showTrack = event.target.checked;

        this.setState({
            showTrack: showTrack,
        }, () => this.fieldChanged("trackId", null));
    }

    hideAttribute() {
        this.setState({
            attribute: null,
        });
    }

    attributeSaved(attribute) {
        this.props.onSaveAttribute(attribute);

        this.setState({
            attribute: null,
        }, () => this.setAttributeId(attribute.id));
    }

    hideTimeSlot() {
        this.setState({
            timeSlot: null,
        });
    }

    timeSlotSaved(timeSlot) {
        this.props.onSaveTimeSlot(timeSlot);

        this.setState({
            timeSlot: null,
        }, () => this.setTimeSlotId(timeSlot.id));
    }

    hideTrack() {
        this.setState({
            track: null,
        });
    }

    trackSaved(track) {
        this.props.onSaveTrack(track);

        this.setState({
            track: null,
        }, () => this.setTrackId(track.id));
    }

    renderTrackDescription(tracks, trackId) {
        let rendered = null;

        if (trackId) {
            const track = arrays.findEntity(tracks, trackId);
            const pattern = renderPattern(track);
            let p = 0;

            rendered = <div className="track-description">
                {pattern.map(phase => <Typography variant="caption" component="p" key={"phase_" + p++}>
                    {phase}
                </Typography>)}
            </div>;
        }

        return rendered;
    }

    render() {
        const data = this.props.data;
        const allowRecurrence = this.props.allowRecurrence;
        const requireRecurrence = this.props.requireRecurrence;
        const allowPastDate = this.props.allowPastDate;
        const lockStartDate = this.props.lockStartDate;
        const attributes = this.props.attributes;
        const timeSlots = this.props.timeSlots;
        const tracks = this.props.tracks;
        const attribute = this.state.attribute;
        const timeSlot = this.state.timeSlot;
        const track = this.state.track;
        const showTrack = this.state.showTrack;

        const invalidDate = (date) => !allowPastDate && datetime.isPastDate(date);

        return <div className="shift-form">
            <div className="field">
                <AttributeField
                    attributes={attributes}
                    value={data.attributeId}
                    onChange={this.attributeIdChanged.bind(this)}
                    required={true}
                    showAddNew={true}
                />
            </div>
            <div className="field">
                <TextField label="Openings" required={true}
                           type="number"
                           value={forms.sanitizeValue(data.openings)}
                           error={!data.openings || data.openings < 1}
                           onChange={this.setOpenings.bind(this)}
                           inputProps={{
                               min: 1,
                           }}
                />
            </div>
            <div className="field">
                <TextField label="Description"
                           type="text"
                           multiline={true}
                           value={forms.sanitizeValue(data.description)}
                           onChange={this.setDescription.bind(this)}
                />
            </div>
            <div className="field">
                <TimeSlotField
                    timeSlots={timeSlots}
                    value={data.timeSlotId}
                    onChange={this.timeSlotIdChanged.bind(this)}
                    showAddNew={true}
                    required={true}
                />
            </div>
            {showTrack ? null :
                <div className="field">
                    <TextField label="Date" required={true}
                               type="date"
                               disabled={lockStartDate}
                               value={forms.sanitizeValue(data.startDate)}
                               error={invalidDate(data.startDate)}
                               onChange={this.setStartDate.bind(this)}
                    />
                </div>}
            {allowRecurrence ?
                <>
                    <div className="option">
                        <FormControlLabel labelPlacement="end"
                                          label="Repeat"
                                          control={<Checkbox color="primary"
                                                             checked={forms.sanitizeOption(showTrack)}
                                                             disabled={requireRecurrence}
                                                             onChange={this.setShowTrack.bind(this)}
                                          />}
                        />
                        <Tooltip message="Using repeating shifts can save you a lot of time, because you don't have to create them one by one, and assignments may be carried forward."/>
                    </div>
                    {showTrack ? <>
                        <div className="field">
                            <TextField label="Beginning" required={true}
                                       type="date"
                                       disabled={lockStartDate}
                                       value={forms.sanitizeValue(data.startDate)}
                                       error={invalidDate(data.startDate)}
                                       onChange={this.setStartDate.bind(this)}
                            />
                        </div>
                        <div className="field">
                            <TextField label="Ending"
                                       type="date"
                                       value={forms.sanitizeValue(data.stopDate)}
                                       error={strings.isNotBlank(data.stopDate) && (invalidDate(data.stopDate) || (strings.isNotBlank(data.startDate) && data.stopDate < data.startDate))}
                                       onChange={this.setStopDate.bind(this)}
                            />
                        </div>
                        <div className="field">
                            <TrackField
                                tracks={tracks}
                                value={data.trackId}
                                onChange={this.trackIdChanged.bind(this)}
                                showAddNew={true}
                                required={true}
                            />
                            {this.renderTrackDescription(tracks, data.trackId)}
                        </div>
                    </> : null}
                </> : null}
            {attribute ? <AttributeDialog
                attribute={attribute}
                onCancel={this.hideAttribute.bind(this)}
                onSaved={this.attributeSaved.bind(this)}
            /> : null}
            {timeSlot ? <TimeSlotDialog
                timeSlot={timeSlot}
                onCancel={this.hideTimeSlot.bind(this)}
                onSaved={this.timeSlotSaved.bind(this)}
            /> : null}
            {track ? <TrackDialog
                track={track}
                onCancel={this.hideTrack.bind(this)}
                onSaved={this.trackSaved.bind(this)}
            /> : null}
        </div>
    }
}

export default ShiftForm;
