import React from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import {COLORS_ATTRIBUTES} from "../../constants";
import "./attributeCard.css";

const AttributeCard = (props) => {
    const { attribute, children } = props;

    return <Card className="attribute-card" raised={true}>
        <CardContent>
            <Typography variant="h4" paragraph={true}>
                {attribute.title}
            </Typography>
            <Typography className="color" style={{
                backgroundColor: attribute.color || COLORS_ATTRIBUTES[0],
            }}>&nbsp;</Typography>
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
};

export default AttributeCard;
