import React from 'react';
import {Typography} from "@mui/material";
import {EmojiPeople as VolunteerIcon, SwapHoriz as TradeIcon} from "@mui/icons-material";
import ShiftEventTimeRange from "../timeRange/shiftEventTimeRange";
import {arrays, datetime, strings, users} from "@atttomyx/shared-utils";
import {misc} from "@atttomyx/shared-constants";
import "./summaryShiftEvent.css";

const SummaryShiftEvent = (props) => {
    const { user: me, wide, event, filter } = props;

    const renderTrade = () => {
        const customFields = event.extendedProps;
        const trade = customFields.trade;
        const user = customFields.user;

        return <div key={trade.id} className="trade">
            <TradeIcon className="trade"/>
            {user ? <Typography variant="caption">
                {wide ? users.getFullName(user) : users.getInitials(user)}
            </Typography> : null}
        </div>
    };

    const customFields = event.extendedProps;
    const shift = customFields.shift;
    const trade = customFields.trade;
    const volunteer = customFields.volunteer;
    const user = customFields.user;
    const attribute = customFields.attribute || misc.UNKNOWN;
    const openings = shift.openings;
    const filled = shift.userIds.length;
    const need = openings - filled;
    const attributeToShow = wide ? attribute : strings.getFirstLettersCapitalized(attribute);
    const past = datetime.isPast(shift.stop);

    return <div className="summary-shift-event">
        <ShiftEventTimeRange event={event}
                             wide={wide}/>
        {filter && me.roles.admin ?
            <Typography variant="caption" component="div">
                Have: {filled}
            </Typography> : me.roles.admin ?
            <Typography variant="caption" component="div">
                {attributeToShow}, {filled}
            </Typography> :
            <Typography variant="caption" component="div">
                {attributeToShow}
            </Typography>}
        {!past && trade ? renderTrade() : null}
        {!past && me.roles.admin && need > 0 ?
            <Typography variant="caption" component="div">
                Need: {need}
            </Typography> : null}
        {!past && !me.roles.admin && !user && !trade && !arrays.contains(shift.userIds, me.id) ?
            <Typography variant="caption" component="div" className="open">
                {volunteer ? <VolunteerIcon/> : "Open"}
            </Typography> : null}
    </div>
}

export default SummaryShiftEvent;
