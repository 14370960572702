import React, {useEffect, useMemo, useRef, useState} from "react";
import moment from "moment";
import {isDesktop} from "react-device-detect";
import {useReactToPrint} from "react-to-print";
import csvDownload from "json-to-csv-export";
import {Button, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import {Print as PrintIcon} from "@mui/icons-material";
import {FiltersAccordion, Info, UserAvatar} from "@atttomyx/react-components";
import TradeReportFilters from "../../filters/tradeReportFilters/tradeReportFilters";
import ReportShiftsDetails from "../../components/reportShiftsDetails/reportShiftsDetails";
import ReportShiftsDialog from "../../dialogs/reportShiftsDialog/reportShiftsDialog";
import Printable from "../../components/printable/printable";
import {arrays, objects, sorting, users as userUtils} from "@atttomyx/shared-utils";
import {toCompositeId} from "../../utils/shifts";
import {
    ATTENDANCE_CAME_IN,
    ATTENDANCE_NO_SHOW,
    CSV_DELIMITER,
    CSV_FILENAME_TRADE,
    CSV_HEADERS_TRADE,
    TYPE_NO_SHOW
} from "../../constants";
import "./tradeReportPage.css";

const TradeReportPage = (props) => {
    const {users, deletedUsers, attributes, trades, shiftCache, filters} = props;
    const [usersById, setUsersById] = useState(null);
    const [deletedUserIds, setDeletedUserIds] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState(null);
    const [result, setResult] = useState(null);
    const data = filters.data || {};

    const ref = useRef(null);
    const printReport = useReactToPrint({
        content: () => ref.current,
        documentTitle: data.type === TYPE_NO_SHOW ? ATTENDANCE_NO_SHOW : ATTENDANCE_CAME_IN,
    });

    const filtered = useMemo(() => {
        const byCompositeId = {};

        trades.forEach(trade => {
            const compositeId = toCompositeId(trade.shiftKey);
            const existing = byCompositeId[compositeId];

            if (existing) {
                if (trade.updated > existing.updated) {
                    byCompositeId[compositeId] = trade;
                }
            } else {
                byCompositeId[compositeId] = trade;
            }
        });

        return Object.values(byCompositeId);
    }, [trades]);

    useEffect(() => {
        if (showResults) {
            const compositeToShift = {};
            const userIdToGaveAwayShifts = {};
            const userIdToFilledInShifts = {};
            const attributeId = data.attributeId;
            const userIds = data.userIds;

            shiftCache.entities.forEach(shift => {
                const composite = toCompositeId(shift.key);

                compositeToShift[composite] = shift;
            });

            filtered.filter(trade => userIds.length === 0
                || arrays.contains(userIds, trade.requestedBy) || arrays.contains(userIds, trade.tradeWith))
            .forEach(trade => {
                const composite = toCompositeId(trade.shiftKey);
                const shift = compositeToShift[composite];

                if (shift) {
                    if (!attributeId || shift.attributeId === attributeId) {
                        const array1 = userIdToGaveAwayShifts[trade.requestedBy] || [];
                        const array2 = userIdToFilledInShifts[trade.tradeWith] || [];

                        arrays.addTo(array1, shift);
                        arrays.addTo(array2, shift);

                        userIdToGaveAwayShifts[trade.requestedBy] = array1;
                        userIdToFilledInShifts[trade.tradeWith] = array2;
                    }
                }
            });

            const mostAtTop = (a, b) => {
                return sorting.sortByFieldDesc(a, b, "total");
            };
            const alphabetical = (a, b) => {
                return userUtils.sortByName(a.user, b.user);
            };
            const composite = sorting.getCompositeSorter([mostAtTop, alphabetical]);
            const userIdToResult = {};

            Object.entries(userIdToGaveAwayShifts)
            .filter(([userId, shifts]) => objects.notNullOrUndefined(usersById[userId]) && shifts.length > 0)
            .forEach(([userId, shifts]) => {
                userIdToResult[userId] = {
                    userId: userId,
                    user: usersById[userId],
                    shifts: shifts,
                    gaveAway: shifts.length,
                    filledIn: 0,
                    total: shifts.length,
                };
            });

            Object.entries(userIdToFilledInShifts)
            .filter(([userId, shifts]) => objects.notNullOrUndefined(usersById[userId]) && shifts.length > 0)
            .forEach(([userId, shifts]) => {
                const result = userIdToResult[userId] || {
                    userId: userId,
                    user: usersById[userId],
                    shifts: [],
                    gaveAway: 0,
                    filledIn: 0,
                    total: 0,
                };

                result.shifts = arrays.addAll(result.shifts, shifts);
                result.filledIn = shifts.length;
                result.total = result.total + shifts.length;

                userIdToResult[userId] = result;
            });

            const sorted = Object.values(userIdToResult).sort(composite);

            setResults(sorted);

        } else {
            setResults(null);
        }
    }, [showResults, filtered, shiftCache.entities]);

    useEffect(() => {
        if (filters.data.fromDate && filters.data.untilDate) {
            shiftCache.load(filters.data.fromDate, filters.data.untilDate);
        }
    }, [filters.data]);

    useEffect(() => {
        const idToUser = arrays.getIdToEntity(users);
        const deleted = [];

        deletedUsers.map(user => {
            const userId = user.id;

            idToUser[userId] = user;
            arrays.addTo(deleted, userId);
        });

        setUsersById(idToUser);
        setDeletedUserIds(deleted);
    }, [users, deletedUsers]);

    const downloadCsv = () => {
        const lines = [];

        results.forEach(result => {
            const user = result.user;
            const name = userUtils.getFullName(user);

            result.shifts.forEach(shift => {
                const momentStart = moment(shift.start);
                const momentStop = moment(shift.stop);

                arrays.addTo(lines, {
                    name: name,
                    filledIn: arrays.contains(shift.userIds, user.id),
                    startDayOfWeek: momentStart.format("dddd"),
                    startIso: momentStart.toISOString(),
                    stopDayOfWeek: momentStop.format("dddd"),
                    stopIso: momentStop.toISOString(),
                });
            });
        });

        csvDownload({
            data: lines,
            delimiter: CSV_DELIMITER,
            headers: CSV_HEADERS_TRADE,
            filename: CSV_FILENAME_TRADE,
        });
    };

    return <div className="trade-report-page">
        <Typography variant="h5" paragraph={true}>
            Trade Report
        </Typography>
        <FiltersAccordion
            filters={filters}
            form={TradeReportFilters}
            formProps={{
                users: users,
                attributes: attributes,
            }}
            open={!showResults}
            mayDeleteCriteria={false}
        />
        <div className="actions">
            {isDesktop ?
                <>
                    <Button color="secondary"
                            disabled={!showResults}
                            onClick={printReport}>
                        <PrintIcon/> Print
                    </Button>
                    <Button color="secondary"
                            disabled={!showResults}
                            onClick={downloadCsv}>
                        Download CSV
                    </Button>
                </> : null}
            <Button color="primary"
                    onClick={() => setShowResults(!showResults)}>
                {showResults ? "Edit Filters" : "Run Report"}
            </Button>
        </div>
        {results ? <>
            <Divider/>
            {results.length > 0 ? <>
                    <Printable ref={ref}>
                        <Typography variant="h5" className="print-only">
                            Trades
                        </Typography>
                        <Table size="small" cellPadding={0} cellSpacing={0} className="results striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>
                                        Gave&nbsp;away
                                    </TableCell>
                                    <TableCell>
                                        Filled&nbsp;in
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.map((result) =>
                                    <TableRow key={result.userId} className="result"
                                              onClick={() => setResult(result)}>
                                        <TableCell className={arrays.contains(deletedUserIds, result.userId) ? "bogus" : ""}>
                                            <UserAvatar user={result.user} mode="right" size="small"/>
                                        </TableCell>
                                        <TableCell>
                                            {result.gaveAway}
                                        </TableCell>
                                        <TableCell>
                                            {result.filledIn}
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                        <div className="print-only">
                            {results.map(result => <div key={result.userId}>
                                <Divider/>
                                <UserAvatar user={result.user} mode="right" size="small"/>
                                <ReportShiftsDetails
                                    user={result.user}
                                    shifts={result.shifts}
                                    wide={true}
                                />
                            </div>)}
                        </div>
                    </Printable>
                    <Info>
                        Click on a user to see the shifts they traded
                    </Info>
                </> :
                <Typography>
                    No results
                </Typography>}
        </> : null}
        {result ? <ReportShiftsDialog
            user={result.user}
            shifts={result.shifts}
            type={data.type}
            onCancel={() => setResult(null)}
        /> : null}
    </div>
};

export default TradeReportPage;
