import React, {Component} from 'react';
import {Typography} from "@mui/material";
import {renderTime} from "../../../utils/fullCalendar";

class ShiftEventTimeRange extends Component {

    render() {
        const event = this.props.event;
        const wide = this.props.wide;
        const renderedTime = renderTime(event, wide, "shift");
        const start = renderedTime.start;
        const stop = renderedTime.stop;

        return start && !stop ?
            <Typography variant="caption" component="div">
                {renderedTime.start} <strong>&rarr;</strong>
            </Typography> : !start && stop ?
                <Typography variant="caption" component="div">
                    <strong>&rarr;</strong> {renderedTime.stop}
                </Typography> :
                <Typography variant="caption" component="div">
                    {renderedTime.start} - {renderedTime.stop}
                </Typography>
    }
}

export default ShiftEventTimeRange;
