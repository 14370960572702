import axios from 'axios';
import {datetime} from "@atttomyx/shared-utils";

export const findTimeOffs = (start, stop, success, failure) => {
    const url = "/api/v1/timeOff/list?start=" + start + "&stop=" + stop;

    axios.get(url)
    .then(response => {
        const json = response.data;
        const timeOffs = json.timeOffs;

        success(timeOffs);
    })
    .catch(failure);
};

export const createTimeOff = (timeOff, success, failure) => {
    const url = "/api/v1/timeOff/";

    axios.post(url, {
        requestedBy: timeOff.requestedBy,
        start: datetime.sanitizeDateTime(timeOff.start),
        stop: datetime.sanitizeDateTime(timeOff.stop),
        reason: timeOff.reason,
        approved: timeOff.approved,
        notes: timeOff.notes,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const updateTimeOff = (timeOffId, timeOff, success, failure) => {
    const url = "/api/v1/timeOff/" + timeOffId + "/";

    axios.put(url, {
        requestedBy: timeOff.requestedBy,
        start: datetime.sanitizeDateTime(timeOff.start),
        stop: datetime.sanitizeDateTime(timeOff.stop),
        reason: timeOff.reason,
        approved: timeOff.approved,
        notes: timeOff.notes,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const deleteTimeOff = (timeOffId, success, failure) => {
    const url = "/api/v1/timeOff/" + timeOffId + "/";

    axios.delete(url)
    .then(response => {
        success(timeOffId);
    })
    .catch(failure);
};

export const createRequest = (timeOff, success, failure) => {
    const url = "/api/v1/timeOff/request";

    axios.post(url, {
        start: datetime.sanitizeDateTime(timeOff.start),
        stop: datetime.sanitizeDateTime(timeOff.stop),
        reason: timeOff.reason,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const updateRequest = (timeOffId, timeOff, success, failure) => {
    const url = "/api/v1/timeOff/" + timeOffId + "/request";

    axios.put(url, {
        start: datetime.sanitizeDateTime(timeOff.start),
        stop: datetime.sanitizeDateTime(timeOff.stop),
        reason: timeOff.reason,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const approveTimeOff = (timeOffId, notes, success, failure) => {
    const url = "/api/v1/timeOff/" + timeOffId + "/approve";

    axios.put(url, {
        notes: notes,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const denyTimeOff = (timeOffId, notes, success, failure) => {
    const url = "/api/v1/timeOff/" + timeOffId + "/deny";

    axios.put(url, {
        notes: notes,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const cancelTimeOff = (timeOffId, success, failure) => {
    const url = "/api/v1/timeOff/" + timeOffId + "/cancel";

    axios.delete(url)
    .then(response => {
        success(timeOffId);
    })
    .catch(failure);
};
