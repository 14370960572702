import axios from 'axios';
import {standardizePatterns} from "../utils/tracks";
import {datetime} from "@atttomyx/shared-utils";

export const listTracks = (cursor, limit, success, failure) => {
    let url = "/api/v1/track/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const createTrack = (track, success, failure) => {
    const url = "/api/v1/track/";

    axios.post(url, toData(track))
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

const toData = (track) => {
    const data = {
        title: track.title,
        color: track.color,
        start: datetime.sanitizeDate(track.start),
    };

    standardizePatterns(track, data);

    return data;
};

export const saveTrack = (trackId, track, success, failure) => {
    const url = "/api/v1/track/" + trackId + "/";

    axios.put(url, toData(track))
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const deleteTrack = (trackId, success, failure) => {
    const url = "/api/v1/track/" + trackId + "/";

    axios.delete(url)
    .then(response => {
        success(trackId);
    })
    .catch(failure);
};
