import * as Moment from "moment";
import {extendMoment} from "moment-range";
import {arrays, datetime, objects, sorting, strings} from "@atttomyx/shared-utils";
import {assignedToShift, isPartOfRecurrence, mayVolunteer, toCompositeId, unassignUsers} from "./shifts";
import {findTrackId} from "./tracks";
import {misc} from "@atttomyx/shared-constants";
import {COLORS_ATTRIBUTES, COLORS_TRACKS, VIEW_TIME_DAY,} from "../constants";

const REASON_OWN = "own";
const REASON_TRADE = "trade";
const REASON_OPEN = "open";

const moment = extendMoment(Moment);

export const getContentHeight = () => {
    const screen = window.screen;

    return screen.height - 224;
};

export const timeOffsToEvents = (loggedIn, timeOffs, branding, users, view) => {
    const filtered = filterTimeOffs(timeOffs, users);
    const userIdToUser = arrays.getIdToEntity(users);
    const events = [];

    filtered.forEach((timeOff) => {
        const user = userIdToUser[timeOff.requestedBy];
        const customFields = {
            timeOff: timeOff,
            view: view,
        };

        if (user) {
            const approved = strings.isNotBlank(timeOff.approvedBy);
            const denied = strings.isNotBlank(timeOff.deniedBy);

            if (loggedIn.roles.admin || user.id === loggedIn.id || approved) {
                const classes = [];
                let color = "#888888";

                customFields.user = user;
                customFields.firstName = user.firstName;
                customFields.lastName = user.lastName;

                if (!approved && !denied && !datetime.isPast(timeOff.stop)) {
                    arrays.addTo(classes, "needs-attention");
                }

                if (approved) {
                    color = branding.primary;
                    customFields.admin = userIdToUser[timeOff.approvedBy];

                } else if (denied) {
                    color = branding.secondary;
                    customFields.admin = userIdToUser[timeOff.deniedBy];
                }

                arrays.addTo(events, {
                    id: timeOff.id,
                    start: timeOff.start,
                    end: timeOff.stop,
                    color: color,
                    classNames: classes,
                    extendedProps: customFields,
                });
            }
        }
    });

    return events;
};

const filterTimeOffs = (timeOffs, users) => {
    const userIds = arrays.getIds(users);
    const filtered = [];

    arrays.addAll(filtered, timeOffs.filter((timeOff) => {
        return arrays.contains(userIds, timeOff.requestedBy);
    }));

    return filtered;
};

const shiftIdToEntity = (array) => {
    const shiftIdToEntity = {};

    array.forEach((entity) => {
        const shiftId = toCompositeId(entity.shiftKey);

        shiftIdToEntity[shiftId] = entity;
    });

    return shiftIdToEntity;
};

export const shiftsToEvents = (account, user, shifts, timeOffs, users, deletedUsers, attributes, tracks, volunteers, trades, filters, view) => {
    const branding = account.branding;
    const settings = account.settings;
    const userIdToUser = arrays.getIdToEntity(users);
    const userIdToDeletedUser = arrays.getIdToEntity(deletedUsers);
    const attributeIdToAttribute = arrays.getIdToEntity(attributes);
    const shiftIdToVolunteer = shiftIdToEntity(volunteers);
    const shiftIdToTrade = shiftIdToEntity(trades);
    const filtered = filterShifts(user, settings, shifts, timeOffs, shiftIdToTrade, filters);
    const shiftIdToTrack = getShiftIdToTrack(filtered, tracks);
    const events = [];

    filtered.forEach((shift) => {
        const event = shiftToEvent(user, shift, view, branding, userIdToUser, userIdToDeletedUser, attributeIdToAttribute, shiftIdToTrack, shiftIdToVolunteer, shiftIdToTrade);

        if (view !== VIEW_TIME_DAY) {
            const startDate = datetime.getDate(shift.start);
            const stopDate = datetime.getDate(shift.stop);

            if (startDate !== stopDate) {
                const part1 = objects.deepCopy(event);

                part1.id = event.id;
                part1.groupId = event.id;
                part1.end = datetime.endOfDay(startDate);
                part1.extendedProps.split = true;
                part1.extendedProps.start = shift.start;
                part1.extendedProps.stop = shift.stop;

                arrays.addTo(events, part1);

            } else {
                arrays.addTo(events, event);
            }
        } else {
            arrays.addTo(events, event);
        }
    });

    return events;
};

const getShiftIdToTrack = (shifts, tracks) => {
    const trackIdToTrack = arrays.getIdToEntity(tracks);
    const shiftIdToTrack = {};

    shifts.filter(isPartOfRecurrence).forEach(shift => {
        const shiftId = shift.key.shiftId;
        const recurrence = shift.recurrence;
        const trackId = recurrence ? findTrackId(recurrence, tracks) : null;
        const track = trackId ? trackIdToTrack[trackId] : null;

        if (shiftId && track) {
            shiftIdToTrack[shiftId] = track;
        }
    });

    return shiftIdToTrack;
};

const shiftToEvent = (loggedIn, shift, view, branding, userIdToUser, userIdToDeletedUser, attributeIdToAttribute, shiftIdToTrack, shiftIdToVolunteer, shiftIdToTrade) => {
    const shiftId = toCompositeId(shift.key);
    const openings = shift.openings;
    const userIds = shift.userIds;
    const track = shiftIdToTrack[shift.key.shiftId];
    const attribute = attributeIdToAttribute[shift.attributeId] || {};
    const volunteer = shiftIdToVolunteer[shiftId];
    const trade = shiftIdToTrade[shiftId];
    const customFields = {
        view: view,
        shift: shift,
        track: shiftIdToTrack[shift.key.shiftId],
        attribute: attribute.title || misc.UNKNOWN,
        color: attribute.color || COLORS_ATTRIBUTES[0],
    };

    if (loggedIn.roles.admin) {
        if (userIds.length > 0) {
            const users = [];

            userIds.forEach((userId) => {
                const user = userIdToUser[userId] || userIdToDeletedUser[userId];

                if (user) {
                    arrays.addTo(users, user);
                }
            });

            users.sort(sorting.sortByLastNameAndFirstName);
            customFields.users = users;
        }
    }

    if (!loggedIn.roles.admin) {
        if (trade && (loggedIn.id === trade.requestedBy || arrays.contains(trade.requestedIds, loggedIn.id))) {
            const requestedBy = userIdToUser[trade.requestedBy];

            customFields.trade = trade;
            customFields.wanted = arrays.contains(trade.volunteerIds, loggedIn.id);

            if (requestedBy && loggedIn.id !== requestedBy.id) {
                customFields.user = requestedBy;
            }
        }

        if (volunteer && loggedIn.id === volunteer.userId) {
            customFields.volunteer = volunteer;
        }
    }

    const classes = [];
    let color;

    if (loggedIn.roles.admin) {
        if (track) {
            color = track.color || COLORS_TRACKS[0];

        } else {
            color = COLORS_TRACKS[0];
        }

        if (openings > userIds.length && !datetime.isPast(shift.stop)) {
            arrays.addTo(classes, "needs-attention");
        }

    } else {
        color = branding.primary;

        if (!arrays.contains(userIds, loggedIn.id)) {
            color = branding.secondary;

            arrays.addTo(classes, "needs-attention");
        }
    }

    return {
        id: shiftId,
        start: shift.start,
        end: shift.stop,
        color: color,
        classNames: classes,
        extendedProps: customFields,
    }
};

const filterShifts = (user, settings, shifts, timeOffs, shiftIdToTrade, filters) => {
    const maxSequentialHours = settings.maxSequentialHours;
    const filtered = [];

    if (user.roles.admin) {
        const attributeId = filters.attributeId;
        const userIds = filters.userIds || [];

        arrays.addAll(filtered, shifts.filter((shift) => {
            const attribute = !attributeId || shift.attributeId === attributeId;
            const assignee = userIds.length === 0 || arrays.containsAny(shift.userIds, userIds);

            return attribute && assignee;
        }));

        unassignUsers(filtered, timeOffs, maxSequentialHours);

    } else {
        const userId = user.id;
        const firstPass = [];
        const secondPass = [];
        const hoursBeforeOvertime = settings.hoursBeforeOvertime;
        const allowOvertime = settings.allowVolunteerOvertime;

        arrays.addAll(firstPass, shifts);
        unassignUsers(firstPass, timeOffs, maxSequentialHours);

        arrays.addAll(secondPass, firstPass.filter((shift) => {
            const shiftId = toCompositeId(shift.key);
            const trade = shiftIdToTrade[shiftId] || {};
            const past = datetime.isPast(shift.stop);
            let include = false;

            if (arrays.contains(shift.userIds, userId)) {
                include = true;
                shift.reason = REASON_OWN;

            } else if (!past && arrays.contains(trade.requestedIds, userId)) {
                include = true;
                shift.reason = REASON_TRADE;

            } else if (!past && mayVolunteer(user, shift, firstPass, timeOffs)) {
                include = true;
                shift.reason = REASON_OPEN;
            }

            return include;
        }));

        arrays.addAll(filtered, secondPass.filter((shift) => {
            const reason = shift.reason;

            delete shift.reason;

            return reason !== REASON_OPEN || allowOvertime
                || willNotCauseOvertime(user, shift, secondPass, hoursBeforeOvertime);
        }));
    }

    return filtered;
};

const willNotCauseOvertime = (user, shift, shifts, hoursBeforeOvertime) => {
    const userId = user.id;
    const weekStart = datetime.beginningOfWeek(shift.start);
    const weekStop = datetime.endOfWeek(shift.start);
    const weekStartMoment = moment(weekStart);
    const weekStopMoment = moment(weekStop);
    const weekRange = moment.range(weekStartMoment, weekStopMoment);
    const shiftHours = datetime.hoursApart(shift.stop, shift.start);
    let totalHours = 0;

    shifts.filter((shift) => {
        return assignedToShift(shift, userId);
    }).forEach((shift) => {
        const startMoment = moment(shift.start);
        const stopMoment = moment(shift.stop);
        const range = moment.range(startMoment, stopMoment);
        const hours = range.duration("hour");

        if (weekRange.contains(startMoment)) {
            totalHours += hours;
        }
    });

    return totalHours + shiftHours <= hoursBeforeOvertime;
};

export const renderTime = (event, wide, field) => {
    const customFields = event.extendedProps;
    let renderedStart = null;
    let renderedStop = null;

    if (customFields.split) {
        const start = customFields.start;
        const stop = customFields.stop;

        if (start) {
            renderedStart = wide ? datetime.getPrettyTime(start) : datetime.getShortTime(start);
        }

        if (stop) {
            renderedStop = wide ? datetime.getPrettyTime(stop) : datetime.getShortTime(stop);
        }

    } else {
        const obj = customFields[field];
        const start = obj.start;
        const stop = obj.stop;

        if (start) {
            renderedStart = wide ? datetime.getPrettyTime(start) : datetime.getShortTime(start);
        }

        if (stop) {
            renderedStop = wide ? datetime.getPrettyTime(stop) : datetime.getShortTime(stop);
        }
    }

    return {
        start: renderedStart,
        stop: renderedStop,
    }
};

export const getDayCellClassNames = (info, showScheduleUntil) => {
    const css = [];

    if (showScheduleUntil) {
        const date = datetime.getDate(info.date.toISOString());

        if (date >= showScheduleUntil) {
            arrays.addTo(css, "not-posted");
        }
    } else {
        arrays.addTo(css, "not-posted");
    }

    return css;
};

export const sortByStartAndAttribute = (eventA, eventB) => {
    return sortByFields(eventA, eventB, "start", "attribute");
};

const sortByFields = (eventA, eventB, field1, field2) => {
    const fieldA1 = getSanitizedValue(eventA, field1);
    const fieldA2 = getSanitizedValue(eventA, field2);
    const fieldB1 = getSanitizedValue(eventB, field1);
    const fieldB2 = getSanitizedValue(eventB, field2);

    let ret = 0;

    if (fieldA1 > fieldB1) {
        ret = 1;

    } else if (fieldA1 < fieldB1) {
        ret = -1;

    } else if (fieldA2 > fieldB2) {
        ret = 1;

    } else if (fieldA2 < fieldB2) {
        ret = -1;
    }

    return ret;
};

const getSanitizedValue = (event, field) => {
    let value = event[field];

    if (value === undefined) {
        value = event.extendedProps[field];
    }

    return strings.sanitizeStr(value).toLowerCase();
};
