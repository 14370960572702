import React, {useMemo} from "react";
import {TextField} from "@mui/material";
import {datetime, forms, objects} from "@atttomyx/shared-utils";

const FromUntilFilters = (props) => {
    const { data, onChange : onSync } = props;

    const today = useMemo(() => {
        return datetime.today();
    }, []);

    const threeMonthsAgo = useMemo(() => {
        return datetime.beginningOfMonthsAgo(today, 3);
    }, [today]);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        onSync(modified);
    };

    return <div className="from-until-filters">
        <div className="field">
            <TextField label="From"
                       type="date"
                       required={true}
                       value={forms.sanitizeValue(data.fromDate)}
                       error={datetime.isFuture(data.untilDate) || datetime.isBefore(data.fromDate, threeMonthsAgo)}
                       onChange={(event) => onChange("fromDate", event.target.value)}
                       inputProps={{
                           min: threeMonthsAgo,
                           max: today,
                       }}
            />
        </div>
        <div className="field">
            <TextField label="Until"
                       type="date"
                       required={true}
                       value={forms.sanitizeValue(data.untilDate)}
                       error={datetime.isFuture(data.untilDate) || datetime.isBefore(data.untilDate, data.fromDate)}
                       onChange={(event) => onChange("untilDate", event.target.value)}
                       inputProps={{
                           min: threeMonthsAgo,
                           max: today,
                       }}
            />
        </div>
    </div>
}

export default FromUntilFilters;
