import React, {Component} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import TimeZoneField from "../../fields/timeZoneField/timeZoneField";
import * as timeSlotService from "../../services/timeSlots";
import {forms, strings} from "@atttomyx/shared-utils";
import {timezones} from "@atttomyx/react-utils";

class TimeSlotDialog extends Component {

    constructor(props) {
        super(props);

        const timeSlot = this.props.timeSlot;
        const timeZone1 = timezones.getTimeZone();
        const timeZone2 = timeSlot.timeZone;
        const differ = timezones.offsetsDiffer(timeZone1, timeZone2);

        this.state = {
            title: timeSlot.title,
            start: differ ? timezones.convertTime(timeSlot.start, timeZone1, timeZone2) : timeSlot.start,
            stop: differ ? timezones.convertTime(timeSlot.stop, timeZone1, timeZone2) : timeSlot.stop,
            timeZone: timeZone2 || timeZone1,
            saving: false,
        };
    }

    setTitle(event) {
        const title = event.target.value;

        this.setState({
            title: title,
        });
    }

    setStart(event) {
        const start = event.target.value;

        this.setState({
            start: start,
        });
    }

    setStop(event) {
        const stop = event.target.value;

        this.setState({
            stop: stop,
        });
    }

    setTimeZone(timeZone) {
        this.setState({
            timeZone: timeZone,
        });
    }

    submitForm() {
        const timeSlotId = this.props.timeSlot.id;
        const title = this.state.title;
        const start = this.state.start;
        const stop = this.state.stop;
        const timeZone = this.state.timeZone;
        const callback = this.timeSlotSaved.bind(this);

        this.setState({
            saving: true,
        });

        const timeSlot = {
            title: title,
            start: start,
            stop: stop,
            timeZone: timeZone,
        };

        if (timeSlotId) {
            timeSlotService.saveTimeSlot(timeSlotId, timeSlot, callback);

        } else {
            timeSlotService.createTimeSlot(timeSlot, callback);
        }
    }

    timeSlotSaved(timeSlot) {
        this.setState({
            saving: false,
        });

        this.props.onSaved(timeSlot);
    }

    render() {
        const title = this.state.title;
        const start = this.state.start;
        const stop = this.state.stop;
        const timeZone = this.state.timeZone;
        const saving = this.state.saving;

        return <Dialog
            open={true}
            aria-labelledby="time-slot-dialog"
        >
            <ClosableDialogTitle onClose={this.props.onCancel}>
                Time Slot
            </ClosableDialogTitle>
            <DialogContent className="time-slot-dialog">
                {saving ?
                    <CircularProgress size="40px"/> :
                    <div>
                        <div className="field">
                            <TextField label="Title" required={true}
                                       type="text"
                                       value={forms.sanitizeValue(title)}
                                       onChange={this.setTitle.bind(this)}
                            />
                        </div>
                        <div className="field">
                            <TextField label="Start" required={true}
                                       type="time"
                                       value={forms.sanitizeValue(start)}
                                       onChange={this.setStart.bind(this)}
                                       inputProps={{
                                           step: 300,   // 5 min
                                       }}
                            />
                        </div>
                        <div className="field">
                            <TextField label="Stop" required={true}
                                       type="time"
                                       value={forms.sanitizeValue(stop)}
                                       onChange={this.setStop.bind(this)}
                                       inputProps={{
                                           step: 300,   // 5 min
                                       }}
                            />
                        </div>
                        <div className="field">
                            <TimeZoneField value={timeZone}
                                           onChange={this.setTimeZone.bind(this)}/>
                        </div>
                    </div>}
            </DialogContent>
            <DialogActions>
                <Button color="primary"
                        disabled={strings.isBlank(title) || strings.isBlank(start) || strings.isBlank(stop)}
                        onClick={this.submitForm.bind(this)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    }
}

export default TimeSlotDialog;
