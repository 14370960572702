import {objects} from "@atttomyx/shared-utils";

export const sanitizeAccount = (account) => {
    account.branding = normalizeBranding(account.branding);
    account.address = account.address || {};
};

const normalizeBranding = (branding) => {
    return {
        logoUrl: branding.logoUrl,
        logoContainsName: branding.logoContainsName,
        primary: branding.primary || branding.primaryColor,
        secondary: branding.secondary || branding.secondaryColor,
    }
};

export const standardizeBranding = (branding) => {
    return {
        logoUrl: branding.logoUrl,
        logoContainsName: branding.logoContainsName,
        primaryColor: branding.primary,
        secondaryColor: branding.secondary,
    }
};

export const sanitizeMyAccount = (account) => {
    sanitizeAccount(account);

    account.settings = sanitizeSettings(account.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};

    sanitized.maxSequentialHours = objects.defaultIfNullOrUndefined(sanitized.maxSequentialHours, 16);
    sanitized.hoursBeforeOvertime = objects.defaultIfNullOrUndefined(sanitized.hoursBeforeOvertime, 40);
    sanitized.allowVolunteerOvertime = objects.defaultIfNullOrUndefined(sanitized.allowVolunteerOvertime, false);
    sanitized.allowTradeOvertime = objects.defaultIfNullOrUndefined(sanitized.allowTradeOvertime, false);
    sanitized.hideOtherUsers = objects.defaultIfNullOrUndefined(sanitized.hideOtherUsers, true);
    sanitized.showScheduleUntil = objects.defaultIfNullOrUndefined(sanitized.showScheduleUntil, null);

    return sanitized;
};
