import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {isMobile, isMobileOnly} from "react-device-detect";
import {Fab, IconButton, Typography} from "@mui/material";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Email as EmailIcon,
    Phone as CallIcon,
    Share as ShareIcon,
    Sms as SmsIcon
} from "@mui/icons-material";
import {useFiltered} from "@atttomyx/shared-hooks";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingButtons} from "@atttomyx/react-components";
import UserCard from "../../cards/userCard/userCard";
import UserFilters from "../../filters/userFilters/userFilters";
import * as userService from "../../services/users";
import {arrays, sorting, strings, users as userUtils} from "@atttomyx/shared-utils";
import {communication, router} from "@atttomyx/react-utils";
import {shareJoinUrl} from "../../utils/deepLinks";
import {PAGE_PROFILE, PAGE_USER} from "../../constants";
import "./usersPage.css";

const UsersPage = (props) => {
    const { history, dimensions, snackbar, account, user : me, users, filters, attributes, onDelete } = props;
    const [ user, setUser ] = useState(null);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ visibleUsers, setVisibleUsers ] = useState([]);
    const [ attributeIdToTitle, setAttributeIdToTitle ] = useState({});
    const filtered = useFiltered(visibleUsers, filters, sorting.sortByLastNameAndFirstName);

    useEffect(() => {
        if (me.roles.admin || !account.settings.hideOtherUsers) {
            setVisibleUsers(users);

        } else {
            setVisibleUsers(users.filter(user => arrays.containsAny(me.settings.attributeIds, user.settings.attributeIds)));
        }
    }, [me, account, users]);

    useEffect(() => {
        if (me.roles.admin || !account.settings.hideOtherUsers) {
            setAttributeIdToTitle(arrays.getIdToField(attributes, "title"));

        } else {
            const idToAttribute = arrays.getIdToEntity(attributes);
            const visibleAttributes = [];

            me.settings.attributeIds.forEach(attributeId => arrays.addTo(visibleAttributes, idToAttribute[attributeId]));

            setAttributeIdToTitle(arrays.getIdToField(visibleAttributes, "title"));
        }
    }, [me, account, attributes]);

    return <div className="users-page">
        <Typography variant="h5" paragraph={true}>
            Users
        </Typography>
        <FiltersAccordion
            filters={filters}
            form={UserFilters}
            formProps={{
                attributeIdToTitle: attributeIdToTitle,
            }}
            open={showFilters}
            onOpen={() => setShowFilters(true)}
            onClose={() => setShowFilters(false)}
        />
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(user) =>
                <UserCard key={user.id}
                          me={me}
                          user={user}
                          attributeIdToTitle={attributeIdToTitle}
                >
                    {me.roles.admin ?
                        <IconButton color="secondary" title="Delete" className="delete"
                                    disabled={me.id === user.id}
                                    onClick={() => setUser(user)}>
                            <DeleteIcon/>
                        </IconButton> : null}
                    {isMobileOnly ?
                        <IconButton color="primary" title="Call"
                                    disabled={me.id === user.id || (!me.roles.admin && !user.settings.allowCalls) || strings.isBlank(user.phone)}
                                    onClick={() => communication.popCall(user.phone)}>
                            <CallIcon/>
                        </IconButton> : null}
                    {isMobile ?
                        <IconButton color="primary" title="Text"
                                    disabled={me.id === user.id || (!me.roles.admin && !user.settings.allowSms) || strings.isBlank(user.phone)}
                                    onClick={() => communication.popSms(user.phone)}>
                            <SmsIcon/>
                        </IconButton> : null}
                    <IconButton color="primary" title="Email"
                                disabled={me.id === user.id}
                                onClick={() => communication.popEmail(user.email)}>
                        <EmailIcon/>
                    </IconButton>
                    {me.roles.admin ?
                        <IconButton color="primary" title="Edit"
                                    onClick={() => me.id === user.id
                                        ? router.redirectTo(history, PAGE_PROFILE)
                                        : router.redirectTo(history, `${PAGE_USER}/${user.id}`)}>
                            <EditIcon/>
                        </IconButton> : null}
                </UserCard>}
        />
        {me.roles.admin ?
            <FloatingButtons position="higher">
                <Fab color="primary" size="large" title="Invite user(s)"
                     disabled={!isMobile || strings.isBlank(account.code)}
                     onClick={() => shareJoinUrl(account)}
                >
                    <ShareIcon/>
                </Fab>
                <Fab color="primary" size="large" title="Add user"
                     onClick={() => router.redirectTo(history, PAGE_USER)}
                >
                    <AddIcon/>
                </Fab>
            </FloatingButtons> : null}
        {user ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={userUtils.getFullName(user)}
            onCancel={() => setUser(null)}
            onDelete={(userId) => {
                onDelete(user);
                setUser(null);
            }}
            deleter={(success, failure) => userService.deleteUser(user.id, success, failure)}
        /> : null}
    </div>
}

export default withRouter(UsersPage);
