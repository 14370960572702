import * as Moment from "moment";
import {extendMoment} from "moment-range";
import {arrays, datetime, strings, users as userUtils} from "@atttomyx/shared-utils";
import {isApproved} from "./timeOffs";
import {sortByStart} from "./sorting";
import {misc} from "@atttomyx/shared-constants";

const DAY_OF_YEAR = "YYYY-MM-DD";
const TIME_OF_DAY = "HH:mm";

const moment = extendMoment(Moment);

export const sanitizeShift = (shift) => {
    shift.key = shift.key || fromCompositeId(shift.id);
    shift.id = toCompositeId(shift.key);

    shift.userIds = shift.userIds || [];
};

export const isPartOfRecurrence = (shift) => {
    const instanceId = shift.key.instanceId;

    return instanceId !== undefined && instanceId !== null;
};

export const toCompositeId = (shiftKey) => {
    const shiftId = shiftKey.shiftId;
    const instanceId = shiftKey.instanceId;

    return instanceId
        ? `${shiftId}:${instanceId}`
        : shiftId;
};

export const fromCompositeId = (compositeId) => {
    const index = compositeId.indexOf(":");

    return index > -1 ? {
        shiftId: compositeId.substring(0, index),
        instanceId: parseInt(compositeId.substring(index + 1, compositeId.length)),
    } : {
        shiftId: compositeId,
        instanceId: null,
    };
};

export const keyEquals = (key1, key2) => {
    return !strings.differ(key1.shiftId, key2.shiftId)
        && !strings.differ(key1.instanceId, key2.instanceId);
};

export const findTimeSlotIdForShift = (shift, timeSlots) => {
    const start = shift.start;
    const stop = shift.stop;
    const data = {
        startTime: datetime.getTime(start),
        stopTime: datetime.getTime(stop),
    };

    return findTimeSlotIdForData(data, timeSlots);
};

export const findTimeSlotIdForData = (data, timeSlots) => {
    const startTime = data.startTime;
    const stopTime = data.stopTime;
    let timeSlotId = null;

    if (startTime && stopTime) {
        timeSlots.forEach((timeSlot) => {
            if (startTime === timeSlot.start && stopTime === timeSlot.stop) {
                timeSlotId = timeSlot.id;
            }
        });
    }

    return timeSlotId;
};

const availableUsers = (shift, attributeId, start, stop, users,
    includeAssigned, allowOvertime, hoursBeforeOvertime, maxSequentialHours, userIdToHours,
    shifts, timeOffs) => {

    const shiftId = shift.id || misc.UNKNOWN;
    const shiftToUse = arrays.findEntity(shifts, shiftId) || shift;
    const daysAndTimes = toDaysAndTimes(start, stop);
    const hours = datetime.hoursApart(stop, start);
    const filtered = [];

    console.log("--- availability filter ---");

    users.filter((user) => {
        const userId = user.id;
        const name = userUtils.getFullName(user);
        const settings = user.settings;
        let available = false;

        if (assignedToShift(shiftToUse, userId)) {
            if (!includeAssigned) {
                console.log(name + " is already assigned");

            } else if (hasApprovedTimeOff(userId, timeOffs, daysAndTimes.range)) {
                console.log(name + " has approved time off");

            } else if (tooManySequentialHours(start, stop, userId, shifts, maxSequentialHours, hours)) {
                console.log(name + " would have too many sequential hours");

            } else {
                available = true;
            }

        } else if (!hasSettings(settings)) {
            console.log(name + " lacks settings");

        } else if (!hasAttribute(settings, attributeId)) {
            console.log(name + " lacks qualification");

        } else if (hasOverlappingShift(shiftId, userId, shifts, daysAndTimes.range)) {
            console.log(name + " is assigned to an overlapping shift");

        } else if (hasApprovedTimeOff(userId, timeOffs, daysAndTimes.range)) {
            console.log(name + " has approved time off");

        } else if (!allowOvertime && wouldGoIntoOvertime(userId, hoursBeforeOvertime, userIdToHours, allowOvertime ? 0 : hours)) {
            console.log(name + " would go into overtime");

        } else if (tooManySequentialHours(start, stop, userId, shifts, maxSequentialHours, hours)) {
            console.log(name + " would have too many sequential hours");

        } else {
            available = true;
        }

        return available;
    }).forEach((user) => {
        arrays.addTo(filtered, user);
    });

    return filtered;
};

export const mayVolunteer = (user, shift, shifts, timeOffs) => {
    let available = false;

    if (shift.openings > shift.userIds.length
        && datetime.isLessThanMonthFromNow(shift.start)) {

        const start = shift.start;
        const startTime = datetime.getTime(start);
        const stopTime = datetime.getTime(shift.stop);
        let stop = shift.stop;

        if (startTime && stopTime) {
            if (stopTime < startTime) {
                stop = datetime.nextDay(start) + "T" + stopTime;

            } else {
                stop = datetime.getDate(start) + "T" + stopTime;
            }
        }

        const userId = user.id;
        const settings = user.settings;
        const daysAndTimes = toDaysAndTimes(start, stop);

        available = hasSettings(settings)
            && hasAttribute(settings, shift.attributeId)
            && !hasOverlappingShift(shift.id, userId, shifts, daysAndTimes.range)
            && !hasApprovedTimeOff(userId, timeOffs, daysAndTimes.range);
    }

    return available;
};

const hasSettings = (settings) => {
    return settings !== undefined && settings != null;
};

const hasAttribute = (settings, attributeId) => {
    return !attributeId || arrays.contains(settings.attributeIds, attributeId);
};

const toDaysAndTimes = (start, stop) => {
    const startMoment = moment(start);
    const stopMoment = moment(stop);
    const startDate = startMoment.format(DAY_OF_YEAR);
    const stopDate = stopMoment.format(DAY_OF_YEAR);

    const daysAndTimes = {
        startMoment: startMoment,
        startDate: startDate,
        startTime: startMoment.format(TIME_OF_DAY),
        stopMoment: stopMoment,
        stopDate: stopDate,
        stopTime: stopMoment.format(TIME_OF_DAY),
        range: moment.range(startMoment, stopMoment),
    };

    if (startDate === stopDate) {
        daysAndTimes.dayOfWeek = datetime.toDayOfWeek(startMoment.day());

    } else {
        daysAndTimes.startDayOfWeek = datetime.toDayOfWeek(startMoment.day());
        daysAndTimes.stopDayOfWeek = datetime.toDayOfWeek(stopMoment.day());
    }

    return daysAndTimes;
};

const hasApprovedTimeOff = (userId, timeOffs, range) => {
    return timeOffs.filter(isApproved).filter((timeOff) => {
        return timeOff.requestedBy === userId;
    }).filter((timeOff) => {
        const candidate = moment.range(timeOff.start, timeOff.stop);

        return candidate.overlaps(range);
    }).length > 0;
};

const hasRequestedTimeOff = (userId, timeOffs, range) => {
    return timeOffs.filter((timeOff) => {
        return timeOff.requestedBy === userId;
    }).filter((timeOff) => {
        const candidate = moment.range(timeOff.start, timeOff.stop);

        return candidate.overlaps(range);
    }).length > 0;
};

const hasOverlappingShift = (shiftId, userId, shifts, range) => {
    return shifts.filter((shift) => {
        return shift.id !== shiftId && assignedToShift(shift, userId);
    }).filter((shift) => {
        const tmpRange = moment.range(shift.start, shift.stop);

        return range.overlaps(tmpRange);
    }).length > 0;
};

export const assignedToShift = (shift, userId) => {
    return arrays.contains(shift.userIds, userId);
};

const wouldGoIntoOvertime = (userId, hoursBeforeOvertime, userIdToHours, hours) => {
    const userHours = userIdToHours[userId] || 0;

    return hours + userHours > hoursBeforeOvertime;
};

export const getDefaultDate = (date) => {
    return !date || datetime.isPastDate(date) ? datetime.today() : date;
};

const tooManySequentialHours = (start, stop, userId, shifts, maxSequentialHours, hours) => {
    const sequentialHoursBefore = calcSequentialHoursBefore(start, userId, shifts);
    let tooMany;

    if (sequentialHoursBefore + hours > maxSequentialHours) {
        tooMany = true;

    } else {
        const sequentialHoursAfter = calcSequentialHoursAfter(stop, userId, shifts);

        tooMany = sequentialHoursBefore + hours + sequentialHoursAfter > maxSequentialHours
    }

    return tooMany;
};

const calcSequentialHoursBefore = (start, userId, shifts) => {
    let sequentialHours = 0;

    shifts
        .filter(shift => shift.stop === start && arrays.contains(shift.userIds, userId))
        .forEach(shift => {
            sequentialHours = datetime.hoursApart(shift.stop, shift.start) + calcSequentialHoursBefore(shift.start, userId, shifts);
        });

    return sequentialHours;
};

const calcSequentialHoursAfter = (stop, userId, shifts) => {
    let sequentialHours = 0;

    shifts
        .filter(shift => shift.start === stop && arrays.contains(shift.userIds, userId))
        .forEach(shift => {
            sequentialHours = datetime.hoursApart(shift.stop, shift.start) + calcSequentialHoursAfter(shift.stop, userId, shifts);
        });

    return sequentialHours;
};

export const unassignUsers = (shifts, timeOffs, maxSequentialHours) => {
    removeApprovedTimeOff(shifts, timeOffs);
    removeTooManySequentialHours(shifts, maxSequentialHours);
};

const removeApprovedTimeOff = (shifts, timeOffs) => {
    shifts.forEach((shift) => {
        const startMoment = moment(shift.start);
        const stopMoment = moment(shift.stop);
        const range = moment.range(startMoment, stopMoment);

        shift.userIds = shift.userIds.filter((userId) => {
            return !hasApprovedTimeOff(userId, timeOffs, range);
        });
    });
};

const removeTooManySequentialHours = (shifts, maxSequentialHours) => {
    shifts.sort(sortByStart).forEach((shift) => {
        const start = shift.start;
        const stop = shift.stop;
        const hours = datetime.hoursApart(stop, start);

        shift.userIds = shift.userIds.filter((userId) => {
            const sequentialHours = calcSequentialHoursBefore(start, userId, shifts);

            return sequentialHours + hours <= maxSequentialHours;
        });
    });
};

export const isVisible = (shift, showScheduleUntil) => {
    const start = datetime.getDate(shift.start);

    return showScheduleUntil && showScheduleUntil > start;
};

export const filterByDate = (shifts, date) => {
    return shifts.filter(event => {
        return date === datetime.getDate(event.start);
    });
};

export const findAvailableUsers = (shift, users, shiftCache, timeOffCache,
                                   includeAssigned, allowOvertime, hoursBeforeOvertime, maxSequentialHours, userIdToHours, callback) => {

    const attributeId = shift.attributeId;
    const start = shift.start;
    const stop = shift.stop;

    if (start && stop && stop >= start) {
        const tomorrow = datetime.nextDay(start);

        shiftCache.get(start, tomorrow, (shifts) => {
            timeOffCache.get(start, tomorrow, (timeOffs) => {
                unassignUsers(shifts, timeOffs, maxSequentialHours);

                const available = availableUsers(shift, attributeId, start, stop,
                    users, includeAssigned, allowOvertime, hoursBeforeOvertime, maxSequentialHours, userIdToHours,
                    shifts, timeOffs);

                callback(available);
            });
        });
    } else {
        callback([]);
    }
};

export const calculateHours = (shiftCache, timeOffCache, date, maxSequentialHours, callback) => {
    const weekStart = datetime.beginningOfWeek(date);
    const weekStop = datetime.endOfWeek(date);
    const weekStartMoment = moment(weekStart);
    const weekStopMoment = moment(weekStop);
    const weekRange = moment.range(weekStartMoment, weekStopMoment);

    shiftCache.get(weekStart, weekStop, shifts => {
        timeOffCache.get(weekStart, weekStop, timeOffs => {
            const userIdToHours = {};

            unassignUsers(shifts, timeOffs, maxSequentialHours);

            shifts.forEach((shift) => {
                const startMoment = moment(shift.start);
                const stopMoment = moment(shift.stop);
                const range = moment.range(startMoment, stopMoment);
                const hours = range.duration("hour");

                if (weekRange.contains(startMoment)) {
                    shift.userIds.forEach((userId) => {
                        const userHours = userIdToHours[userId] || 0;

                        userIdToHours[userId] = userHours + hours;
                    });
                }
            });

            callback(userIdToHours);
        });
    });
};

export const calculateHoursForUser = (shiftCache, timeOffCache, userId, date, maxSequentialHours, callback) => {
    const weekStart = datetime.beginningOfWeek(date);
    const weekStop = datetime.endOfWeek(date);
    const weekStartMoment = moment(weekStart);
    const weekStopMoment = moment(weekStop);
    const weekRange = moment.range(weekStartMoment, weekStopMoment);

    shiftCache.get(weekStart, weekStop, shifts => {
        timeOffCache.get(weekStart, weekStop, timeOffs => {
            let totalHours = 0;

            unassignUsers(shifts, timeOffs, maxSequentialHours);

            shifts.filter(shift => assignedToShift(shift, userId))
                .forEach((shift) => {
                    const startMoment = moment(shift.start);
                    const stopMoment = moment(shift.stop);
                    const range = moment.range(startMoment, stopMoment);
                    const hours = range.duration("hour");

                    if (weekRange.contains(startMoment)) {
                        totalHours += hours;
                    }
                });

            callback(totalHours);
        });
    });
};

export const timeOffOverlaps = (shift, timeOffCache, userId, callback) => {
    const start = shift.start;
    const stop = shift.stop;
    const daysAndTimes = toDaysAndTimes(start, stop);

    timeOffCache.get(start, stop, (timeOffs) => {
        const overlaps = hasRequestedTimeOff(userId, timeOffs, daysAndTimes.range)

        callback(overlaps);
    });
};
