import {objects} from "@atttomyx/shared-utils";
import {base} from "./base";

export const dark = (branding) => {
    return objects.deepMerge(base(branding), {
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    backgroundColor: "#444444",
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#444444",
                },
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "#444444",
                },
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: "#444444",
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: "#494949",
                },
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "#575757",
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#575757",
                },
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#222222",
                },
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    border: "1px solid #757575",
                    backgroundColor: "#575757",
                    "&:hover": {
                        backgroundColor: "#676767",
                    },
                    "&$focused": {
                        backgroundColor: "#676767",
                    },
                },
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    '& [class*="Mui-disabled"]': {
                        color: "rgba(255, 255, 255, 0.3)",
                    },
                },
            }
        },
        MUIRichTextEditor: {
            styleOverrides: {
                editor: {
                    backgroundColor: "#575757",
                    border: "1px solid #757575",
                },
                placeHolder: {
                    color: "rgba(255, 255, 255, 0.7)",
                },
                toolbar: {
                    backgroundColor: "#575757",
                    borderTop: "1px solid #757575",
                    borderLeft: "1px solid #757575",
                    borderRight: "1px solid #757575",
                },
            }
        },
    });
};
