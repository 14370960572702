import React, {useEffect, useMemo, useState} from "react";
import {CircularProgress, Dialog, DialogActions, DialogContent, IconButton} from "@mui/material";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import ShiftInfo from "../../../components/shiftInfo/shiftInfo";
import UserList from "../../../components/userList/userList";
import * as shiftService from "../../../services/shifts";
import {calculateHours, isPartOfRecurrence, keyEquals} from "../../../utils/shifts";
import {confirm} from "@atttomyx/react-utils";
import {RECURRENCE_INSTANCE} from "../../../constants";
import "./pastShiftDialog.css";

const PastShiftDialog = (props) => {
    const { user, event, shiftCache, timeOffCache, settings, volunteers, onClose, onDelete } = props;
    const [ userIdToHours, setUserIdToHours ] = useState({});
    const [ loadingUserHours, setLoadingUserHours ] = useState(user.roles.admin);
    const [ deletingShift, setDeletingShift ] = useState(false);

    const customFields = event.extendedProps;
    const users = customFields.users || [];
    const shift = customFields.shift;

    const volunteerIds = useMemo(() => {
        return user.roles.admin
            ? volunteers
                .filter(volunteer => keyEquals(volunteer.shiftKey, shift.key))
                .map(volunteer => volunteer.userId)
            : [];
    }, []);

    useEffect(() => {
        if (user.roles.admin) {
            const maxSequentialHours = settings.maxSequentialHours;

            calculateHours(shiftCache, timeOffCache, shift.start, maxSequentialHours, userIdToHours => {
                setUserIdToHours(userIdToHours);
                setLoadingUserHours(false);
            });
        }
    }, []);

    const deleteShift = () => {
        const mode = isPartOfRecurrence(shift) ? RECURRENCE_INSTANCE : null;

        setDeletingShift(true);

        const success = (shiftKey) => {
            setDeletingShift(false);
            onDelete(shiftKey, false);
        };

        shiftService.deleteShift(shift.key, mode, success);
    };

    return <Dialog
        open={true}
        aria-labelledby="shift-dialog-content"
        className="past-shift-dialog"
    >
        <ClosableDialogTitle onClose={onClose}>
            Past Shift
        </ClosableDialogTitle>
        <DialogContent className="shift-dialog-content">
            {deletingShift ?
                <CircularProgress size="40px" color="secondary"/> :
                <>
                    <ShiftInfo event={event}/>
                    {user.roles.admin ? <>
                        <div className="info">
                            {loadingUserHours ?
                                <CircularProgress size="14px"/> :
                                <UserList users={users}
                                          userIdToHours={userIdToHours}
                                          volunteerIds={volunteerIds}
                                          hoursBeforeOvertime={settings.hoursBeforeOvertime}
                                />}
                        </div>
                    </> : null}
                </>}
        </DialogContent>
        {user.roles.admin && !deletingShift ?
            <DialogActions>
                <IconButton color="secondary" title="Delete shift" className="delete"
                            onClick={() => confirm.confirmDelete("this shift", deleteShift)}>
                    <DeleteIcon/>
                </IconButton>
            </DialogActions> : null}
    </Dialog>
}

export default PastShiftDialog;
