import axios from 'axios';
import {approved, unapproved} from "../utils/trades";

export const listTrades = (cursor, limit, success, failure) => {
    findTrades("/api/v1/trade/list", approved, cursor, limit, success, failure);
};

export const mineTrades = (cursor, limit, success, failure) => {
    findTrades("/api/v1/trade/mine", unapproved, cursor, limit, success, failure);
};

const findTrades = (endpoint, filter, cursor, limit, success, failure) => {
    let url = endpoint + "?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        json.trades = json.trades.filter(filter);

        success(json);
    })
    .catch(failure);
};

export const requestTrade = (trade, success, failure) => {
    const url = "/api/v1/trade/request";
    const shiftKey = trade.shiftKey;

    axios.post(url, {
        shiftKey: {
            shiftId: shiftKey.shiftId,
            instanceId: shiftKey.instanceId,
        },
        userIds: trade.userIds,
        reason: trade.reason,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const editTrade = (tradeId, trade, success, failure) => {
    const url = `/api/v1/trade/${tradeId}/`;

    axios.put(url, {
        userIds: trade.userIds,
        reason: trade.reason,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const volunteerTrade = (tradeId, success, failure) => {
    const url = `/api/v1/trade/${tradeId}/volunteer`;

    axios.put(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const withdrawTrade = (tradeId, success, failure) => {
    const url = `/api/v1/trade/${tradeId}/withdraw`;

    axios.put(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const approveTrade = (tradeId, userId, success, failure) => {
    const url = `/api/v1/trade/${tradeId}/approve`;

    axios.put(url, {
        userId: userId,
    })
    .then(response => {
        success(tradeId);
    })
    .catch(failure);
};

export const deleteTrade = (tradeId, success, failure) => {
    const url = `/api/v1/trade/${tradeId}/`;

    axios.delete(url)
    .then(response => {
        success(tradeId);
    })
    .catch(failure);
};
