import React from "react";
import AttributeUsersFilters from "../attributeUsersFilters/attributeUsersFilters";
import FromUntilFilters from "../fromUntilFilters/fromUntilFilters";

const TradeReportFilters = (props) => {
    const { data, onChange, formProps } = props;

    return <div className="trade-report-filters">
        <AttributeUsersFilters
            data={data}
            onChange={onChange}
            formProps={formProps}
        />
        <FromUntilFilters
            data={data}
            onChange={onChange}
        />
    </div>
}

export default TradeReportFilters;
