import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Typography} from "@mui/material";
import {EmojiPeople as VolunteerIcon} from "@mui/icons-material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import ShiftInfo from "../../../components/shiftInfo/shiftInfo";
import * as volunteerService from "../../../services/volunteers";
import {calculateHoursForUser} from "../../../utils/shifts";
import {datetime, objects} from "@atttomyx/shared-utils";
import "./openShiftDialog.css";

const OpenShiftDialog = (props) => {
    const { snackbar, settings, user, event, shiftCache, timeOffCache, onCancel, onSave, onDelete } = props;
    const [ saving, setSaving ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ userHours, setUserHours ] = useState(0);

    const customFields = event.extendedProps;
    const shift = customFields.shift;
    const volunteer = customFields.volunteer || {};
    const exists = objects.notNullOrUndefined(customFields.volunteer);
    const allowOvertime = settings.allowVolunteerOvertime;
    const maxSequentialHours = settings.maxSequentialHours;
    const hoursBeforeOvertime = settings.hoursBeforeOvertime;
    const wouldGoIntoOvertime = !allowOvertime && (userHours + datetime.hoursApart(shift.stop, shift.start) > hoursBeforeOvertime);

    useEffect(() => {
        if (!allowOvertime) {
            calculateHoursForUser(shiftCache, timeOffCache, user.id, shift.start, maxSequentialHours, setUserHours);
        }
    }, []);

    const submitForm = () => {
        setSaving(true);

        const success = (volunteer) => {
            setSaving(false);
            onSave(volunteer);
            snackbar.setSuccess("You volunteered");
        };

        volunteerService.createVolunteer(shift.key, success, snackbar.setError);
    };

    const deleteVolunteer = () => {
        setDeleting(true);

        const success = (volunteerId) => {
            setDeleting(false);
            onDelete(volunteerId);
            snackbar.setSuccess("Volunteer cancelled");
        };

        volunteerService.deleteVolunteer(volunteer.id, success, snackbar.setError);
    };

    return <Dialog
        open={true}
        aria-labelledby="shift-dialog-content"
        className="open-shift-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Open Shift
        </ClosableDialogTitle>
        <DialogContent className="shift-dialog-content">
            {saving || deleting ?
                <CircularProgress size="40px"/> : deleting ?
                <CircularProgress size="40px" color="secondary"/> :
                <div>
                    <ShiftInfo event={event}/>
                    {exists ? <div>
                        <Divider/>
                        <Typography paragraph={true}>
                            You volunteered
                        </Typography>
                        <Typography>
                            You will be notified if your admin assigns you to this shift
                        </Typography>
                    </div> : wouldGoIntoOvertime ? <div>
                        <Divider/>
                        <Typography>
                            This shift would put you into overtime
                        </Typography>
                    </div> : null}
                </div>}
        </DialogContent>
        {!saving && !deleting ? <DialogActions>
            {exists ?
                <Button color="secondary" size="small" variant="text"
                        onClick={deleteVolunteer}>
                    Withdraw
                </Button> :
                <Button color="primary" size="small"
                        disabled={wouldGoIntoOvertime}
                        onClick={submitForm}>
                    <VolunteerIcon/> Volunteer
                </Button>}
        </DialogActions> : null}
    </Dialog>
};

export default OpenShiftDialog;
