import React from 'react';
import {Typography} from "@mui/material";
import {EmojiPeople as VolunteerIcon, SwapHoriz as TradeIcon} from "@mui/icons-material";
import {UserAvatarGroup} from "@atttomyx/react-components";
import ShiftEventTimeRange from "../timeRange/shiftEventTimeRange";
import {arrays, datetime} from "@atttomyx/shared-utils";
import {VIEW_DAY_WEEK} from "../../../constants";
import "./detailedShiftEvent.css";

const DetailedShiftEvent = (props) => {
    const { user: me, wide, event, filter } = props;

    const customFields = event.extendedProps;
    const shift = customFields.shift;
    const attribute = customFields.attribute;
    const color = customFields.color;
    const users = customFields.users || [];
    const user = customFields.user;
    const view = customFields.view;
    const trade = customFields.trade;
    const volunteer = customFields.volunteer;
    const need = shift.openings - users.length;
    const past = datetime.isPast(shift.stop);
    const descriptionLines = arrays.splitOnNewline(shift.description);
    let counter = 1;

    return <div className="detailed-shift-event">
        {view === VIEW_DAY_WEEK ?
            <ShiftEventTimeRange event={event}
                                 wide={wide}/> : null}
        {!filter ?
            <Typography variant="caption" component="div">
                {attribute}
            </Typography> : null}
        {descriptionLines.map(descriptionLine =>
            <Typography key={"line_" + counter++} variant="caption" component="div">
                {descriptionLine}
            </Typography>)}
        {me.roles.admin && users.length > 0 ?
            <UserAvatarGroup
                max={5}
                users={users}
                mode="vertical"
                color={color}
            /> : null}
        {!me.roles.admin && trade ?
            trade.requestedBy === me.id ?
                <Typography variant="caption" component="div">
                    <TradeIcon/>
                </Typography> : arrays.contains(trade.volunteerIds, me.id) ?
                    <Typography variant="caption" component="div">
                        <VolunteerIcon/>
                    </Typography> : user ?
                    <div key={user.id}>
                        <Typography variant="caption" component="div">
                            Fill-in for:
                        </Typography>
                        <UserAvatarGroup
                            users={[user]}
                            mode="vertical"
                        />
                    </div> : null
            : null}
        {!past && me.roles.admin && need > 0 ?
            <Typography variant="caption" component="div">
                Need: {need}
            </Typography> : null}
        {!past && !me.roles.admin && !user && !trade && !arrays.contains(shift.userIds, me.id) ?
            <Typography variant="caption" component="div">
                {volunteer ? <VolunteerIcon/> : "Open"}
            </Typography> : null}
    </div>
}

export default DetailedShiftEvent;
