import axios from 'axios';
import {sanitizeAttendance} from "../utils/attendances";

export const listAttendances = (cursor, limit, success, failure) => {
    let url = "/api/v1/attendance/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        json.attendances.forEach(sanitizeAttendance);

        success(json);
    })
    .catch(failure);
};

export const mineAttendances = (cursor, limit, success, failure) => {
    let url = "/api/v1/attendance/mine?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        json.attendances.forEach(sanitizeAttendance);

        success(json);
    })
    .catch(failure);
};

export const saveAttendance = (attendance, success, failure) => {
    const url = "/api/v1/attendance/";

    axios.post(url, {
        date: attendance.id,
        cameIns: attendance.cameIns,
        noShows: attendance.noShows,
    })
    .then(response => {
        const json = response.data;

        sanitizeAttendance(json);
        success(json);
    })
    .catch(failure);
};
