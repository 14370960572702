import React, {Component} from 'react';
import {Typography} from "@mui/material";

class DisabledPage extends Component {

    render() {
        return <div className="disabled-page">
            <Typography variant="h5" paragraph={true}>
                Billing required
            </Typography>
            <Typography>
                Please contact your admin
            </Typography>
        </div>
    }
}

export default DisabledPage;
