import React from "react";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {Check as AssignedIcon} from "@mui/icons-material";
import {DateTimeRange} from "@atttomyx/react-components";
import {arrays} from "@atttomyx/shared-utils";
import {sortByStartAndStop} from "../../utils/sorting";
import {toCompositeId} from "../../utils/shifts";

const ReportShiftsDetails = (props) => {
    const {user, shifts, wide} = props;

    return <Table size="small" className="volunteer-details striped dates">
        <TableBody>
            {shifts.sort(sortByStartAndStop).map(shift =>
                <TableRow key={toCompositeId(shift.key)}>
                    <TableCell>
                        {arrays.contains(shift.userIds, user.id) ? <AssignedIcon/> : null}
                    </TableCell>
                    <TableCell>
                        <DateTimeRange start={shift.start} stop={shift.stop} wide={wide}/>
                    </TableCell>
                </TableRow>)}
        </TableBody>
    </Table>
};

export default ReportShiftsDetails;
