import React from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import {renderPattern} from "../../utils/tracks";
import {COLORS_TRACKS} from "../../constants";
import "./trackCard.css";

const TrackCard = (props) => {
    const { track, children } = props;
    const pattern = renderPattern(track);
    let p = 0;

    return <Card className="track-card" raised={true}>
        <CardContent>
            <Typography variant="h4" paragraph={true}>
                {track.title}
            </Typography>
            <Typography className="color" style={{
                backgroundColor: track.color || COLORS_TRACKS[0],
                marginBottom: "20px",
            }}>&nbsp;</Typography>
            {pattern.map(phase => <Typography key={"phase_" + p++}>
                {phase}
            </Typography>)}
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
};

export default TrackCard;
