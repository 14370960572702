import {toCompositeId} from "./shifts";

export const sanitizeAttendance = (attendance) => {
    attendance.id = attendance.date;
    delete attendance.date;
};

export const getCompositeKey = (attendance) => {
    const shiftKey = toCompositeId(attendance.shiftKey);

    return toCompositeKey(attendance.userId, shiftKey)
};

export const toCompositeKey = (userId, shiftKey) => {
    return `${userId}:${shiftKey}`;
};

export const fromCompositeKey = (compositeKey) => {
    const index = compositeKey.indexOf(":");

    return {
        userId: compositeKey.substring(0, index),
        shiftKey: compositeKey.substring(index + 1, compositeKey.length),
    };
};
