import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableRow, Typography,} from "@mui/material";
import {Info} from "@atttomyx/react-components";
import ReportAttendanceDialog from "../../dialogs/reportAttendanceDialog/reportAttendanceDialog";
import {arrays} from "@atttomyx/shared-utils";
import {TYPE_CAME_IN, TYPE_NO_SHOW} from "../../constants";
import "./myAttendancePage.css";

const MyAttendancePage = (props) => {
    const { user, attendances } = props;
    const [ type, setType ] = useState(null);
    const [ cameIns, setCameIns ] = useState([]);
    const [ noShows, setNoShows ] = useState([]);

    useEffect(() => {
        const cameIn = [];
        const noShow = [];

        attendances.forEach(attendance => {
            const date = attendance.id;

            if (attendance.cameIn) {
                arrays.addTo(cameIn, date);
            }

            if (attendance.noShow) {
                arrays.addTo(noShow, date);
            }
        });

        setCameIns(cameIn);
        setNoShows(noShow);
    }, [attendances]);

    return <div className="my-attendance-page">
        <Typography variant="h5" paragraph={true}>
            My Attendance
        </Typography>
        <Table size="small" cellPadding={0} cellSpacing={0} className="results striped">
            <TableBody>
                <TableRow className="result"
                          onClick={() => setType(TYPE_CAME_IN)}>
                    <TableCell>
                        Came In
                    </TableCell>
                    <TableCell>
                        {cameIns.length}
                    </TableCell>
                </TableRow>
                <TableRow className="result"
                          onClick={() => setType(TYPE_NO_SHOW)}>
                    <TableCell>
                        No Show
                    </TableCell>
                    <TableCell>
                        {noShows.length}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <Info>
            Click on a row to see the actual days
        </Info>
        {type ? <ReportAttendanceDialog
            user={user}
            dates={type === TYPE_NO_SHOW ? noShows : cameIns}
            type={type}
            onCancel={() => setType(null)}
        /> : null}
    </div>
};

export default MyAttendancePage;
