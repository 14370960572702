import React, {Component} from 'react';
import {Typography} from "@mui/material";
import {DateTimeRange} from "@atttomyx/react-components";
import {arrays} from "@atttomyx/shared-utils";
import "./shiftInfo.css";

class ShiftInfo extends Component {

    render() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const shift = customFields.shift;
        const attribute = customFields.attribute;
        const descriptionLines = arrays.splitOnNewline(shift.description);
        let counter = 1;

        return <div className="shift-info">
            <Typography component="div">
                {attribute}
            </Typography>
            <div className="info">
                <DateTimeRange start={shift.start} stop={shift.stop}/>
            </div>
            {descriptionLines.length > 0 ?
                <div className="description">
                    {descriptionLines.map(descriptionLine =>
                        <Typography key={"line_" + counter++} variant="caption" component="div">
                            {descriptionLine}
                        </Typography>)}
                </div> : null}
        </div>
    }
}

export default ShiftInfo;
