import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import ColorPicker from "../../fields/colorPicker/colorPicker";
import * as attributeService from "../../services/attributes";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {COLORS_ATTRIBUTES, COLORS_TRACKS} from "../../constants";

const AttributeDialog = (props) => {
    const { attribute, onCancel, onSaved } = props;
    const [ title, setTitle ] = useState(attribute.title);
    const [ color, setColor ] = useState(objects.defaultIfNullOrUndefined(attribute.color, COLORS_TRACKS[0]));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        const attributeId = attribute.id;

        setSaving(true);

        const data = {
            title: title,
            color: color,
        };

        const success = (attribute) => {
            setSaving(false);
            onSaved(attribute);
        };

        const failure = (err) => alert("Unable to save qualification");

        if (attributeId) {
            attributeService.saveAttribute(attributeId, data, success, failure);

        } else {
            attributeService.createAttribute(data, success, failure);
        }
    };

    return <Dialog
        open={true}
        aria-labelledby="attribute-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Qualification
        </ClosableDialogTitle>
        <DialogContent className="attribute-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <div>
                    <div className="field">
                        <TextField label="Title" required={true}
                                   type="text"
                                   value={forms.sanitizeValue(title)}
                                   onChange={(event) => setTitle(event.target.value)}
                        />
                    </div>
                    <div className="field">
                        <ColorPicker
                            colors={COLORS_ATTRIBUTES}
                            value={color}
                            onChange={setColor}
                        />
                    </div>
                </div>}
        </DialogContent>
        <DialogActions>
            <Button color="primary"
                    disabled={strings.isBlank(title)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions>
    </Dialog>
};

export default AttributeDialog;
