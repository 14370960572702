import React, {Component} from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import {timezones} from "@atttomyx/react-utils";

class TimeSlotCard extends Component {

    render() {
        const timeSlot = this.props.timeSlot;
        const timeZone1 = timezones.getTimeZone();
        const timeZone2 = timeSlot.timeZone;
        const differ = timezones.offsetsDiffer(timeZone1, timeZone2);

        return <Card className="attribute-card" raised={true}>
            <CardContent>
                <Typography variant="h4" paragraph={true}>
                    {timeSlot.title}
                </Typography>
                {differ ?
                    <>
                        <Typography variant="body1">
                            {timezones.renderTimeRange(
                                timezones.convertTime(timeSlot.start, timeZone1, timeZone2),
                                timezones.convertTime(timeSlot.stop, timeZone1, timeZone2),
                                timeZone2)}
                        </Typography>
                        <Typography variant="body2">
                            ({timezones.renderTimeRange(timeSlot.start, timeSlot.stop, timeZone1)})
                        </Typography>
                    </> :
                    <Typography variant="body1">
                        {timezones.renderTimeRange(timeSlot.start, timeSlot.stop, timeZone1)}
                    </Typography>}
            </CardContent>
            <CardActions disableSpacing={true}>
                {this.props.children}
            </CardActions>
        </Card>
    }
}

export default TimeSlotCard;
