import {mobile} from "@atttomyx/react-utils";
import {PAGE_NEW_USER, PATH_DEEP_LINK, WEB_BASE_URL} from "../constants";

export const shareJoinUrl = (account) => {
    const code = account.code;
    const joinUrl = `${WEB_BASE_URL}${PATH_DEEP_LINK}${PAGE_NEW_USER}?code=${code}`;

    console.log(joinUrl);

    mobile.popShare("Join Account",
        "Click to join " + account.name + ". You will need to enter this Join Code: " + code,
        joinUrl);
};

export const parseRelativeUrl = (url) => {
    const index = url ? url.indexOf(PATH_DEEP_LINK) : -1;

    return index > -1 ? url.substring(index) : null;
};
