import axios from 'axios';
import {notifications} from "@atttomyx/shared-utils";

export const listNotifications = (cursor, limit, success, failure) => {
    let url = "/api/v1/notification/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        json.notifications.forEach(notifications.sanitizeNotification);

        success(json);
    })
    .catch(failure);
};

export const markAsRead = (notificationIds, success, failure) => {
    const url = "/api/v1/notification/markAsRead";

    axios.post(url, {
        ids: notificationIds,
    })
    .then(response => {
        success(notificationIds);
    })
    .catch(failure);
};

export const deleteNotifications = (notificationIds, success, failure) => {
    const url = "/api/v1/notification/bulkDelete";

    axios.post(url, {
        ids: notificationIds,
    })
    .then(response => {
        success(notificationIds);
    })
    .catch(failure);
};

export const loadPreferences = (success, failure) => {
    axios.get("/api/v1/notification/preferences")
    .then(response => {
        const json = response.data;

        json.deliveries = notifications.normalizeDeliveries(json.deliveries);
        json.topics = notifications.normalizeTopics(json.topics);

        success(json);
    })
    .catch(failure);
};

export const savePreferences = (preferences, success, failure) => {
    const url = "/api/v1/notification/preferences";

    axios.put(url, {
        messagingToken: preferences.messagingToken,
        deliveries: notifications.standardizeDeliveries(preferences.deliveries),
        topics: notifications.standardizeTopics(preferences.topics),
    })
    .then(response => {
        const json = response.data;

        json.deliveries = notifications.normalizeDeliveries(json.deliveries);
        json.topics = notifications.normalizeTopics(json.topics);

        success(json);
    })
    .catch(failure);
};
