import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {ThumbDown as DeniedIcon, ThumbUp as ApprovedIcon} from "@mui/icons-material";
import {UserAvatar} from "@atttomyx/react-components";
import {VIEW_DAY_WEEK} from "../../constants";
import "./timeOffEvent.css";

class TimeOffEvent extends Component {

    renderApprovedOrDenied() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;

        return <Table size="small" padding="none">
            <TableBody>
                <TableRow>
                    <TableCell className="thumb">
                        {timeOff.approvedBy ? <ApprovedIcon fontSize="small"/> : null}
                        {timeOff.deniedBy ? <DeniedIcon fontSize="small"/> : null}
                    </TableCell>
                    <TableCell>
                        {this.renderContent()}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }

    renderContent() {
        const me = this.props.user;
        const event = this.props.event;
        const customFields = event.extendedProps;
        const view = customFields.view;
        const timeOff = customFields.timeOff;
        const user = customFields.user;

        return <div className="user">
            <UserAvatar user={user} mode="right" size="small"/>
            {me.roles.admin && view === VIEW_DAY_WEEK ? <Typography variant="caption" component="div">
                {timeOff.reason ? timeOff.reason : "time off"}
            </Typography> : null}
        </div>
    }

    render() {
        const event = this.props.event;
        const customFields = event.extendedProps;
        const timeOff = customFields.timeOff;
        const approvedOrDenied = timeOff.approvedBy || timeOff.deniedBy;

        return <div className="time-off-event">
            {approvedOrDenied ? this.renderApprovedOrDenied() : this.renderContent()}
        </div>
    }
}

export default TimeOffEvent;
