import {arrays, strings} from "@atttomyx/shared-utils";
import {INTRO_ACCOUNT, INTRO_ADD_USERS, INTRO_POST_SCHEDULE, INTRO_PROFILE} from "../constants";

export const getUserItems = (account, user) => {
    const items = [];

    arrays.addTo(items, {
        key: INTRO_PROFILE,
        done: strings.isNotBlank(user.imageUrl),
        title: "Upload a profile picture",
        gotoUrl: "/profile",
        videoId: "vrU6Kq0xsVM",
    });

    arrays.addTo(items, {
        key: INTRO_POST_SCHEDULE,
        done: account.settings.showScheduleUntil !== null,
        title: "Schedule posted by the admin",
        gotoUrl: null,
        videoId: null,
    });

    return items;
};

export const getAdminItems = (account, user, users) => {
    return [{
        key: INTRO_ACCOUNT,
        done: strings.isNotBlank(account.branding.logoUrl),
        title: "Upload an account logo",
        gotoUrl: "/myAccount",
        videoId: "37O7hG1smGA",
    }, {
        key: INTRO_PROFILE,
        done: strings.isNotBlank(user.imageUrl),
        title: "Upload a profile picture",
        gotoUrl: "/profile",
        videoId: "2Gw_M7urBe8",
    }, {
        key: INTRO_ADD_USERS,
        done: users.length > 1,
        title: "Add a user",
        gotoUrl: "/users",
        videoId: "DoXuHk07w0U",
    }, {
        key: INTRO_POST_SCHEDULE,
        done: account.settings.showScheduleUntil !== null,
        title: "Post the schedule",
        gotoUrl: "/schedule",
        videoId: "Z_NA4nzNqRU",
    }]
};

export const isTodoItem = (item) => {
    return item.done !== true;
};
