import React from "react";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {datetime, sorting} from "@atttomyx/shared-utils";

const ReportAttendanceDetails = (props) => {
    const {dates} = props;

    return <Table size="small" className="report-attendance-details striped dates">
        <TableBody>
            {dates.sort(sorting.alphabetizeDesc).map(date =>
                <TableRow key={date}>
                    <TableCell>{datetime.getPrettyDate(date)}</TableCell>
                </TableRow>)}
        </TableBody>
    </Table>
};

export default ReportAttendanceDetails;
