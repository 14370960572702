import axios from 'axios';

export const listTimeSlots = (cursor, limit, success, failure) => {
    let url = "/api/v1/timeSlot/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const createTimeSlot = (timeSlot, success, failure) => {
    const url = "/api/v1/timeSlot/";

    axios.post(url, {
        title: timeSlot.title,
        start: timeSlot.start,
        stop: timeSlot.stop,
        timeZone: timeSlot.timeZone,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const saveTimeSlot = (timeSlotId, timeSlot, success, failure) => {
    const url = "/api/v1/timeSlot/" + timeSlotId + "/";

    axios.put(url, {
        title: timeSlot.title,
        start: timeSlot.start,
        stop: timeSlot.stop,
        timeZone: timeSlot.timeZone,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const deleteTimeSlot = (timeSlotId, success, failure) => {
    const url = "/api/v1/timeSlot/" + timeSlotId + "/";

    axios.delete(url)
    .then(response => {
        success(timeSlotId);
    })
    .catch(failure);
};
