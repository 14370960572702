import React, {Component} from 'react';
import {Prompt, withRouter} from "react-router-dom";
import {Button, CircularProgress, Typography} from '@mui/material';
import TimeOffForm from "../../forms/timeOffForm/timeOffForm";
import * as timeOffService from "../../services/timeOffs";
import {strings} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {verbiage} from "@atttomyx/shared-constants";
import {PAGE_TIME_OFF} from "../../constants";

class EditTimeOffPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timeOff: {},
            data: {},
            loading: true,
            saving: false,
        };
    }

    componentDidMount() {
        const user = this.props.user;
        const timeOffCache = this.props.timeOffCache;
        const timeOffId = this.props.match.params.id;

        const timeOff = timeOffCache.findEntity(timeOffId);

        if (timeOff && (user.roles.admin || timeOff.requestedBy === user.id)) {
            this.timeOffLoaded(timeOff);

        } else {
            this.props.snackbar.setError("Time Off not found");
            this.props.history.goBack();
        }
    }

    timeOffLoaded(timeOff) {
        this.setState({
            timeOff: timeOff,
            data: {
                requestedBy: timeOff.requestedBy,
                start: timeOff.start,
                stop: timeOff.stop,
                reason: timeOff.reason,
                notes: timeOff.notes,
                approved: strings.isBlank(timeOff.deniedBy),
                valid: true,
            },
            loading: false,
        });
    }

    setData(data) {
        this.setState({
            data: data,
        });
    }

    resetForm() {
        const timeOff = this.state.timeOff;

        this.timeOffLoaded(timeOff);
    }

    submitForm() {
        const user = this.props.user;
        const timeOffId = this.props.match.params.id;
        const data = this.state.data;

        this.setState({
            saving: true,
        });

        const timeOff = {
            start: data.start,
            stop: data.stop,
            reason: data.reason,
        };

        if (user.roles.admin) {
            timeOff.requestedBy = data.requestedBy;
            timeOff.approved = data.approved;
            timeOff.notes = data.notes;

            timeOffService.updateTimeOff(timeOffId, timeOff, this.timeOffSaved.bind(this));

        } else {
            timeOffService.updateRequest(timeOffId, timeOff, this.timeOffSaved.bind(this));
        }
    }

    timeOffSaved(timeOff) {
        const history = this.props.history;
        const timeOffCache = this.props.timeOffCache;

        timeOffCache.onEntitySaved(timeOff);

        this.setState({
            timeOff: timeOff,
            saving: false,
        });

        router.redirectTo(history, PAGE_TIME_OFF);
    }

    timeOffChanged() {
        const timeOff = this.state.timeOff;
        const data = this.state.data;
        const wasApproved = strings.isBlank(timeOff.deniedBy);

        return strings.differ(data.requestedBy, timeOff.requestedBy)
            || strings.differ(data.start, timeOff.start)
            || strings.differ(data.stop, timeOff.stop)
            || strings.differ(data.reason, timeOff.reason)
            || (data.approved && !wasApproved)
            || (!data.approved && wasApproved)
            || strings.differ(data.notes, timeOff.notes);
    }

    approvedChanged() {
        const user = this.props.user;
        const timeOff = this.state.timeOff;
        const data = this.state.data;

        return user.roles.admin &&
            ((data.approved && strings.isBlank(timeOff.approvedBy)) || (!data.approved && strings.isBlank(timeOff.deniedBy)));
    }

    render() {
        const user = this.props.user;
        const users = this.props.users;
        const data = this.state.data;
        const loading = this.state.loading;
        const saving = this.state.saving;
        const modified = this.timeOffChanged();
        const savable = modified || this.approvedChanged();

        return loading || saving ?
            <div className="edit-time-off-page">
                <CircularProgress size="80px"/>
            </div> :
            <div className="edit-time-off-page">
                <Typography variant="h5" paragraph={true}>
                    Edit Time Off
                </Typography>
                <TimeOffForm
                    user={user}
                    users={users}
                    timeOff={data}
                    onChange={this.setData.bind(this)}
                    disableRequestedBy={true}
                />
                <div className="actions">
                    <Button color="secondary" variant="text"
                            disabled={!modified}
                            onClick={this.resetForm.bind(this)}>
                        Undo
                    </Button>
                    <Button color="primary" size="large"
                            disabled={!savable || !data.valid}
                            onClick={this.submitForm.bind(this)}>
                        Save
                    </Button>
                </div>
                <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
            </div>
    }
}

export default withRouter(EditTimeOffPage);
