import React, {useMemo} from "react";
import {SelectField} from "@atttomyx/react-components";
import {objects} from "@atttomyx/shared-utils";
import {ADD_NEW} from "../../constants";

const AttributeField = (props) => {
    const {attributes, attributeIdToTitle, value, onChange, showAddNew, required, disabled, error} = props;

    const options = useMemo(() => {
        const map = attributeIdToTitle ? objects.deepCopy(attributeIdToTitle) : {};

        if (attributes) {
            attributes.forEach(attribute => map[attribute.id] = attribute.title);
        }

        if (showAddNew) {
            map[ADD_NEW] = ADD_NEW;
        }

        return map;
    }, [attributes, attributeIdToTitle]);

    return <div className="attribute-field">
        <SelectField
            label="Qualification"
            emptyLabel="All"
            required={required}
            disabled={disabled}
            value={value}
            error={error}
            onChange={onChange}
            options={options}
        />
    </div>
}

export default AttributeField;
