import {arrays, datetime} from "@atttomyx/shared-utils";

export const renderPattern = (track) => {
    const daysOfWeek = track.daysOfWeek;
    const onAndOff = track.onAndOff;
    const offAndOn = track.offAndOn;
    const compressed = track.compressed;
    const lines = [];

    if (daysOfWeek) {
        daysOfWeek.phases.forEach((phase) => {
            arrays.addTo(lines, datetime.renderDaysOfWeek(phase.days));
        });

    } else if (onAndOff) {
        onAndOff.phases.forEach((phase) => {
            arrays.addTo(lines, renderOnAndOff(phase.on, phase.off));
        });

    } else if (offAndOn) {
        offAndOn.phases.forEach((phase) => {
            arrays.addTo(lines, renderOffAndOn(phase.off, phase.on));
        });

    } else if (compressed) {
        arrays.addTo(lines, datetime.renderDaysOfWeek(compressed.days));
        arrays.addTo(lines, "Hour off: " + datetime.renderDayOfWeek(compressed.hourOff));
        arrays.addTo(lines, "Day off: " + datetime.renderDayOfWeek(compressed.dayOff));
    }

    return lines;
};

const renderOnAndOff = (on, off) => {
    return on + " on, " + off + " off";
};

const renderOffAndOn = (off, on) => {
    return off + " off, " + on + " on";
};

export const validDaysOfWeek = (phases) => {
    return phases.some(phase => phase.days && phase.days.length > 0);
};

export const validOnAndOff = (phase) => {
    return validOn(phase) && validOff(phase);
};

export const validOffAndOn = (phase) => {
    return validOff(phase) && validOn(phase);
};

export const validOn = (phase) => {
    return phase.on && phase.on > 0;
};

export const validOff = (phase) => {
    return phase.off && phase.off > 0;
};

export const validCompressed = (compressed) => {
    const days = compressed.days;

    return validCompressedDaysOfWeek(days)
        && validCompressedDayOfWeek(days, compressed.hourOff)
        && validCompressedDayOfWeek(days, compressed.dayOff);
};

export const validCompressedDaysOfWeek = (days) => {
    return days && days.length === 5;
};

export const validCompressedDayOfWeek = (days, day) => {
    return arrays.contains(days, day);
};

export const standardizePatterns = (track, data) => {
    const daysOfWeek = track.daysOfWeek;
    const onAndOff = track.onAndOff;
    const offAndOn = track.offAndOn;
    const compressed = track.compressed;

    if (daysOfWeek) {
        data.daysOfWeek = {
            phases: standardizeDaysOfWeek(daysOfWeek.phases),
        };

    } else if (onAndOff) {
        data.onAndOff = {
            phases: standardizeOnAndOff(onAndOff.phases),
        };

    } else if (offAndOn) {
        data.offAndOn = {
            phases: standardizeOffAndOn(offAndOn.phases),
        };

    } else if (compressed) {
        data.compressed = {
            days: compressed.days,
            hourOff: compressed.hourOff,
            dayOff: compressed.dayOff,
        };
    }
};

const standardizeDaysOfWeek = (phases) => {
    const standardized = [];

    phases.forEach((phase) => {
        arrays.addTo(standardized, {
            days: phase.days,
        });
    });

    return standardized;
};

const standardizeOnAndOff = (phases) => {
    const standardized = [];

    phases.forEach((phase) => {
        arrays.addTo(standardized, {
            on: phase.on,
            off: phase.off,
        });
    });

    return standardized;
};

const standardizeOffAndOn = (phases) => {
    const standardized = [];

    phases.forEach((phase) => {
        arrays.addTo(standardized, {
            off: phase.off,
            on: phase.on,
        });
    });

    return standardized;
};

export const findTrackId = (recurrence, tracks) => {
    const start = recurrence.start;
    const daysOfWeek = recurrence.daysOfWeek;
    const onAndOff = recurrence.onAndOff;
    const offAndOn = recurrence.offAndOn;
    const compressed = recurrence.compressed;
    let trackId = null;

    if (start && (daysOfWeek || onAndOff || offAndOn || compressed)) {
        tracks.forEach((track) => {
            if (sameAsTrack(track, start, daysOfWeek, onAndOff, compressed)) {
                trackId = track.id;
            }
        });
    }

    return trackId;
};

const sameAsTrack = (track, start, daysOfWeek, onAndOff, offAndOn, compressed) => {
    let ret = false;

    if (track.start === start) {
        if (daysOfWeek && track.daysOfWeek
            && daysOfWeek.phases && track.daysOfWeek.phases
            && daysOfWeek.phases.length === track.daysOfWeek.phases.length) {

            ret = true;

            for (let i = 0; i < daysOfWeek.phases.length; i++) {
                ret = ret && sameDaysOfWeek(daysOfWeek.phases[i], track.daysOfWeek.phases[i]);
            }

        } else if (onAndOff && track.onAndOff
            && onAndOff.phases && track.onAndOff.phases
            && onAndOff.phases.length === track.onAndOff.phases.length) {

            ret = true;

            for (let i = 0; i < onAndOff.phases.length; i++) {
                ret = ret && sameOnAndOff(onAndOff.phases[i], track.onAndOff.phases[i]);
            }

        } else if (offAndOn && track.offAndOn
            && offAndOn.phases && track.offAndOn.phases
            && offAndOn.phases.length === track.offAndOn.phases.length) {

            ret = true;

            for (let i = 0; i < offAndOn.phases.length; i++) {
                ret = ret && sameOffAndOn(offAndOn.phases[i], track.offAndOn.phases[i]);
            }

        } else if (compressed && track.compressed) {
            ret = sameDaysOfWeek(compressed, track.compressed)
                && compressed.hourOff === track.compressed.hourOff
                && compressed.dayOff === track.compressed.dayOff;
        }
    }

    return ret;
};

export const sameAsRecurrence = (recurrence, start, daysOfWeek, onAndOff, offAndOn, compressed) => {
    let ret = false;

    if (recurrence.start === start) {
        if (daysOfWeek && recurrence.daysOfWeek && recurrence.daysOfWeek.phases
            && daysOfWeek.length === recurrence.daysOfWeek.phases.length) {

            ret = true;

            for (let i = 0; i < daysOfWeek.length; i++) {
                ret = ret && sameDaysOfWeek(daysOfWeek[i], recurrence.daysOfWeek.phases[i]);
            }

        } else if (onAndOff && recurrence.onAndOff && recurrence.onAndOff.phases
            && onAndOff.length === recurrence.onAndOff.phases.length) {

            ret = true;

            for (let i = 0; i < onAndOff.length; i++) {
                ret = ret && sameOnAndOff(onAndOff[i], recurrence.onAndOff.phases[i]);
            }

        } else if (offAndOn && recurrence.offAndOn && recurrence.offAndOn.phases
            && offAndOn.length === recurrence.offAndOn.phases.length) {

            ret = true;

            for (let i = 0; i < offAndOn.length; i++) {
                ret = ret && sameOffAndOn(offAndOn[i], recurrence.offAndOn.phases[i]);
            }

        } else if (compressed && recurrence.compressed) {
            ret = sameDaysOfWeek(compressed, recurrence.compressed)
                && compressed.hourOff === recurrence.compressed.hourOff
                && compressed.dayOff === recurrence.compressed.dayOff;
        }
    }

    return ret;
};

const sameDaysOfWeek = (phaseA, phaseB) => {
    return arrays.equalsIgnoreOrder(phaseA.days, phaseB.days);
};

const sameOnAndOff = (phaseA, phaseB) => {
    return phaseA.on === phaseB.on && phaseA.off === phaseB.off;
};

const sameOffAndOn = (phaseA, phaseB) => {
    return phaseA.off === phaseB.off && phaseA.on === phaseB.on;
};
