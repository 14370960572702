import React, {useEffect, useState} from "react";
import {Card, CardContent, Divider, Typography} from "@mui/material";
import {arrays, datetime, formatters} from "@atttomyx/shared-utils";
import {CardData} from "@atttomyx/react-components";
import {renderAttributes} from "../../utils/attributes";

const SelfCard = (props) => {
    const { data, settingsProps } = props;
    const { attributes } = settingsProps;
    const [ attributeIdToTitle, setAttributeIdToTitle ] = useState({});

    useEffect(() => {
        setAttributeIdToTitle(arrays.getIdToField(attributes, "title"));
    }, [attributes]);

    return <Card className="user-self-form" raised={true}>
        <CardContent>
            {data.attributeIds.length > 0 ? <Typography variant="h6">
                Qualifications
            </Typography> : null}
            <Typography variant="body2">
                {renderAttributes(data.attributeIds, attributeIdToTitle)}
            </Typography>
            {data.startDate || data.hourlyRate ?
                <>
                    <Divider/>
                    <CardData
                        filters={{}}
                        data={data}
                        keyToMetadata={{
                            startDate: {
                                title: "Start date",
                                formatter: (val) => val ? datetime.getFullDate(val) : null,
                            },
                            hourlyRate: {
                                title: "Pay rate",
                                formatter: (val) => val ? formatters.usDollars(val) + "/h" : null,
                            },
                        }}
                    />
                </> : null}
        </CardContent>
    </Card>
}

export default SelfCard;
