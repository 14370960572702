import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {useQuery} from "@atttomyx/react-hooks";

const StealthPage = (props) => {
    const { snackbar, authService, onToLogin, onLogin } = props;
    const query = useQuery();

    useEffect(() => {
        const token = query.get("token");

        const failure = (err) => {
            snackbar.setError(err);
            authService.clearAuthToken();
            onToLogin();
        }

        if (token) {
            const success = (user) => {
                if (user.proxy) {
                    onLogin();

                } else {
                    failure("invalid token");
                }
            }

            authService.storeAuthToken(token);
            authService.getLoggedInUser(success, failure);

        } else {
            failure("missing params");
        }
    }, []);

    return <CircularProgress size="80px"/>
}

export default StealthPage;
