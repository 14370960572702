import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Divider, List, ListItem, Typography} from "@mui/material";
import {ContactUs} from "@atttomyx/react-components";
import IntroItem from "../../components/IntroItem/introItem";
import {APP_NAME} from "../../constants";
import "./introPage.css";

class IntroPage extends Component {

    renderAdminWelcome() {
        return <>
            <Typography variant="body1">
                We're here to enable you to spend less time working on the schedule and more time working on your business.
            </Typography>
            <List>
                <ListItem>
                    We will automatically filter unqualified and unavailable users out of lists
                </ListItem>
                <ListItem>
                    We will notify you whenever a user makes a request that needs your attention
                </ListItem>
                <ListItem>
                    You can easily find volunteers to cover unexpected openings
                </ListItem>
            </List>
        </>
    }

    renderUserWelcome() {
        return <>
            <Typography variant="body1">
                We're here to empower you with easy access to your work schedule.
            </Typography>
            <List>
                <ListItem>
                    We will notify you whenever the admin makes a change to your schedule
                </ListItem>
                <ListItem>
                    You can volunteer for open shifts to get more hours
                </ListItem>
                <ListItem>
                    You can trade shifts with other users to better fit your life outside of work
                </ListItem>
            </List>
        </>
    }

    render() {
        const user = this.props.user;
        const checklist = this.props.checklist;

        return <div className="intro-page">
            <Typography variant="h5" paragraph={true}>
                Welcome to {APP_NAME}!
            </Typography>
            {user.roles.admin ? this.renderAdminWelcome() : this.renderUserWelcome()}
            <Typography variant="body1" paragraph={true} className="section">
                Here are a few steps to help you get started:
            </Typography>
            <div className="checklist">
                {checklist.map(item => <IntroItem key={item.key} item={item}/>)}
            </div>
            <Divider/>
            <ContactUs/>
        </div>
    }
}

export default withRouter(IntroPage);
