import React, {Component} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {ClosableDialogTitle, Info} from "@atttomyx/react-components";
import * as shiftService from "../../services/shifts";
import {datetime, forms, strings} from "@atttomyx/shared-utils";
import "./deleteShiftsDialog.css";

class DeleteShiftsDialog extends Component {

    constructor(props) {
        super(props);

        const after = this.props.showScheduleUntil;

        this.state = {
            after: after ? after : datetime.today(),
            deleting: false,
        };
    }

    setAfter(event) {
        const after = event.target.value;

        this.setState({
            after: after,
        });
    }

    submitForm() {
        const after = datetime.previousDay(this.state.after);

        this.setState({
            deleting: true,
        });

        shiftService.deleteShifts(after, this.massDeleteSuccess.bind(this), this.massDeleteFailure.bind(this));
    }

    massDeleteSuccess() {
        this.setState({
            deleting: false,
        });

        this.props.onDelete();
    }

    massDeleteFailure(err) {
        this.setState({
            deleting: false,
        });

        this.props.onDelete();
    }

    render() {
        const showScheduleUntil = this.props.showScheduleUntil;
        const after = this.state.after;
        const deleting = this.state.deleting;

        return <Dialog
            open={true}
            aria-labelledby="delete-shifts-dialog"
        >
            <ClosableDialogTitle onClose={this.props.onCancel}>
                Bulk Delete Shifts
            </ClosableDialogTitle>
            <DialogContent className="delete-shifts-dialog">
                {deleting ?
                    <CircularProgress size="40px" color="secondary"/> :
                        <div className="field">
                            <TextField label="On and after" required={true}
                                       type="date"
                                       value={forms.sanitizeValue(after)}
                                       error={datetime.isBefore(after, showScheduleUntil)}
                                       onChange={this.setAfter.bind(this)}
                            />
                            {showScheduleUntil ? <Info>
                                May only bulk delete shifts that have not been posted.
                                Schedule has been posted until {datetime.getPrettyDate(showScheduleUntil)}.
                            </Info> : null}
                        </div>}
            </DialogContent>
            {!deleting ? <DialogActions>
                <Button color="secondary"
                        disabled={strings.isBlank(after) || datetime.isBefore(after, showScheduleUntil)}
                        onClick={this.submitForm.bind(this)}>
                    Delete
                </Button>
            </DialogActions> : null}
        </Dialog>
    }
}

export default DeleteShiftsDialog;
