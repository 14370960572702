import React, {useState} from "react";
import {Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {arrays, forms, objects} from "@atttomyx/shared-utils";
import AttributeDialog from "../../dialogs/attributeDialog/attributeDialog";

const UserAdminForm = (props) => {
    const { data, onChange : onSync, settingsProps } = props;
    const { attributes, onSave } = settingsProps;
    const [ attribute, setAttribute ] = useState(null);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = true;

        onSync(modified);
    };

    const syncAttributeIds = (attributeId, checked) => {
        const modified = objects.deepCopy(data);
        const attributeIds = data.attributeIds ? arrays.copy(data.attributeIds) : [];

        if (checked && !arrays.contains(attributeIds, attributeId)) {
            arrays.addTo(attributeIds, attributeId);

        } else if (arrays.contains(attributeIds, attributeId)) {
            arrays.removeFrom(attributeIds, attributeId);
        }

        modified.attributeIds = attributeIds;
        modified.valid = true;

        onSync(modified);
    }

    return <div className="user-admin-form">
        <Typography variant="h5">
            General
        </Typography>
        <div className="field">
            <TextField label="Start date"
                       type="date"
                       value={forms.sanitizeValue(data.startDate)}
                       onChange={(event) => onChange("startDate", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Hourly rate"
                       type="number"
                       value={forms.sanitizeValue(data.hourlyRate)}
                       onChange={(event) => onChange("hourlyRate", event.target.value)}
                       inputProps={{
                           min: 0,
                       }}
            />
        </div>
        <div className="field">
            <TextField label="Notes"
                       type="text"
                       multiline={true}
                       value={forms.sanitizeValue(data.notes)}
                       onChange={(event) => onChange("notes", event.target.value)}
            />
        </div>
        <Typography variant="h5" className="section">
            Qualifications
        </Typography>
        <div className="attributes">
            {attributes.map(attribute => <div key={attribute.id} className="option">
                <FormControlLabel labelPlacement="end"
                                  label={attribute.title}
                                  control={<Checkbox color="primary"
                                                     checked={forms.sanitizeOption(arrays.contains(data.attributeIds, attribute.id))}
                                                     onChange={event => syncAttributeIds(attribute.id, event.target.checked)}
                                  />}
                />
            </div>)}
        </div>
        <div className="action">
            <Button color="secondary" variant="text" size="small"
                    onClick={() => setAttribute({})}>
                Add new...
            </Button>

        </div>
        {attribute ? <AttributeDialog
            attribute={attribute}
            onCancel={() => setAttribute(null)}
            onSaved={(attribute) => {
                setAttribute(null);
                onSave(attribute);
                syncAttributeIds(attribute.id, true);
            }}
        /> : null}
    </div>
}

export default UserAdminForm;
