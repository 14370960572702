import React, {Component} from 'react';
import {Prompt, withRouter} from "react-router-dom";
import {Button, CircularProgress, Typography} from '@mui/material';
import ShiftForm from "../../forms/shiftForm/shiftForm";
import * as shiftService from "../../services/shifts";
import {datetime, objects, strings} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {findTimeSlotIdForData, fromCompositeId, getDefaultDate, keyEquals} from "../../utils/shifts";
import {findTrackId, sameAsRecurrence} from "../../utils/tracks";
import {verbiage} from "@atttomyx/shared-constants";
import {
    PAGE_SCHEDULE,
    PATTERN_COMPRESSED,
    PATTERN_DAYS_OF_WEEK,
    PATTERN_OFF_AND_ON,
    PATTERN_ON_AND_OFF,
    RECURRENCE_FUTURE,
    RECURRENCE_INSTANCE
} from "../../constants";

class EditShiftPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shift: {},
            data: {
                startDate: getDefaultDate(this.props.date),
            },
            loadingShift: true,
            savingShift: false,
        };
    }

    componentDidMount() {
        const mode = this.props.match.params.mode;

        if (mode) {
            switch (mode) {
                case RECURRENCE_INSTANCE:
                case RECURRENCE_FUTURE:
                    this.findCachedShift();
                    break;
                default:
                    throw new Error(mode);
            }
        } else {
            this.findCachedShift();
        }
    }

    findCachedShift() {
        const shiftCache = this.props.shiftCache;
        const shiftKey = fromCompositeId(this.props.match.params.id);

        const shift = shiftCache.findByComparator((candidate) => keyEquals(candidate.key, shiftKey));

        if (shift) {
            this.shiftLoaded(shift);

        } else {
            this.props.snackbar.setError("Shift not found");
            this.props.history.goBack();
        }
    }

    shiftLoaded(shift) {
        const timeSlots = this.props.timeSlots;
        const tracks = this.props.tracks;
        const recurrence = shift.recurrence;
        const start = shift.start;
        const stop = shift.stop;
        const startDate = datetime.getDate(start);
        const startTime = datetime.getTime(start);
        const stopDate = recurrence ? recurrence.stop : null;
        const stopTime = datetime.getTime(stop);

        const data = {
            attributeId: shift.attributeId,
            openings: shift.openings,
            start: shift.start,
            stop: shift.stop,
            description: shift.description,
            startDate: startDate,
            startTime: startTime,
            stopDate: stopDate,
            stopTime: stopTime,
            valid: true,
        };

        data.timeSlotId = findTimeSlotIdForData(data, timeSlots);

        if (recurrence) {
            const mode = this.props.match.params.mode;

            if (mode !== RECURRENCE_INSTANCE) {
                const trackId = findTrackId(recurrence, tracks);

                if (trackId) {
                    const daysOfWeek = recurrence.daysOfWeek;
                    const onAndOff = recurrence.onAndOff;
                    const offAndOn = recurrence.offAndOn;
                    const compressed = recurrence.compressed;

                    data.trackId = trackId;
                    data.beginning = recurrence.start;
                    data.stop = stopDate ? stop : datetime.oneHundredYearsFrom(startDate) + "T" + stopTime;

                    if (daysOfWeek) {
                        data.pattern = PATTERN_DAYS_OF_WEEK;
                        data.daysOfWeek = objects.deepCopy(daysOfWeek.phases);
                    }

                    if (onAndOff) {
                        data.pattern = PATTERN_ON_AND_OFF;
                        data.onAndOff = objects.deepCopy(onAndOff.phases);
                    }

                    if (offAndOn) {
                        data.pattern = PATTERN_OFF_AND_ON;
                        data.offAndOn = objects.deepCopy(offAndOn.phases);
                    }

                    if (compressed) {
                        data.pattern = PATTERN_COMPRESSED;
                        data.compressed = objects.deepCopy(compressed);
                    }
                } else {
                    data.valid = false;
                }
            }
        }

        this.setState({
            shift: shift,
            data: data,
            loadingShift: false,
        });
    }

    setData(data) {
        this.setState({
            data: data,
        });
    }

    resetForm() {
        const shift = this.state.shift;

        this.shiftLoaded(shift);
    }

    submitForm() {
        const shiftKey = fromCompositeId(this.props.match.params.id);
        const mode = this.props.match.params.mode;
        const data = this.state.data;

        this.setState({
            savingShift: true,
        });

        const shift = {
            attributeId: data.attributeId,
            openings: data.openings,
            start: data.start,
            stop: data.stop,
            description: data.description,
        };

        if (data.pattern && mode !== RECURRENCE_INSTANCE) {
            shift.recurrence = {
                start: data.beginning,
            };

            if (data.pattern === PATTERN_DAYS_OF_WEEK) {
                shift.recurrence.daysOfWeek = {
                    phases: data.daysOfWeek,
                };

            } else if (data.pattern === PATTERN_ON_AND_OFF) {
                shift.recurrence.onAndOff = {
                    phases: data.onAndOff,
                };

            } else if (data.pattern === PATTERN_OFF_AND_ON) {
                shift.recurrence.offAndOn = {
                    phases: data.offAndOn,
                };

            } else if (data.pattern === PATTERN_COMPRESSED) {
                shift.recurrence.compressed = data.compressed;
            }
        }

        shiftService.saveShift(shiftKey, shift, mode, this.shiftSaved.bind(this));
    }

    shiftSaved(shift) {
        const history = this.props.history;
        const shiftCache = this.props.shiftCache;

        if (shift.recurrence) {
            shiftCache.clear();

        } else {
            shiftCache.onEntitySaved(shift);
        }

        this.props.snackbar.setSuccess("Shift saved");

        this.setState({
            shift: shift,
            savingShift: false,
        });

        router.redirectTo(history, PAGE_SCHEDULE);
    }

    shiftChanged() {
        const mode = this.props.match.params.mode;
        const shift = this.state.shift;
        const data = this.state.data;
        const recurrence = shift.recurrence;
        const daysOfWeek = recurrence ? recurrence.daysOfWeek : null;
        const onAndOff = recurrence ? recurrence.onAndOff : null;
        const offAndOn = recurrence ? recurrence.offAndOn : null;
        const compressed = recurrence ? recurrence.compressed : null;

        return strings.differ(data.attributeId, shift.attributeId)
            || strings.differ(data.start, shift.start)
            || strings.differ(datetime.getTime(data.stop), datetime.getTime(shift.stop))
            || strings.differ(data.description, shift.description)
            || strings.differ(data.openings, shift.openings)
            || (!recurrence && data.pattern)
            || (!daysOfWeek && data.pattern === PATTERN_DAYS_OF_WEEK)
            || (!onAndOff && data.pattern === PATTERN_ON_AND_OFF)
            || (!offAndOn && data.pattern === PATTERN_OFF_AND_ON)
            || (!compressed && data.pattern === PATTERN_COMPRESSED)
            || (recurrence && strings.differ(data.stopDate, recurrence.stop))
            || (mode === RECURRENCE_FUTURE && recurrence && !sameAsRecurrence(recurrence, data.beginning, data.daysOfWeek, data.onAndOff, data.offAndOn, data.compressed));
    }

    render() {
        const mode = this.props.match.params.mode;
        const user = this.props.user;
        const attributes = this.props.attributes;
        const timeSlots = this.props.timeSlots;
        const tracks = this.props.tracks;
        const onSaveAttribute = this.props.onSaveAttribute;
        const onSaveTimeSlot = this.props.onSaveTimeSlot;
        const onSaveTrack = this.props.onSaveTrack;
        const data = this.state.data;
        const loadingShift = this.state.loadingShift;
        const savingShift = this.state.savingShift;
        const modified = this.shiftChanged();

        return loadingShift || savingShift ?
            <div className="edit-shift-page">
                <CircularProgress size="80px"/>
            </div> :
            <div className="edit-shift-page">
                <Typography variant="h5" paragraph={true}>
                    Edit Shift
                </Typography>
                <ShiftForm
                    attributes={attributes}
                    timeSlots={timeSlots}
                    tracks={tracks}
                    data={data}
                    onChange={this.setData.bind(this)}
                    onSaveAttribute={onSaveAttribute}
                    onSaveTimeSlot={onSaveTimeSlot}
                    onSaveTrack={onSaveTrack}
                    allowRecurrence={mode !== RECURRENCE_INSTANCE}
                    requireRecurrence={mode === RECURRENCE_FUTURE}
                    lockStartDate={mode === RECURRENCE_INSTANCE || mode === RECURRENCE_FUTURE}
                    allowPastDate={user.roles.super} // todo: maybe make a user/app setting for "allow past" instead of checking super
                />
                <div className="actions">
                    <Button color="secondary" variant="text"
                            disabled={!modified}
                            onClick={this.resetForm.bind(this)}>
                        Undo
                    </Button>
                    <Button color="primary" size="large"
                            disabled={!modified || !data.valid}
                            onClick={this.submitForm.bind(this)}>
                        Save
                    </Button>
                </div>
                <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
            </div>
    }
}

export default withRouter(EditShiftPage);
