import axios from 'axios';
import {sanitizeShift} from "../utils/shifts";
import {datetime} from "@atttomyx/shared-utils";
import {standardizePatterns} from "../utils/tracks";
import {RECURRENCE_FUTURE, RECURRENCE_INSTANCE} from "../constants";

export const findShifts = (start, stop, success, failure) => {
    const url = "/api/v1/shift/list?start=" + start + "&stop=" + stop;

    axios.get(url)
    .then(response => {
        const json = response.data;
        const shifts = json.shifts;

        shifts.forEach(sanitizeShift);

        success(shifts);
    })
    .catch(failure);
};

export const createShift = (shift, success, failure) => {
    const url = "/api/v1/shift/";
    const body = toData(shift);

    body.userIds = shift.userIds;

    axios.post(url, body)
    .then(response => {
        const json = response.data;

        sanitizeShift(json);
        success(json);
    })
    .catch(failure);
};

const toData = (shift) => {
    const recurrence = shift.recurrence;

    const data = {
        description: shift.description,
        start: datetime.sanitizeDateTime(shift.start),
        stop: datetime.sanitizeDateTime(shift.stop),
        attributeId: shift.attributeId,
        openings: shift.openings,
    };

    if (recurrence) {
        data.recurrence = {
            start: datetime.sanitizeDate(recurrence.start),
        };

        standardizePatterns(recurrence, data.recurrence);
    }

    return data;
};

export const saveShift = (shiftKey, shift, mode, success, failure) => {
    const instanceId = shiftKey.instanceId;
    let url = "/api/v1/shift/" + shiftKey.shiftId + "/";

    if (mode === RECURRENCE_INSTANCE || mode === RECURRENCE_FUTURE || instanceId) {
        const action = mode === RECURRENCE_FUTURE ? "modifyFuture" : "modifyInstance";

        url += shiftKey.instanceId + "/" + action;

    } else {
        url += "modifyShift";
    }

    axios.put(url, toData(shift))
    .then(response => {
        const json = response.data;

        sanitizeShift(json);
        success(json);
    })
    .catch(failure);
};

export const assignShift = (shiftKey, openings, userIds, mode, success, failure) => {
    const instanceId = shiftKey.instanceId;
    let url = "/api/v1/shift/" + shiftKey.shiftId + "/";

    if (mode === RECURRENCE_INSTANCE || mode === RECURRENCE_FUTURE || instanceId) {
        const action = mode === RECURRENCE_FUTURE ? "assignFuture" : "assignInstance";

        url += shiftKey.instanceId + "/" + action;

    } else {
        url += "assignShift";
    }

    axios.put(url, {
        openings: openings,
        userIds: userIds,
    })
    .then(response => {
        const json = response.data;

        sanitizeShift(json);
        success(json);
    })
    .catch(failure);
};

export const deleteShift = (shiftKey, mode, success, failure) => {
    const instanceId = shiftKey.instanceId;
    let url = "/api/v1/shift/" + shiftKey.shiftId + "/";

    if (mode === RECURRENCE_INSTANCE || mode === RECURRENCE_FUTURE || instanceId) {
        const action = mode === RECURRENCE_FUTURE ? "future" : "instance";

        url += shiftKey.instanceId + "/" + action;

    } else {
        url += "shift";
    }

    axios.delete(url)
    .then(response => {
        success(shiftKey);
    })
    .catch(failure);
};

export const deleteShifts = (after, success, failure) => {
    const url = "/api/v1/shift/bulk?after=" + after;

    axios.delete(url)
    .then(response => {
        success();
    })
    .catch(failure);
};
