import React from "react";
import {MultiSelectField, SelectField} from "@atttomyx/react-components";
import {date} from "@atttomyx/shared-constants";

const OPTIONS = {
    [date.SUNDAY]: "Sun",
    [date.MONDAY]: "Mon",
    [date.TUESDAY]: "Tue",
    [date.WEDNESDAY]: "Wed",
    [date.THURSDAY]: "Thu",
    [date.FRIDAY]: "Fri",
    [date.SATURDAY]: "Sat",
};

const DayOfWeekField = (props) => {
    const {label, required, disabled, value, onChange, multiple, error} = props;

    return <div className="day-of-week-field">
        {multiple ?
            <MultiSelectField
                label={label}
                required={required}
                disabled={disabled}
                value={value}
                error={error}
                onChange={onChange}
                options={OPTIONS}
            /> :
            <SelectField
                label={label}
                required={required}
                disabled={disabled}
                value={value}
                error={error}
                onChange={onChange}
                options={OPTIONS}
            />}
    </div>
}

export default DayOfWeekField;
