import {arrays, sorting, strings} from "@atttomyx/shared-utils";
import {misc} from "@atttomyx/shared-constants";

export const renderAttributes = (attributeIds, attributeIdToTitle) => {
    let rendered = attributeIds.length === 0 ? "No qualifications" : "";

    if (attributeIds.length > 0) {
        const titles = [];

        attributeIds.forEach((attributeId) => {
            arrays.addTo(titles, renderAttribute(attributeId, attributeIdToTitle));
        });

        rendered = strings.commaSeparated(titles.sort(sorting.alphabetize));
    }

    return rendered;
};

export const renderAttribute = (attributeId, attributeIdToTitle) => {
    return attributeId === misc.ALL ? "all" : attributeIdToTitle[attributeId] || misc.UNKNOWN;
};
