import axios from 'axios';
import {storage} from "@atttomyx/react-utils";
import {KEY_VERSION} from "../constants";

const local = storage.getLocal();
const session = storage.getSession();

const getLatestVersion = (success, failure) => {
    axios.get("/api/v1/app/version")
    .then(response => {
        const json = response.data;
        const version = json.major + "." + json.minor + "." + json.patch;

        success(version);
    })
    .catch(failure);
};

export const ensureOnLatestVersion = (bypassCache, callback) => {
    if (bypassCache || !session.getStr(KEY_VERSION)) {
        session.setStr(KEY_VERSION, "loading");

        getLatestVersion(latest => {
            const version = getVersion();

            console.log("current version: " + version);
            console.log("latest version: " + latest);

            if (version !== latest) {
                console.log("reloading...");

                local.setStr(KEY_VERSION, latest);

                window.location.reload(true);

            } else if (callback) {
                callback(latest);
            }
        });
    } else if (callback) {
        callback(getVersion());
    }
};

export const getVersion = () => {
    return local.getStr(KEY_VERSION);
};
