import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {IconButton} from "@mui/material";
import {Forward as GotoIcon} from "@mui/icons-material";
import CheckboxIcon from "../checkboxIcon/checkboxIcon";
import {router} from "@atttomyx/react-utils";
import "./introItem.css";

class IntroItem extends Component {

    render() {
        const history = this.props.history;
        const item = this.props.item;

        return <div className="intro-item">
            <div className="left">
                <CheckboxIcon done={item.done}>
                    {item.title}
                </CheckboxIcon>
            </div>
            <div className="right">
                {item.gotoUrl ?
                    <IconButton title="Goto" edge="end"
                                onClick={() => router.redirectTo(history, item.gotoUrl)}>
                        <GotoIcon color="primary"/>
                    </IconButton> : null}
            </div>
        </div>
    }
}

export default withRouter(IntroItem);
