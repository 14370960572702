import {timezones} from "@atttomyx/react-utils";

export const renderTimeSlot = (timeSlot) => {
    const timeZone1 = timezones.getTimeZone();
    const timeZone2 = timeSlot.timeZone;
    const differ = timezones.offsetsDiffer(timeZone1, timeZone2);
    const suffix = differ
        ? timezones.renderTimeRange(timezones.convertTime(timeSlot.start, timeZone1, timeZone2), timezones.convertTime(timeSlot.stop, timeZone1, timeZone2), timeZone2)
        : timezones.renderTimeRange(timeSlot.start, timeSlot.stop, timeZone1);

    return `${timeSlot.title} (${suffix})`;
};
