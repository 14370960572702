import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {CheckBox as DoneIcon, CheckBoxOutlineBlank as TodoIcon} from "@mui/icons-material";
import "./checkboxIcon.css";

class CheckboxIcon extends Component {

    render() {
        const done = this.props.done;

        return <Table size="small" padding="none" className="checkbox-icon">
            <TableBody>
                <TableRow>
                    <TableCell className="checkbox">
                        {done ? <DoneIcon/> : <TodoIcon/>}
                    </TableCell>
                    <TableCell>
                        {this.props.children}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }
}

export default CheckboxIcon;
