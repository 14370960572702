import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {Mutex} from 'async-mutex';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import {CircularProgress, Fab, Typography} from "@mui/material";
import {
    Add as AddIcon,
    AssignmentTurnedIn as PostScheduleIcon,
    Delete as DeleteIcon,
    FilterList as FilterIcon
} from "@mui/icons-material";
import {useQuery} from "@atttomyx/react-hooks";
import {FiltersDialog, FloatingButtons} from "@atttomyx/react-components";
import ScheduleFilters from "../../filters/scheduleFilters/scheduleFilters";
import DetailedShiftEvent from "../../components/shiftEvent/detailed/detailedShiftEvent";
import SummaryShiftEvent from "../../components/shiftEvent/summary/summaryShiftEvent";
import DeleteShiftsDialog from "../../dialogs/deleteShiftsDialog/deleteShiftsDialog";
import AdminShiftDialog from "../../dialogs/shiftDialog/admin/adminShiftDialog";
import MineShiftDialog from "../../dialogs/shiftDialog/mine/mineShiftDialog";
import OpenShiftDialog from "../../dialogs/shiftDialog/open/openShiftDialog";
import TradeShiftDialog from "../../dialogs/shiftDialog/trade/tradeShiftDialog";
import PastShiftDialog from "../../dialogs/shiftDialog/past/pastShiftDialog";
import PostScheduleDialog from "../../dialogs/postScheduleDialog/postScheduleDialog";
import {getContentHeight, getDayCellClassNames, shiftsToEvents} from "../../utils/fullCalendar";
import {fromCompositeId, keyEquals, toCompositeId} from "../../utils/shifts";
import {getGoBackTo} from "../../utils/users";
import {arrays, datetime, strings} from "@atttomyx/shared-utils";
import {router} from "@atttomyx/react-utils";
import {PAGE_SHIFT, VIEW_DAY_MONTH, VIEW_DAY_WEEK, VIEW_TIME_DAY} from "../../constants";
import "./schedulePage.css";

const eventsMutex = new Mutex();
const datesMutex = new Mutex();

const SchedulePage = (props) => {
    const {snackbar, history, dimensions, account, user : me, users, deletedUsers,
        attributes, tracks, volunteers, trades, shiftCache, timeOffCache, filters,
        date, setDate, view, setView,
        onSaveVolunteer, onDeleteVolunteer, onSaveTrade, onDeleteTrade, onSaveMyAccount} = props;
    const [range, setRange] = useState({});
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);
    const [popShiftId, setPopShiftId] = useState(null);
    const [showPostSchedule, setShowPostSchedule] = useState(false);
    const [showMassDelete, setShowMassDelete] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const query = useQuery();

    useEffect(() => {
        const dateParam = query.get("date");
        const shiftId = query.get("shiftId");

        if (dateParam) {
            setDate(dateParam);
        }

        if (shiftId) {
            setPopShiftId(shiftId);
        }
    }, []);

    useEffect(() => {
        eventsMutex.acquire()
            .then(function(release) {
                try {
                    const events = shiftsToEvents(account, me, shiftCache.entities, timeOffCache.entities,
                        users, deletedUsers, attributes, tracks, volunteers, trades, filters.data, view);

                    if (popShiftId) {
                        const shiftKey = fromCompositeId(popShiftId);

                        events.forEach((candidate) => {
                            if (!event) {
                                const customFields = candidate.extendedProps;
                                const shift = customFields.shift;
                                const key = shift.key;

                                if (keyEquals(key, shiftKey)) {
                                    setEvent(candidate);
                                    setPopShiftId(null);
                                }
                            }
                        });
                    }

                    setEvents(events);
                } finally {
                    release();
                }
            }.bind(this));
    }, [shiftCache.entities, timeOffCache.entities, range, filters.data, view,
        account, me, users, attributes, volunteers, trades]);

    const dateRangeChanged = (info) => {
        const view = info.view;
        const start = datetime.getDate(view.activeStart.toISOString());
        const stop = datetime.getDate(view.activeEnd.toISOString());
        const date = datetime.getDate(view.currentStart.toISOString());

        if (range.start !== start || range.stop !== stop) {
            datesMutex.acquire()
                .then(function(release) {
                    try {
                        if (range.start !== start || range.stop !== stop) {
                            setDate(date);
                            setView(view.type);

                            setRange({
                                start: start,
                                stop: stop,
                            });

                            shiftCache.load(start, stop);
                            timeOffCache.load(start, stop);
                        }
                    } finally {
                        release();
                    }
                }.bind(this));
        }
    };

    const shiftSaved = (shift, reloadShifts) => {
        if (reloadShifts) {
            const start = range.start;
            const stop = range.stop;

            setEvent(null);
            shiftCache.load(start, stop, true);

        } else {
            setEvent(null);
            shiftCache.onEntitySaved(shift);
        }
    };

    const shiftDeleted = (shiftKey, reloadShifts) => {
        if (reloadShifts) {
            const start = range.start;
            const stop = range.stop;

            setEvent(null);
            shiftCache.load(start, stop, true);

        } else {
            const shiftId = toCompositeId(shiftKey);

            setEvent(null);
            shiftCache.onEntityDeleted(shiftId);
        }
    };

    const volunteerSaved = (volunteer) => {
        setEvent(null);
        onSaveVolunteer(volunteer);
    };

    const volunteerDeleted = (volunteerId) => {
        setEvent(null);
        onDeleteVolunteer(volunteerId);
    };

    const tradeSaved = (trade) => {
        setEvent(null);
        onSaveTrade(trade);
    };

    const tradeDeleted = (tradeId) => {
        setEvent(null);
        onDeleteTrade(tradeId);
    };

    const tradeVolunteer = (trade) => {
        setEvent(null);
        onSaveTrade(trade);
    };

    const tradeCancelled = (trade) => {
        setEvent(null);
        onSaveTrade(trade);
    };

    const tradeApproved = (trade) => {
        const start = range.start;
        const stop = range.stop;

        setEvent(null);
        onDeleteTrade(trade.id);
        shiftCache.load(start, stop, true);
    };

    const schedulePosted = (account) => {
        setShowPostSchedule(false);
        onSaveMyAccount(account);
    };

    const massDeletedShifts = () => {
        const start = range.start;
        const stop = range.stop;

        setEvent(null);
        setShowMassDelete(false);
        shiftCache.load(start, stop, true);
    };

    const renderShiftDialog = () => {
        const customFields = event.extendedProps;
        const shift = customFields.shift;
        const trade = customFields.trade || {};
        const past = datetime.isPast(shift.stop);

        // todo: maybe make a user/app setting for "allow past" instead of checking super
        return past && !me.roles.super ?
            <PastShiftDialog
                settings={account.settings}
                user={me}
                users={users}
                volunteers={volunteers}
                event={event}
                shiftCache={shiftCache}
                timeOffCache={timeOffCache}
                onClose={() => setEvent(null)}
                onDelete={shiftDeleted}
            /> : me.roles.admin ?
            <AdminShiftDialog
                snackbar={snackbar}
                settings={account.settings}
                user={me}
                users={users}
                deletedUsers={deletedUsers}
                volunteers={volunteers}
                event={event}
                shiftCache={shiftCache}
                timeOffCache={timeOffCache}
                onCancel={() => setEvent(null)}
                onSave={shiftSaved}
                onDelete={shiftDeleted}
            /> : arrays.contains(shift.userIds, me.id) ?
            <MineShiftDialog
                snackbar={snackbar}
                settings={account.settings}
                user={me}
                users={users}
                event={event}
                shiftCache={shiftCache}
                timeOffCache={timeOffCache}
                onCancel={() => setEvent(null)}
                onSaveTrade={tradeSaved}
                onDeleteTrade={tradeDeleted}
                onApproveTrade={tradeApproved}
            /> : arrays.contains(trade.requestedIds, me.id) ?
            <TradeShiftDialog
                snackbar={snackbar}
                settings={account.settings}
                user={me}
                event={event}
                shiftCache={shiftCache}
                timeOffCache={timeOffCache}
                onCancel={() => setEvent(null)}
                onSave={tradeVolunteer}
                onDelete={tradeCancelled}
            /> :
            <OpenShiftDialog
                snackbar={snackbar}
                settings={account.settings}
                user={me}
                event={event}
                shiftCache={shiftCache}
                timeOffCache={timeOffCache}
                onCancel={() => setEvent(null)}
                onSave={volunteerSaved}
                onDelete={volunteerDeleted}
            />
    };

    const renderSchedule = () => {
        const wide = dimensions.wide;
        const spinner = shiftCache.loading || timeOffCache.loading;
        const showScheduleUntil = account.settings.showScheduleUntil;
        const css = [];

        arrays.addTo(css, "schedule-page");

        if (!me.roles.admin) {
            arrays.addTo(css, "tall");
        }

        return <div className={strings.spaceSeparated(css)}>
            <div className="calendar">
                <FullCalendar initialView={view || me.settings.scheduleView}
                              initialDate={date}
                              plugins={[timeGridPlugin, dayGridPlugin]}
                              contentHeight={getContentHeight()}
                              height="100%"
                              expandRows={true}
                              displayEventTime={false}
                              displayEventEnd={false}
                              allDaySlot={false}
                              fixedWeekCount={false}
                              eventDisplay="block"
                              eventOrder={["start", "attribute"]}
                              events={events}
                              eventClick={info => setEvent(info.event)}
                              eventContent={info => info.event.extendedProps.view === VIEW_DAY_MONTH ?
                                  <SummaryShiftEvent
                                      user={me}
                                      event={info.event}
                                      wide={wide}
                                      filter={filters.data.attributeId}
                                  /> :
                                  <DetailedShiftEvent
                                      user={me}
                                      event={info.event}
                                      wide={wide}
                                      filter={filters.data.attributeId}
                                  />}
                              datesSet={dateRangeChanged}
                              dayHeaderClassNames={info => me.roles.admin ? getDayCellClassNames(info, showScheduleUntil) : []}
                              dayCellClassNames={info => me.roles.admin ? getDayCellClassNames(info, showScheduleUntil) : []}
                              validRange={{
                                  start: getGoBackTo(me),
                                  end: !me.roles.admin ? showScheduleUntil : null,
                              }}
                              headerToolbar={{
                                  left: wide ? 'title' : '',
                                  right: 'today prev,next ' + VIEW_TIME_DAY + ',' + VIEW_DAY_WEEK + ',' + VIEW_DAY_MONTH,
                              }}
                              footerToolbar={wide ? null : {
                                  left: 'title',
                              }}
                />
            </div>
            {spinner && wide ? <CircularProgress size="40px"/> : null}
            {me.roles.admin ?
                <FloatingButtons position="higher">
                    <Fab color="secondary" size="medium" title="Delete non-posted shifts"
                         disabled={spinner}
                         onClick={() => setShowMassDelete(true)}
                    >
                        <DeleteIcon/>
                    </Fab>
                    <Fab color="primary" size="medium" title="Add shift"
                         disabled={spinner}
                         onClick={() => router.redirectTo(history, PAGE_SHIFT)}
                    >
                        <AddIcon/>
                    </Fab>
                    <Fab color="primary" size="medium" title="Post schedule"
                         disabled={spinner}
                         onClick={() => setShowPostSchedule(true)}
                    >
                        <PostScheduleIcon/>
                    </Fab>
                    <Fab color="primary" size="medium" title="Filter schedule"
                         disabled={spinner}
                         onClick={() => setShowFilters(true)}
                    >
                        <FilterIcon/>
                    </Fab>
                </FloatingButtons> : null}
            {event ? renderShiftDialog() : null}
            {showPostSchedule ?
                <PostScheduleDialog showScheduleUntil={showScheduleUntil}
                                    onCancel={() => setShowPostSchedule(false)}
                                    onSaveMyAccount={schedulePosted}
                /> : null}
            {showMassDelete ?
                <DeleteShiftsDialog showScheduleUntil={showScheduleUntil}
                                    onCancel={() => setShowMassDelete(false)}
                                    onDelete={massDeletedShifts}
                /> : null}
            {showFilters ? <FiltersDialog
                filters={filters}
                form={ScheduleFilters}
                formProps={{
                    users: users,
                    attributes: attributes,
                }}
                onClose={() => setShowFilters(false)}
            /> : null}
        </div>
    };

    const renderNotPosted = () => {
        return <div className="schedule-page">
            <Typography variant="h5" paragraph={true}>
                Schedule not yet posted
            </Typography>
            <Typography>
                You will be notified as soon as the admin posts the schedule
            </Typography>
        </div>
    };

    return me.roles.admin || account.settings.showScheduleUntil
        ? renderSchedule()
        : renderNotPosted();
}

export default withRouter(SchedulePage);
