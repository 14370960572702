import React, {useEffect, useState} from 'react';
import {Prompt, withRouter} from "react-router-dom";
import {Button, Checkbox, CircularProgress, FormControlLabel, Menu, MenuItem, Typography} from '@mui/material';
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Tooltip} from "@atttomyx/react-components";
import ShiftForm from "../../forms/shiftForm/shiftForm";
import UserPicker from "../../fields/userPicker/userPicker";
import * as shiftService from "../../services/shifts";
import {calculateHours, findAvailableUsers, getDefaultDate} from "../../utils/shifts";
import {router} from "@atttomyx/react-utils";
import {arrays, datetime, forms} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import {
    ADD_ANOTHER_BLANK,
    ADD_ANOTHER_COPY,
    PAGE_SCHEDULE,
    PATTERN_COMPRESSED,
    PATTERN_DAYS_OF_WEEK,
    PATTERN_OFF_AND_ON,
    PATTERN_ON_AND_OFF
} from "../../constants";

const freshData = (date) => {
    return {
        startDate: getDefaultDate(date),
        openings: 1,
    }
};

const AddShiftPage = (props) => {
    const {
        history, snackbar, settings, user, users, deletedUsers, attributes, timeSlots, tracks, shiftCache, timeOffCache, date,
        onSaveAttribute, onSaveTimeSlot, onSaveTrack
    } = props;
    const [data, setData] = useState(freshData(date));
    const [saving, setSaving] = useState(false);
    const [showAssign, setShowAssign] = useState(false);
    const [shiftHours, setShiftHours] = useState(0);
    const [userIds, setUserIds] = useState([]);
    const [ userIdToHours, setUserIdToHours ] = useState({});
    const [ availableUsers, setAvailableUsers ] = useState([]);
    const [ loadingAvailability, setLoadingAvailability ] = useState(false);

    useEffect(() => {
        if (data.attributeId && data.startDate && data.timeSlotId) {
            const maxSequentialHours = settings.maxSequentialHours;
            const hoursBeforeOvertime = settings.hoursBeforeOvertime;
            const timeSlot = arrays.findEntity(timeSlots, data.timeSlotId);

            const shift = {
                attributeId: data.attributeId,
                start: `${data.startDate}T${timeSlot.start}`,
                stop: timeSlot.stop < timeSlot.start
                    ? `${datetime.nextDay(data.startDate)}T${timeSlot.stop}`
                    : `${data.startDate}T${timeSlot.stop}`,
                userIds: userIds,
            };

            setShiftHours(datetime.hoursApart(shift.stop, shift.start));
            setUserIds([]);
            setLoadingAvailability(true);

            calculateHours(shiftCache, timeOffCache, shift.start, maxSequentialHours, (userIdToHours) => {
                setUserIdToHours(userIdToHours);

                findAvailableUsers(shift, users, shiftCache, timeOffCache, true, true, hoursBeforeOvertime, maxSequentialHours, userIdToHours, (availableUsers) => {
                    setAvailableUsers(availableUsers);
                    setLoadingAvailability(false);
                });
            });
        } else {
            setAvailableUsers([]);
            setUserIds([]);
        }
    }, [data.attributeId, data.startDate, data.timeSlotId]);

    const resetForm = () => {
        setData(freshData(date));
        setShowAssign(false);
        setUserIds([]);
    };

    const closeMenuAndSubmitForm = (popupState, mode) => {
        popupState.close();

        submitForm(mode);
    };

    const submitForm = (mode) => {
        setSaving(true);

        const shift = {
            attributeId: data.attributeId,
            openings: data.openings,
            start: data.start,
            stop: data.stop,
            description: data.description,
            userIds: showAssign ? userIds : [],
        };

        if (data.pattern) {
            shift.recurrence = {
                start: data.beginning,
            };

            if (data.pattern === PATTERN_DAYS_OF_WEEK) {
                shift.recurrence.daysOfWeek = {
                    phases: data.daysOfWeek,
                };

            } else if (data.pattern === PATTERN_ON_AND_OFF) {
                shift.recurrence.onAndOff = {
                    phases: data.onAndOff,
                };

            } else if (data.pattern === PATTERN_OFF_AND_ON) {
                shift.recurrence.offAndOn = {
                    phases: data.offAndOn,
                };

            } else if (data.pattern === PATTERN_COMPRESSED) {
                shift.recurrence.compressed = data.compressed;
            }
        }

        const success = (shift) => {
            if (shift.recurrence) {
                shiftCache.clear();

            } else {
                shiftCache.onEntitySaved(shift);
            }

            snackbar.setSuccess("Shift saved");
            setSaving(false);

            if (mode !== ADD_ANOTHER_COPY) {
                resetForm();

            } else {
                setShowAssign(false);
                setUserIds([]);
            }

            if (!mode) {
                router.redirectTo(history, PAGE_SCHEDULE);
            }
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        shiftService.createShift(shift, success, failure);
    };

    const renderSaveAndAddAnother = () => {
        return <PopupState variant="popover" popupId="delete">
            {(popupState) => (
                <>
                    <Button color="primary" size="large"
                            disabled={!data.valid || userIds.length > data.openings}
                            {...bindTrigger(popupState)}>
                        Save & Add Another
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => closeMenuAndSubmitForm(popupState, ADD_ANOTHER_COPY)}>Like
                            this</MenuItem>
                        <MenuItem onClick={() => closeMenuAndSubmitForm(popupState, ADD_ANOTHER_BLANK)}>Blank</MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
    };

    const valid = data.valid;
    const modified = forms.differ(freshData(date), data)
        || showAssign && userIds.length > 0;

    return saving ?
        <div className="add-shift-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="add-shift-page">
            <Typography variant="h5" paragraph={true}>
                Add Shift
            </Typography>
            <ShiftForm
                attributes={attributes}
                timeSlots={timeSlots}
                tracks={tracks}
                data={data}
                onChange={setData}
                onSaveAttribute={onSaveAttribute}
                onSaveTimeSlot={onSaveTimeSlot}
                onSaveTrack={onSaveTrack}
                allowRecurrence={true}
                requireRecurrence={false}
                lockStartDate={false}
                allowPastDate={user.roles.super} // todo: maybe make a user/app setting for "allow past" instead of checking super
            />
            <div className="option">
                <FormControlLabel labelPlacement="end"
                                  label="Assign"
                                  control={<Checkbox color="primary"
                                                     checked={forms.sanitizeOption(showAssign)}
                                                     onChange={(event) => setShowAssign(event.target.checked)}
                                  />}
                />
                <Tooltip
                    message="You may assign users to this shift now or make the assignments after it is on the schedule."/>
            </div>
            {showAssign ? loadingAvailability ?
                <CircularProgress size="40px"/> :
                <UserPicker
                    availableUsers={availableUsers}
                    users={users}
                    deletedUsers={deletedUsers}
                    userIds={userIds}
                    userIdToHours={userIdToHours}
                    isDisabled={(checked) => data.openings <= userIds.length && !checked}
                    settings={settings}
                    shiftUserIds={[]}
                    shiftHours={shiftHours}
                    onChange={setUserIds}
                    ready={valid}
                    readyMessage="Complete all required fields for availability."
                /> : null}
            <div className="actions">
                <Button color="primary" size="large"
                        disabled={!valid || userIds.length > data.openings}
                        onClick={() => submitForm(null)}>
                    Save
                </Button>
                {renderSaveAndAddAnother()}
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default withRouter(AddShiftPage);
